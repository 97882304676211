import api from '../api';

export const authService = api.injectEndpoints({
  endpoints: (build) => {
    return {
      login: build.mutation({
        query: (args) => ({
          url: 'user/login',
          method: 'POST',
          body: args,
        }),
        invalidatesTags: ['AUTH'],
      }),
      sendCode: build.mutation({
        query: (args) => ({
          url: 'user/code',
          method: 'POST',
          body: args,
        }),
        invalidatesTags: ['SEND_CODE'],
      }),
      reSendCode: build.mutation({
        query: (args) => ({
          url: 'user/code',
          method: 'PUT',
          body: args,
        }),
        invalidatesTags: ['RESEND_CODE'],
      }),
    };
  },
});

export const { useLoginMutation, useSendCodeMutation, useReSendCodeMutation } =
  authService;
