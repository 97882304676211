export enum ErrorMessages {
  PASSWORD = 'Şifreniz en az 6 karakter uzunluğunda olmalıdır.',
  COMPANYID = 'Geçerli bir alfanumerik kurum kimliği giriniz.',
  PHONE_NUMBER = 'Geçerli bir telefon numarası giriniz.',
  INVALID_TEXT = 'En az 3 karakter uzunluğunda olmalıdır.',
  EMAIL = 'Geçerli bir e-mail adresi giriniz.'
}

export enum InputType {
  Text = 'text',
  Password = 'password',
  Number = 'number',
  Email = 'email',
}
