import colors from '../../utils/colors';
import styled from 'styled-components';
import { usePagination } from './usePagination';
import './pagination.module.css';
import { MOBILE_WIDTH } from '../../utils/globals';


interface IPaginationProps {
  totalCount: number;
  pageNumber: number;
  onChangePage: any;
}

const Pagination = ({
  totalCount,
  pageNumber,
  onChangePage,
}: IPaginationProps) => {
  const pageSize = 10;
  const paginationRange = usePagination({
    totalCount: totalCount,
    pageSize: pageSize,
    siblingCount: 2,
    currentPage: pageNumber,
  });

  return (
    <Wrapper>
      <NumberWrapper
        $disabled={pageNumber === 1}
        onClick={() => pageNumber !== 1 && onChangePage(pageNumber - 1)}
      >
        <img
          width={24}
          height={24}
          alt='left_chevron'
          src='/assets/icons/left-chevron.png'
        />
      </NumberWrapper>
      {paginationRange?.map((value, index: number) => {
        const tempIndex = typeof value === 'number' ? value : index + 1;
        return (
          <NumberWrapper
            key={index}
            $selected={value === pageNumber}
            onClick={() => onChangePage(tempIndex)}
          >
            <p>{value}</p>
          </NumberWrapper>
        );
      })}

      <NumberWrapper
        $disabled={pageNumber === Math.ceil(totalCount / pageSize)}
        onClick={() =>
          pageNumber !== Math.ceil(totalCount / pageSize) &&
          pageNumber + 1 <= (paginationRange?.at(-1) as number) &&
          onChangePage(pageNumber + 1)
        }
      >
        <img
          width={24}
          height={24}
          alt='right_chevron'
          src='/assets/icons/right-chevron.png'
        />
      </NumberWrapper>
    </Wrapper>
  );
};

export default Pagination;

const Wrapper = styled.div.attrs((props) => props)`
  display: flex;
  column-gap: 4px;
  align-items: center;
  justify-content: center;
  margin: 10px 0px 150px 0px;
  width: 392px;
  @media (max-width: ${MOBILE_WIDTH}px) {
    width: 100%;
    padding: 0px 10px;
  }
`;

interface Props {
  $disabled?: boolean;
  $selected?: boolean;
}

const NumberWrapper = styled.div<Props>`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 32px;
  height: 32px;
  /* border: 1px solid
    ${({ $selected }) => ($selected ? colors.mainBlue : colors.gray60)}; */
  border-radius: 4px;
  opacity: ${({ $disabled, $selected }) => ($disabled ? '0.5' : '1')};
  cursor: ${({ $disabled, $selected }) => (!$disabled ? 'pointer' : 'inherit')};
  color: ${({ $disabled, $selected }) =>
    $selected ? colors.white : colors.mainBlue};
  background-color: ${({ $disabled, $selected }) => {
    if ($disabled) {
      return !$disabled ? colors.white : colors.gray60;
    } else {
      return $selected ? colors.mainBlue : colors.white;
    }
  }};
`;
