import { useEffect, useRef, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import colors from '../../utils/colors';
import Modal from '../modal/Modal';
import ApplicationForm from '../bids/form/ApplicationForm';
import { MEDIUM_WIDTH, MOBILE_WIDTH } from '../../utils/globals';
import { useUnsubscribeNotificationMutation } from '../../app/services/workspace.service';
import NotificationSettings from '../settings/NotificationSettings';

const Navbar = ({
  unprotected,
  whatsappFeature,
  bidsFeature,
  callcenterPostFeature,
  callcenterGetFeature,
}: {
  unprotected?: boolean;
  whatsappFeature?: boolean;
  bidsFeature?: boolean;
  callcenterPostFeature?: boolean;
  callcenterGetFeature?: boolean;
}) => {
  const [display, setDisplay] = useState(false);
  const [showMenu, setShowMenu] = useState(false);
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  // const workspace = useAppSelector((store) => store.workspace.data);

  const location = useLocation().pathname.split('/')[1];
  // const isWhatsappActive = location === 'whatsapp';
  // const isBidsActive = location === 'bids';
  // const isCallcenterActive = location === 'callcenter';

  const isLoginActive = location === 'login';

  const firstTitleRef = useRef<any>();
  const secondTitleRef = useRef<any>();

  useEffect(() => {
    firstTitleRef.current = document.getElementById('which-checkup');
    secondTitleRef.current = document.getElementById('how-it-works');
  }, [isLoginActive]);

  const [applicationModalOpen, setApplicationModalOpen] = useState(false);
  // const [settingsModalOpen, setSettingsModalOpen] = useState(false);

  useEffect(() => {
    window.addEventListener('scroll', setScrollEvent);
    return () => {
      window.removeEventListener('scroll', setScrollEvent);
    };
  }, []);

  const setScrollEvent = () => {
    if (window.pageYOffset > 100) {
      setDisplay(true);
    } else {
      setDisplay(false);
    }
  };
  // const [showModal, setShowModal] = useState(false);

  // const handleSettings = () => {
  //   setShowModal((pre) => !pre);
  // };

  const toggleMenu = () => {
    setShowMenu(!showMenu);
  };

  const [unsubscribe] = useUnsubscribeNotificationMutation();

  const logoutHandler = async () => {
    try {
      const reg = await navigator.serviceWorker.ready;
      const subscription = await reg.pushManager.getSubscription();
      console.log(subscription);
      if (subscription) {
        const successful = await subscription.unsubscribe();
        if (successful) {
          await unsubscribe({});
          console.log("You've successfully unsubscribed");
        }
      }
      dispatch({ type: 'RESET' });
    } catch (error) {
      console.error('An error occurred:', error);
    }
  };
  const loginHandler = () => {
    // handleSettings();
    navigate('/login');
    //navbar kapatma
    setShowMenu(false);
  };

  // const onCloseSettingsModal = () => {
  //   setSettingsModalOpen(false);
  //   document.body.style.overflow = 'auto';
  // };

  const onCloseApplicationModal = () => {
    setApplicationModalOpen(false);
    document.body.style.overflow = 'auto';
  };

  const handleScrollToSection = (ref: any) => {
    //Scroll ettiğimiz section'ın title'ı navbar altında kaldığı için böyle bir şey ekledim.
    const navbarHeight = 85;
    const offsetTop = ref.current.offsetTop - navbarHeight;
    window.scrollTo({ top: offsetTop, behavior: 'smooth' });
    setShowMenu(false);
  };

  return (
    <>
      <Modal
        isOpen={applicationModalOpen}
        content={<ApplicationForm handleCloseModal={onCloseApplicationModal} />}
        title={'Başvuru Yap'}
      />
      {/* <Modal
        isOpen={settingsModalOpen}
        content={<NotificationSettings onCloseModal={onCloseSettingsModal} />}
        title={'Notifikasyon Ayarları'}
      /> */}
      <NavbarWrapper $display={display}>
        <LogoWrapper>
          <Logo
            src='/assets/icons/hangiCheckUpLogo.png'
            alt='logo'
            onClick={() =>
              unprotected ? navigate('/landing') : navigate('/offers')
            }
          />
        </LogoWrapper>
        <MenuIcon isOpen={showMenu} onClick={toggleMenu}>
          <img alt='hamburger-icon' src='/assets/icons/hamburger.png' />
        </MenuIcon>
        <MenuItemsWrapper show={showMenu}>
          {!unprotected && (
            <>
              {/* {bidsFeature && (
                <NavItem
                  $isActive={isBidsActive}
                  style={{
                    color: `${colors.mainBlue}`,
                  }}
                >
                  <div
                    onClick={() => {
                      navigate(`/bids/${workspace._id}`);
                      setShowMenu(false);
                    }}
                  >
                    {'Teklifler'}
                  </div>
                </NavItem>
              )} */}
              {/* {whatsappFeature && (
                <NavItem
                  $isActive={isWhatsappActive}
                  style={{
                    color: `${colors.mainBlue}`,
                  }}
                >
                  <div
                    onClick={() => {
                      navigate('/whatsapp');
                      setShowMenu(false);
                    }}
                  >
                    {'Whatsapp'}
                  </div>
                </NavItem>
              )} */}
              <Item onClick={logoutHandler} $isLogin={true}>
                Çıkış Yap
              </Item>
              <SettingsIcon
                isOpen={window.location.pathname === '/settings'}
                onClick={() => {
                  navigate('/settings');
                  // setSettingsModalOpen(true);
                  // setShowMenu(false);
                  // document.body.style.overflow = 'hidden';
                }}
              >
                <img
                  alt='settings-icon'
                  src='/assets/icons/settings-icon.png'
                />
              </SettingsIcon>
            </>
          )}
          {unprotected && (
            <>
              {!isLoginActive && (
                <>
                  <InfoButton
                    onClick={() => {
                      handleScrollToSection(firstTitleRef);
                    }}
                  >
                    Neden HangiCheckup?
                  </InfoButton>
                  <InfoButton
                    onClick={() => {
                      handleScrollToSection(secondTitleRef);
                    }}
                  >
                    Nasıl Çalışır?
                  </InfoButton>
                  <Item
                    onClick={() => {
                      setApplicationModalOpen(true);
                      setShowMenu(false);
                      document.body.style.overflow = 'hidden';
                    }}
                  >
                    Başvuru
                  </Item>
                </>
              )}
              <Item onClick={loginHandler} $isLogin={true}>
                Giriş Yap
              </Item>
            </>
          )}

          {/* <ProfileWrapper> */}
          {/* <Profile
              src={`${
                workspace
                  ? socketBaseUrl + workspace?.company?.icon?.url
                  : '/favicon.ico'
              }`}
              onClick={handleSettings}
            /> */}
          {/* {showModal && (
              <ModalContainer>
                {!unprotected && (
                  <InfoButton onClick={logoutHandler}>Logout</InfoButton>
                )}
                {unprotected && (
                  <InfoButton onClick={loginHandler}>Login</InfoButton>
                )}
              </ModalContainer>
            )} */}
          {/* </ProfileWrapper> */}
        </MenuItemsWrapper>
      </NavbarWrapper>
      {/* {showModal && <FixedOverlay onClick={handleSettings} />} */}
    </>
  );
};

const NavbarWrapper = styled.div<{ $display: boolean }>`
  padding: 0px 10%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 80px;
  background-color: white;
  z-index: 2;
  position: fixed;
  box-shadow: ${({ $display }) =>
    $display ? '0px 4px 15px rgba(0, 0, 0, 0.2)' : 'none'};
  transition: box-shadow 0.5 ease;
  @media (max-width: 701px) {
    margin: auto;
  }
`;

const LogoWrapper = styled.div`
  width: 232px;
  height: 40px;
  @media (max-width: ${MEDIUM_WIDTH}px) {
    width: 174px;
    height: 30px;
  }
  @media (max-width: ${MOBILE_WIDTH}px) {
    width: 130.5px;
    height: 22.5px;
  }
`;

const Logo = styled.img`
  width: 100%;
  cursor: pointer;
`;

const ProfileWrapper = styled.div`
  width: 40px;
  height: 40px;
  border-radius: 50%;
  position: relative;
`;

const Profile = styled.img`
  width: 100%;
  border-radius: 50%;
  object-fit: cover;
  cursor: pointer;
`;

const ModalContainer = styled.div`
  width: 100px;
  background-color: white;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2); /* Hafif bir gölge ekledim */
  padding: 10px;
  border-radius: 8px; /* Köşeleri yuvarlattım */
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const InfoButton = styled.div`
  cursor: pointer;
  color: ${colors.mainBlue};
  &:hover {
    color: #0e183b;
  }
`;

const NavItem = styled.div<{ $isActive?: boolean }>`
  padding: 8px 20px;
  /* margin-right: 10px; */
  border-radius: 40px;
  cursor: pointer;
  background-color: ${({ $isActive }) =>
    $isActive ? colors.mainLightBlueHover : ''};
  transition: background-color 0.3s ease;
  &:hover {
    background-color: ${() => colors.mainLightBlue};
    select {
      background: none;
    }
  }
`;

const Item = styled.div<{ $isLogin?: boolean }>`
  padding: 8px 20px;
  /* margin-right: 10px; */
  border-radius: 4px;
  border: 1px solid ${({ $isLogin }) => ($isLogin ? colors.gray60 : '')};
  cursor: pointer;
  background-color: ${({ $isLogin }) =>
    $isLogin ? colors.white : colors.mainBlue};
  color: ${({ $isLogin }) => ($isLogin ? colors.black : colors.white)};
  transition: background-color 0.3s ease;
  &:hover {
    background-color: ${({ $isLogin }) =>
      $isLogin ? colors.gray40 : colors.mainDarkBlue};
    select {
      background: none;
    }
  }
`;

const FixedOverlay = styled.div`
  position: fixed;
  width: 100vw;
  height: 100vh;
`;
const MenuIcon = styled.div<{ isOpen: boolean }>`
  background-color: ${({ isOpen }: any) =>
    isOpen ? colors.listHover : 'none'};
  border-radius: 5px;

  img {
    display: none;
    width: 24px;
    height: 24px;
    margin: 5px;
    filter: invert(26%) sepia(30%) saturate(5765%) hue-rotate(223deg)
      brightness(82%) contrast(95%);
    @media (max-width: ${MOBILE_WIDTH}px) {
      display: ${({ show }: any) => (!show ? 'flex' : 'none')};
    }
  }
`;

const SettingsIcon = styled.div<{ isOpen?: boolean }>`
  background-color: ${({ isOpen }) =>
    isOpen ? colors.listHover : 'transparent'};
  border-radius: 5px;
  cursor: pointer;
  padding: 8px 10px;
  transition: background-color 0.3s ease;

  img {
    display: flex;
    width: 28px;
    height: 28px;
    transition: transform 0.5s ease;
    transform: rotate(${({ isOpen }) => (isOpen ? '90deg' : '0deg')});
  }
`;

const MenuItemsWrapper = styled.div<{ show: boolean }>`
  display: flex;
  align-items: center;
  gap: 25px;

  @media (max-width: ${MOBILE_WIDTH}px) {
    display: ${({ show }) => (show ? 'flex' : 'none')};
    flex-direction: column;
    align-items: center;
    position: absolute;
    top: 80px;
    right: 0;
    width: 100%;
    background-color: white;
    padding: 20px;
    box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.2);
    z-index: 2;
  }
`;

export default Navbar;
