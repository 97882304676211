import { combineReducers, Reducer } from '@reduxjs/toolkit';
import api from './api';
import { authReducer } from './slices/auth.slice';
import { workspaceReducer } from './slices/workspace.slice';

import { RootState } from './store';
import { bidReducer } from './slices/bid.slice';

const CommonReducers = {
  workspace: workspaceReducer,
  auth: authReducer,
  bid: bidReducer,
  [api.reducerPath]: api.reducer,
};

const appReducer = combineReducers(CommonReducers);

const rootReducer: Reducer = (state: RootState, action: any) => {
  if (action.type === 'RESET') {
    state = undefined;
  }
  return appReducer(state, action);
};

export default rootReducer;
