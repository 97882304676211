import styled from 'styled-components';
import colors from '../../../utils/colors';
import dateFormat from 'dateformat';
import { formatPhoneNumber } from '../../../utils/helperFunctions';

const RowDropdown = ({ row }: any) => {
  if (row?.repetitive?.lenght === 0) {
    return null;
  }

  return (
    <>
      {row.repetitive.map((subPacket: any, index: number) => {
        return (
          <>
            <SpaceUp />
            <tr
              style={{
                background: `${colors.gray40}`,
              }}
              key={index}
            >
              <StyledTd $isOverFlowHidden $radiusLeft>
                {subPacket.fullname}
              </StyledTd>
              <StyledTd>
                {dateFormat(row.createdAt, 'dd-mm-yyyy HH:MM')}
              </StyledTd>
              <StyledTd>
                {row.condition === 'waiting'
                  ? row?.normalizedPhone
                  : formatPhoneNumber(row.phone)}
              </StyledTd>
              <StyledTd $isOverFlowHidden $radiusRight>
                {subPacket.leadDistrict}
              </StyledTd>
            </tr>
            <SpaceDown />
          </>
        );
      })}
    </>
  );
};

const StyledTd = styled.td<{
  $radiusRight?: boolean;
  $radiusLeft?: boolean;
  $status?: string;
  $isOverFlowHidden?: boolean;
  $isWrap?: boolean;
}>`
  padding: 5px 10px;
  line-height: 24px;
  max-width: 100px;
  min-width: 100px;
  text-overflow: ellipsis;
  overflow: ${({ $isOverFlowHidden }) =>
    $isOverFlowHidden ? 'hidden' : 'none'};
  white-space: ${({ $isWrap }) => ($isWrap ? 'wrap' : 'nowrap')};
  text-align: left;
  border-top-left-radius: ${({ $radiusLeft }) =>
    $radiusLeft ? '14px' : '0px'};
  border-bottom-left-radius: ${({ $radiusLeft }) =>
    $radiusLeft ? '14px' : '0px'};
  border-top-right-radius: ${({ $radiusRight }) =>
    $radiusRight ? '14px' : '0px'};
  border-bottom-right-radius: ${({ $radiusRight }) =>
    $radiusRight ? '14px' : '0px'};
  @media (max-width: 768px) {
    line-height: 16px;
  }
`;

const SpaceUp = styled.div`
  margin-top: 3px;
`;

const SpaceDown = styled.div`
  margin-bottom: 3px;
`;

export default RowDropdown;
