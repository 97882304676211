import { useState } from 'react';
import styled from 'styled-components';
import colors from '../../utils/colors';

const DropdownMenu = ({
  // onChangeSection,
  selectedOption,
  options,
  onChangeSelectedOption,
}: any) => {
  const [isOpen, setIsOpen] = useState(false);
  // const [selectedOption, setSelectedOption] = useState(options[0]);

  const handleOptionClick = (option: any) => {
    onChangeSelectedOption(option);
    setIsOpen(false);
    // if (onChangeSection) {
    //   onChangeSection(option.id);
    // }
  };

  return (
    <DropdownContainer>
      <SelectedOption isOpen={isOpen} onClick={() => setIsOpen(!isOpen)}>
        <SelectedImage
          src={selectedOption?.imageSrc}
          alt={selectedOption?.title}
        />
        <img width={20} height={20} src='/assets/icons/chevronDownFilled.png' />
      </SelectedOption>
      {isOpen && (
        <OptionsList>
          {options.map((option: any) => (
            <Option key={option.id} onClick={() => handleOptionClick(option)}>
              <OptionImage src={option.imageSrc} alt={option.title} />
            </Option>
          ))}
        </OptionsList>
      )}
    </DropdownContainer>
  );
};

const DropdownContainer = styled.div`
  position: relative;
  display: inline-block;
  margin-bottom: 25px;
`;

const SelectedOption = styled.div<{ isOpen: boolean }>`
  display: flex;
  align-items: center;
  cursor: pointer;
  padding: 12px 20px 12px 20px;
  border-radius: 16px;
  background-color: ${({ isOpen }) => (isOpen ? '#EAEDF9' : 'transparent')};
  transition: background-color 0.4s ease;
`;

const SelectedImage = styled.img`
  width: 162px;
  height: 28px;
`;

const OptionsList = styled.ul`
  position: absolute;
  top: 100%;
  left: 0;
  width: 100%;
  background-color: #fff;
  list-style-type: none;
  margin: 4px 0 0;
  border: 1px solid #bec8ec;
  z-index: 1;
  border-radius: 16px;
  box-shadow: 0px 0px 20px 4px #2c4dc31f;
  li:first-child {
    border-top-left-radius: 16px;
    border-top-right-radius: 16px;
  }

  li:last-child {
    border-bottom-left-radius: 16px;
    border-bottom-right-radius: 16px;
  }
`;

const Option = styled.li`
  display: flex;
  align-items: center;
  cursor: pointer;
  padding: 12px;
  transition: background-color 0.3s ease;

  &:hover {
    background-color: #f0f0f0;
  }
`;

const OptionImage = styled.img`
  width: 162px;
  height: 28px;
  margin-right: 8px;
`;

const OptionTitle = styled.span``;

export default DropdownMenu;
