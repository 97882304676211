import styled from 'styled-components';
import colors from '../../../utils/colors';
import { addMonths, formatPhoneNumber } from '../../../utils/helperFunctions';
import dateFormat from 'dateformat';

import { useNavigate } from 'react-router-dom';
import { option } from '../../../utils/globals';
import { useAppSelector } from '../../../app/hooks';

const convertStatus = (statusKey: string) => {
  if (statusKey === 'active') {
    return 'Aktif';
  } else if (statusKey === 'pending') {
    return 'Beklemede';
  } else if (statusKey === 'canceled') {
    return 'İptal Edildi';
  } else if (statusKey === 'completed') {
    return 'Tamamlandı';
  } else {
    return 'Bilinmeyen';
  }
};
const CallcenterRow = ({ row, index }: any) => {
  const navigate = useNavigate();
  const workspace = useAppSelector((store) => store.workspace.data);
  const threeMonthsLater = addMonths(row.createdAt, 3);

  return (
    <>
      <StyledRow $index={index}>
        <StyledTdForName $isOverFlowHidden $radiusLeft>
          {row.customerName}
        </StyledTdForName>
        <StyledTdForDate>
          {dateFormat(row.createdAt, 'dd-mm-yyyy')}
        </StyledTdForDate>
        <StyledTdForNumber $isWrap>
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              position: 'relative',
              justifyContent: 'space-between',
              width: '145px',
            }}
          >
            {row.customerPhone}
          </div>
        </StyledTdForNumber>
        <StyledTdForPacket $isOverFlowHidden>
          {row.packetName}
        </StyledTdForPacket>
        <StyledTdForPacket $isOverFlowHidden>
          {dateFormat(threeMonthsLater, 'dd-mm-yyyy')}
        </StyledTdForPacket>
        <StyledTdForPacket $isOverFlowHidden>
          {convertStatus(row?.status)}
        </StyledTdForPacket>
        <StyledTdForPacket $isOverFlowHidden>
          {row.upfrontPayment.toLocaleString('tr-TR', option)}
        </StyledTdForPacket>
        <StyledTdForPacket $isOverFlowHidden>
          {row.totalPayment.toLocaleString('tr-TR', option)}
        </StyledTdForPacket>
        <StyledTdForPacket
          $radiusRight={
            !workspace?.callcenterGetFeature && workspace?.callcenterPostFeature
          }
        >
          {row.pdfNumber}
        </StyledTdForPacket>
        {workspace?.callcenterGetFeature && (
          <StyledTdForPacket $isOverFlowHidden>
            <img
              style={{ cursor: 'pointer' }}
              width={36}
              src='/assets/icons/info-icon.png'
              onClick={(e) => {
                e.stopPropagation();
                navigate(`customers/${row._id}`, { state: { row } });
              }}
            />
          </StyledTdForPacket>
        )}
      </StyledRow>
    </>
  );
};

const StyledRow = styled.tr<{ $index: number }>`
  background: ${({ $index }) =>
    $index % 2 === 0 ? colors.mainLightBlue : 'white'};
  cursor: pointer;
  height: 80px;
`;

const commonCellStyle = ({
  $isOverFlowHidden,
  $isWrap,
  $radiusLeft,
  $radiusRight,
}: {
  $isOverFlowHidden?: boolean;
  $isWrap?: boolean;
  $radiusLeft?: boolean;
  $radiusRight?: boolean;
}) => `
  line-height: 24px;
  padding: 0px 10px 0px 10px;
  text-align: left;
  text-overflow: ellipsis;
  overflow: ${$isOverFlowHidden ? 'hidden' : 'none'};
  white-space: ${$isWrap ? 'wrap' : 'nowrap'};
  border-top-left-radius: ${$radiusLeft ? '14px' : '0px'};
  border-bottom-left-radius: ${$radiusLeft ? '14px' : '0px'};
  border-top-right-radius: ${$radiusRight ? '14px' : '0px'};
  border-bottom-right-radius: ${$radiusRight ? '14px' : '0px'};
  @media (max-width: 768px) {
    line-height: 16px;
  }
`;

const StyledTd = styled.td<{
  $radiusLeft?: boolean;
  $radiusRight?: boolean;
  $status?: string;
  $isWrap?: boolean;
  $isOverFlowHidden?: boolean;
}>`
  ${commonCellStyle}
`;

const StyledTdForName = styled(StyledTd)<{
  $radiusLeft?: boolean;
  $radiusRight?: boolean;
}>`
  min-width: 140px;
  max-width: 140px;
  font-weight: 700;
  font-size: 16px;
`;

const StyledTdForDate = styled(StyledTd)<{
  $radiusLeft?: boolean;
  $radiusRight?: boolean;
}>`
  width: 170px;
`;

const StyledTdForNumber = styled(StyledTd)<{
  $radiusLeft?: boolean;
  $radiusRight?: boolean;
}>`
  width: 180px;
`;

const StyledTdForPacket = styled(StyledTd)<{
  $radiusLeft?: boolean;
  $radiusRight?: boolean;
}>`
  max-width: 220px;
  /* min-width: 220px; */
`;

export default CallcenterRow;
