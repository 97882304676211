import dateFormat from 'dateformat';
import colors from '../../utils/colors';
import { MOBILE_WIDTH, option } from '../../utils/globals';
import styled from 'styled-components';
import ModalCloseButton from '../common/ModalCloseButton';

const columns = [
  { key: 'transactionDate', label: 'İşlem Tarihi' },
  { key: 'transactionType', label: 'Yapılan İşlem' },
  { key: 'transactionAmount', label: 'İşlem Yapılan Tutar' },
];

const CreditHistory = ({ data, onCloseModal }: any) => {
  return (
    <CreditContainer>
      <ModalCloseButton onClick={onCloseModal}></ModalCloseButton>
      <StyledTable>
        <tr style={{ textAlign: 'center' }}>
          {columns.map(({ label }) => (
            <td
              key={label}
              style={{
                color: colors.mainBlue,
                fontWeight: '600',
                paddingBottom: '15px',
              }}
            >
              {label}
            </td>
          ))}
        </tr>
        {data.map(({ createdAt, credit, type }: any, index: number) => (
          <StyledTableRow key={index} $bg={index % 2 === 0}>
            <StyledTableCol $radiusLeft={true}>
              {dateFormat(createdAt, 'dd-mm-yyyy HH:MM')}
            </StyledTableCol>
            <StyledTableCol
              style={{ color: credit > 0 ? colors.green : colors.mainRed }}
            >
              {type === 'add-credit'
                ? 'Bakiye Yükleme'
                : type === 'sell-phone'
                ? 'Numara Satışı'
                : type === 'remove-credit'
                ? 'Bakiye Silme'
                : 'Tanımlanmayan İşlem'}
            </StyledTableCol>
            <StyledTableCol $radiusRight={true}>
              {credit.toLocaleString('tr-TR', option)}
            </StyledTableCol>
          </StyledTableRow>
        ))}
      </StyledTable>
    </CreditContainer>
  );
};

const StyledTableRow = styled.tr<{ $bg: boolean }>`
  text-align: center;
  border-radius: 10px;
  padding: 10px 15px;
  background-color: ${({ $bg }) => ($bg ? colors.mainLightBlue : 'white')};
`;

const StyledTableCol = styled.td<{
  $radiusRight?: boolean;
  $radiusLeft?: boolean;
}>`
  padding: ${({ $radiusLeft }) => ($radiusLeft ? '10px 15px' : '10px 0px')};
  height: 50px;
  border-top-left-radius: ${({ $radiusLeft }) =>
    $radiusLeft ? '14px' : '0px'};
  border-bottom-left-radius: ${({ $radiusLeft }) =>
    $radiusLeft ? '14px' : '0px'};
  border-top-right-radius: ${({ $radiusRight }) =>
    $radiusRight ? '14px' : '0px'};
  border-bottom-right-radius: ${({ $radiusRight }) =>
    $radiusRight ? '14px' : '0px'};
  min-width: 200px;
`;

const StyledTable = styled.table`
  width: 100%;
  border-collapse: collapse;
  @media (max-width: ${MOBILE_WIDTH}px) {
    td {
      padding-right: 25px;
    }
  }
`;

const CreditContainer = styled.div`
  max-height: 600px;
  width: 100%;
  overflow: auto;
  border-collapse: collapse;
`;
export default CreditHistory;
