import api from '../api';

export const workspaceService = api.injectEndpoints({
  endpoints: (build) => {
    return {
      getLeads: build.mutation({
        query: (args) => ({
          url: `workspaces/leads`,
          method: 'PUT',
          body: args,
        }),
        invalidatesTags: ['GET_LEADS'],
      }),
      getHairLeads: build.mutation({
        query: (args) => ({
          url: `workspaces/hairleads`,
          method: 'PUT',
          body: args,
        }),
        invalidatesTags: ['GET_LEADS'],
      }),
      getWorkspace: build.mutation({
        query: () => ({
          url: `workspaces/info`,
          method: 'GET',
        }),
        invalidatesTags: ['GET_WORKSPACE'],
      }),
      updateLeadsStatus: build.mutation({
        query: (args) => ({
          url: `workspaces/lead-status`,
          method: 'PUT',
          body: args,
        }),
        invalidatesTags: ['UPDATE_LEADS_STATUS'],
      }),
      updateHairLeadsStatus: build.mutation({
        query: (args) => ({
          url: `workspaces/hairlead-status`,
          method: 'PUT',
          body: args,
        }),
        invalidatesTags: ['UPDATE_LEADS_STATUS'],
      }),
      // getReportByLeadId: build.mutation({
      //   query: (args: any) => ({
      //     url: `workspaces/lead?${new URLSearchParams(args)}`,
      //   }),
      //   invalidatesTags: ['GET_REPORT_BY_LEAD_ID'],
      // }),
      getLeadById: build.mutation({
        query: (args) => ({
          url: `workspaces/lead?${new URLSearchParams(args)}`,
        }),
        invalidatesTags: ['GET_LEAD_BY_ID'],
      }),
      getHairLeadById: build.mutation({
        query: (args) => ({
          url: `workspaces/hairlead?${new URLSearchParams(args)}`,
        }),
        invalidatesTags: ['GET_LEAD_BY_ID'],
      }),
      getNoteByLeadId: build.mutation({
        query: (args: any) => ({
          url: `workspaces/notes?${new URLSearchParams(args)}`,
        }),
        invalidatesTags: ['GET_NOTE_BY_LEAD_ID'],
      }),
      getNoteByCustomerId: build.mutation({
        query: (args: any) => ({
          url: `callcenter/customer-notes?${new URLSearchParams(args)}`,
        }),
        invalidatesTags: ['GET_NOTE_BY_CUSTOMER_ID'],
      }),
      saveNoteByLeadId: build.mutation({
        query: (args) => ({
          url: `workspaces/notes`,
          method: 'POST',
          body: args,
        }),
        invalidatesTags: ['SAVE_NOTE_BY_LEAD_ID'],
      }),
      saveNoteByCustomerId: build.mutation({
        query: (args) => ({
          url: `callcenter/customer-notes`,
          method: 'POST',
          body: args,
        }),
        invalidatesTags: ['SAVE_NOTE_BY_CUSTOMER_ID'],
      }),
      subscribeNotification: build.mutation({
        query: (args) => ({
          url: `workspaces/subscription`,
          method: 'POST',
          body: args,
        }),
        invalidatesTags: ['SUBSCRIPTION'],
      }),
      unsubscribeNotification: build.mutation({
        query: () => ({
          url: `workspaces/unsubscription`,
          method: 'PUT',
        }),
        invalidatesTags: ['UNSUBSCRIPTION'],
      }),
      getNotificationSettings: build.mutation({
        query: () => ({
          url: `workspaces/settings`,
          method: 'GET',
        }),
        invalidatesTags: ['GET_NOTIF_SETTINGS'],
      }),
      putNotificationSettings: build.mutation({
        query: (args) => ({
          url: `workspaces/settings`,
          method: 'POST',
          body: args,
        }),
        invalidatesTags: ['PUT_NOTIF_SETTINGS'],
      }),
      getAllWorkspaces: build.mutation({
        query: () => ({
          url: `workspaces/all-workspaces`,
        }),
        invalidatesTags: ['GET_ALL_WORKSPACE'],
      }),
      updateStatusByCustomerId: build.mutation({
        query: ({ customerId, status }) => ({
          url: `callcenter/status`,
          method: 'PUT',
          body: { status, customerId },
        }),
        invalidatesTags: ['UPDATE_STATUS_BY_CUSTOMER_ID'],
      }),
      getStatusByCustomerId: build.mutation({
        query: ({ customerId }) => ({
          url: `callcenter/${customerId}`,
          method: 'GET',
        }),
        invalidatesTags: ['GET_STATUS_BY_CUSTOMER_ID'],
      }),
      getIcon: build.mutation({
        query: ({ workspaceId }: any) => ({
          url: `workspaces/icon/${workspaceId}`,
        }),
        invalidatesTags: ['GET_NOTE_BY_CUSTOMER_ID'],
      }),
    };
  },
});

export const {
  useGetLeadsMutation,
  useUpdateLeadsStatusMutation,
  useUpdateHairLeadsStatusMutation,
  useGetLeadByIdMutation,
  useGetHairLeadByIdMutation,
  // useGetReportByLeadIdMutation,
  useGetNoteByLeadIdMutation,
  useGetNoteByCustomerIdMutation,
  useSaveNoteByLeadIdMutation,
  useSaveNoteByCustomerIdMutation,
  useGetWorkspaceMutation,
  useSubscribeNotificationMutation,
  useUnsubscribeNotificationMutation,
  useGetNotificationSettingsMutation,
  usePutNotificationSettingsMutation,
  useGetHairLeadsMutation,
  useGetAllWorkspacesMutation,
  useUpdateStatusByCustomerIdMutation,
  useGetStatusByCustomerIdMutation,
  useGetIconMutation,
} = workspaceService;
