import { useEffect, useState } from 'react';
import Input from '../bids/form/Input';
import {
  validateEmail,
  validatePhoneNumber,
} from '../../utils/formInputValidations';
import colors from '../../utils/colors';
import { toast } from 'react-toastify';
import Button from '../bids/form/Button';
import {
  useGetNotificationSettingsMutation,
  usePutNotificationSettingsMutation,
} from '../../app/services/workspace.service';
import Loading from '../loading/Loading';
import styled from 'styled-components';
import ModalCloseButton from '../common/ModalCloseButton';
import { MEDIUM_WIDTH, MOBILE_WIDTH } from '../../utils/globals';
import {
  formatPhoneNumber,
  formatPhoneNumberForBackend,
} from '../../utils/helperFunctions';

const initialState = { isValid: false, value: '' };

const NotificationSettings = ({ onCloseModal }: any) => {
  const [email, setEmail] = useState(initialState);
  const [phoneNumber, setPhoneNumber] = useState(initialState);

  const [addedEmails, setAddedEmails] = useState<string[]>([]);
  const [addedPhoneNumbers, setAddedPhoneNumbers] = useState<string[]>([]);

  const [controlArr, setControlArr] = useState<Array<string | null>>([]);

  const [isFetching, setIsFetching] = useState(true);

  //Api hooks
  const [getNotificationSettings] = useGetNotificationSettingsMutation();
  const [putNotificationSettings] = usePutNotificationSettingsMutation();

  useEffect(() => {
    getInitialSettings();
  }, []);

  const getInitialSettings = async () => {
    const { data }: any = await getNotificationSettings({});
    if (data?.data) {
      setAddedPhoneNumbers(data?.data?.notificationNumbers);
      setAddedEmails(data?.data?.notificationEmails);
      setIsFetching(false);
    }
  };

  const onChangeEmail = (event: any) => {
    const value = event.target.value;
    if (!validateEmail(value)) {
      return setEmail({ value, isValid: false });
    }
    setEmail({ value, isValid: true });
  };

  const handleEmailButtonClick = () => {
    if (
      email.isValid &&
      !addedEmails.includes(email.value) &&
      addedEmails.length < 3
    ) {
      setAddedEmails([...addedEmails, email.value]);
      setEmail(initialState);
      setControlArr((prev: Array<string | null>) => {
        if (!prev.includes(email.value)) {
          return [...prev, email.value];
        } else {
          return prev.filter((item) => item !== email.value);
        }
      });
    } else if (addedEmails.includes(email.value)) {
      toast.info('Bu e-posta zaten eklenmiş.');
    } else if (addedEmails.length >= 3) {
      toast.info('Maksimum 3 e-posta ekleyebilirsiniz.');
    } else {
      toast.error('Geçersiz e-posta adresi.');
    }
  };

  const onChangePhoneNumber = (event: any) => {
    const value = event.target.value;
    if (!validatePhoneNumber(value)) {
      return setPhoneNumber({ value, isValid: false });
    }
    setPhoneNumber({ value, isValid: true });
  };

  const handlePhoneButtonClick = () => {
    const formattedPhoneNumber = formatPhoneNumberForBackend(phoneNumber.value);
    if (
      phoneNumber.isValid &&
      !addedPhoneNumbers.includes(formattedPhoneNumber) &&
      addedPhoneNumbers.length < 4
    ) {
      setAddedPhoneNumbers([...addedPhoneNumbers, formattedPhoneNumber]);
      setPhoneNumber(initialState);
      setControlArr((prev: Array<string | null>) => {
        if (!prev.includes(formattedPhoneNumber)) {
          return [...prev, formattedPhoneNumber];
        } else {
          return prev.filter((item) => item !== formattedPhoneNumber);
        }
      });
    } else if (addedPhoneNumbers.includes(formattedPhoneNumber)) {
      toast.info('Bu telefon numarası zaten eklenmiş.');
    } else if (addedPhoneNumbers.length >= 4) {
      toast.info('Maksimum 4 cep telefonu numarası ekleyebilirsiniz.');
    } else {
      toast.error('Geçersiz cep telefonu numarası.');
    }
  };

  const removePhoneNumber = (phoneNumberToRemove: string) => {
    setAddedPhoneNumbers(
      addedPhoneNumbers.filter((phone) => phone !== phoneNumberToRemove)
    );
    setControlArr((prev: any) => {
      if (prev.includes(phoneNumberToRemove)) {
        return prev.filter((item: string) => item !== phoneNumberToRemove);
      } else {
        return [...prev, phoneNumberToRemove];
      }
    });
  };

  const removeEmail = (emailToRemove: string) => {
    setAddedEmails(addedEmails.filter((mail) => mail !== emailToRemove));
    setControlArr((prev: any) => {
      if (prev.includes(emailToRemove)) {
        return prev.filter((item: string) => item !== emailToRemove);
      } else {
        return [...prev, emailToRemove];
      }
    });
  };

  const onSubmitHandler = async () => {
    if (controlArr.length === 0) {
      return toast.info('Herhangi bir işlem yapmadınız.');
    }
    try {
      const { data }: any = await putNotificationSettings({
        notificationNumbers: addedPhoneNumbers,
        notificationEmails: addedEmails,
      });
      if (data?.data) {
        setAddedPhoneNumbers(data?.data?.notificationNumbers);
        setAddedEmails(data?.data?.notificationEmails);
        toast.success('Ayarlarınız başarılı şekilde kaydedilmiştir.');
        onCloseModal();
      }
    } catch (error) {
      console.info(error);
    }
  };

  const onCloseHandler = () => {
    if (controlArr.length > 0) {
      return toast.error('Değişikliklerinizi kaydetmediniz.');
    }
    onCloseModal();
  };

  return (
    <>
      <ModalCloseButton onClick={onCloseHandler}></ModalCloseButton>
      <InputContainer>
        <Input
          label={`Cep Telefonu`}
          name='notification-number'
          placeHolder={'+90'}
          onChangeHandler={onChangePhoneNumber}
          value={phoneNumber.value}
          isAddedButtonHidden={false}
          addedButtonClick={handlePhoneButtonClick}
        />
        <Input
          label={`E-Mail`}
          name='notification-mail'
          value={email.value}
          placeHolder={'Email'}
          onChangeHandler={onChangeEmail}
          isAddedButtonHidden={false}
          addedButtonClick={handleEmailButtonClick}
        />
      </InputContainer>
      {!isFetching && (
        <Container>
          <PhoneNumbersContainer>
            <h4>Eklenen Numaralar</h4>
            {addedPhoneNumbers.map((phone) => (
              <Item>
                {formatPhoneNumber(phone)}
                <StyledButton onClick={() => removePhoneNumber(phone)}>
                  X
                </StyledButton>
              </Item>
            ))}
          </PhoneNumbersContainer>
          <MailsContainer>
            <h4>Eklenen Mailler</h4>
            {addedEmails.map((mail) => (
              <Item>
                {mail}
                <StyledButton onClick={() => removeEmail(mail)}>X</StyledButton>
              </Item>
            ))}
          </MailsContainer>
        </Container>
      )}
      {isFetching && (
        <LoadingContainer>
          <Loading width={'65px'} />
        </LoadingContainer>
      )}
      <ButtonsContainer $isFetching={isFetching}>
        <Button text='Kaydet' onClickHandler={onSubmitHandler} type='save' />
        <Button
          text='Geri Al'
          onClickHandler={() => {
            if (controlArr.length !== 0) {
              getInitialSettings();
              setControlArr([]);
              toast.info('Yapılan değişiklikler geri alındı.');
            } else {
              toast.error('Herhangi bir değişiklik bulunamadı.');
            }
          }}
          type='update'
        />
      </ButtonsContainer>
    </>
  );
};

const InputContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
`;

const Container = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 20px;
  @media (max-width: ${MEDIUM_WIDTH}px) {
    flex-direction: column;
    row-gap: 25px;
  }
`;

const PhoneNumbersContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  row-gap: 10px;
  flex-grow: 1;
`;

const MailsContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  row-gap: 10px;
  flex-grow: 1;
  @media (max-width: ${MEDIUM_WIDTH}px) {
    align-items: flex-start;
  }
`;

const ButtonsContainer = styled.div<{ $isFetching?: boolean }>`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-top: ${({ $isFetching }) => ($isFetching ? '0' : '35px')};
  gap: 10px;
`;

const Item = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 20px;
  background-color: ${colors.mainLightBlue};
  padding: 5px 15px;
  border-radius: 20px;
  color: ${colors.mainBlue};
  @media (max-width: ${MOBILE_WIDTH}px) {
    font-size: 13px;
  }
`;

const StyledButton = styled.button`
  cursor: pointer;
  background: gray;
  padding: 0 10px 0 10px;
  margin-left: 5px;
  border: none;
  background-color: transparent;
  color: ${colors.mainBlue};
`;

const LoadingContainer = styled.div`
  display: flex;
  justify-content: center;
  height: 150px;
`;

export default NotificationSettings;
