import { PersistGate } from 'redux-persist/integration/react';
import { persistor } from './app/store';
import SocketContextProvider from './context/socket-context';
import Routes from './routes/index';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import styled from 'styled-components';
import colors from './utils/colors';

function App() {
  return (
    <PersistGate loading={null} persistor={persistor}>
      <SocketContextProvider>
        <Routes />
        <StyledToastContainer
          position='top-center'
          autoClose={5000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          theme='light'
          style={{
            zIndex: 4,
          }}
        />
      </SocketContextProvider>
    </PersistGate>
  );
}

const StyledToastContainer = styled(ToastContainer)`

//Error
.Toastify__progress-bar--error {
  background-color: ${colors.mainRed}; 
}
.Toastify__toast--error .Toastify__toast-icon svg {
  fill: ${colors.mainRed};
}

//Success
.Toastify__progress-bar--success {
  background-color: ${colors.mainBlue}; 
}
.Toastify__toast--success .Toastify__toast-icon svg {
  fill: ${colors.mainBlue};
}


`;

export default App;
