import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

import { BaseQueryApi } from '@reduxjs/toolkit/dist/query/baseQueryTypes';
import {
  FetchArgs,
  FetchBaseQueryArgs,
} from '@reduxjs/toolkit/dist/query/fetchBaseQuery';

import { RootState } from './store';
import { getBaseUrl } from '../config';

const baseUrl = getBaseUrl();

const TIMEOUT = 30 * 1000;

const fetchBase = () => {
  const baseQueryApi = fetchBaseQuery({
    baseUrl,
    prepareHeaders: (headers, { getState }) => {
      const { token } = (getState() as RootState).auth;
      if (token) {
        headers.set('Authorization', `Bearer ${token}`);
      }
      return headers;
    },
    timeout: TIMEOUT,
  });

  return async (
    args: FetchArgs,
    api: BaseQueryApi,
    extraOptions: FetchBaseQueryArgs
  ) => {
    const result: any = await baseQueryApi(args, api, extraOptions);
    return result;
  };
};

const api = createApi({
  reducerPath: 'apis',
  tagTypes: [
    'AUTH',
    'LEADS',
    'GET_BID_BY_ID',
    'UPDATE_AUTH',
    'TRANSACTIONS',
    'TRANSACTIONS_LIMIT',
    'GET_LEADS',
    'GET_WORKSPACE',
    'UPDATE_LEADS_STATUS',
    'GET_REPORT_BY_LEAD_ID',
    'GET_LEAD_BY_ID',
    'GET_NOTE_BY_LEAD_ID',
    'GET_NOTE_BY_CUSTOMER_ID',
    'SAVE_NOTE_BY_CUSTOMER_ID',
    'SAVE_NOTE_BY_LEAD_ID',
    'ACTIVATE_TOGGLE',
    'CREDIT',
    'CREATE_APP',
    'SUBSCRIPTION',
    'UNSUBSCRIPTION',
    'GET_NOTIF_SETTINGS',
    'PUT_NOTIF_SETTINGS',
    'UNSUBSCRIBE',
    'SEND_CODE',
    'RESEND_CODE',
    'DROPDOWN_COMPANY',
    'GET_CUSTOMERS',
    'POST_CUSTOMER',
    'GET_ALL_WORKSPACE',
    'GET_APPOINTED',
    'UPDATE_STATUS_BY_CUSTOMER_ID',
    'GET_STATUS_BY_CUSTOMER_ID',
  ],
  baseQuery: fetchBase(),
  endpoints: () => ({}),
});

export default api;
