import { Navigate, Outlet } from 'react-router-dom';
import { useAppSelector } from '../app/hooks';

const ConditionalWhatsappRoute = () => {
  const workspace = useAppSelector((store) => store.workspace.data);
  const isWpFeatureAvailable = workspace.whatsappFeature;

  console.log('Conditional Whatsapp Route ');

  if (!isWpFeatureAvailable) return <Navigate to='/offers' />;

  return (
    <>
      <Outlet />
    </>
  );
};

export default ConditionalWhatsappRoute;
