import styled from 'styled-components';
import { BANNER_DESC, BANNER_TITLE } from '../../utils/constant';
import { MOBILE_WIDTH } from '../../utils/globals';

const HeroBanner = () => {
  return (
    <StyledDiv>
      <div>
        <h1>{BANNER_TITLE}</h1>
        <p>{BANNER_DESC}</p>
      </div>
    </StyledDiv>
  );
};

const StyledDiv = styled.div`
  height: 700px;
  width: 100%;
  background-image: url(/assets/images/banner.png);
  background-size: cover;
  background-position: center;
  display: flex;
  align-items: center;
  padding: 0px 10%;

  & > div {
    flex: 0.35;
    margin-top: 60px;
  }

  h1 {
    font-size: 40px;
    font-weight: 600;
    line-height: 54px;
    margin-bottom: 30px;

    @media screen and (max-width: ${MOBILE_WIDTH}px) {
      font-size: 30px;
      font-weight: 500;
      line-height: 34px;
      margin-bottom: 10px;
      
  }
  }

  p {
    font-size: 20px;
    font-weight: 400;
    line-height: 24.2px;
    @media screen and (max-width: ${MOBILE_WIDTH}px) {
      font-size: 14px;
      font-weight: 300;
      line-height: 18px;
  }
  }


  @media screen and (max-width: ${MOBILE_WIDTH}px) {
    background-position: bottom 0px right -55px;
    height: 400px;
    width: 100;
  }

`;

export default HeroBanner;
