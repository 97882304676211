import styled from 'styled-components';
import HeroBanner from '../../components/landing/HeroBanner';
import LandingContent from '../../components/landing/LandingContent';
import ContentCard from '../../components/landing/ContentCard';
import NumberedCard from '../../components/landing/NumberedCard';
import {
  CARD_TEXT_1,
  CARD_TEXT_2,
  CARD_TEXT_3,
  CARD_TEXT_4,
  CARD_TITLE_1,
  CARD_TITLE_2,
  CARD_TITLE_3,
  CARD_TITLE_4,
  NUMBERED_CARD_TEXT_1,
  NUMBERED_CARD_TEXT_2,
  NUMBERED_CARD_TEXT_3,
  NUMBERED_CARD_TEXT_4,
} from '../../utils/constant';

const Landing = () => {
  return (
    <>
      <HeroBanner />
      <Container>
        <LandingContent title='Neden HangiCheckup.com?' id={'which-checkup'}>
          <CardsContainer>
            <ContentCard
              image='/assets/images/landing-card1.png'
              title={CARD_TITLE_1}
              text={CARD_TEXT_1}
            />
            <ContentCard
              image='/assets/images/landing-card2.png'
              title={CARD_TITLE_2}
              text={CARD_TEXT_2}
            />
            <ContentCard
              image='/assets/images/landing-card3.png'
              title={CARD_TITLE_3}
              text={CARD_TEXT_3}
            />
            <ContentCard
              image='/assets/images/landing-card4.png'
              title={CARD_TITLE_4}
              text={CARD_TEXT_4}
            />
          </CardsContainer>
        </LandingContent>

        <LandingContent
          title='HangiCheckup.com Nasıl Çalışır?'
          id={'how-it-works'}
        >
          <NumberedCardsContainer>
            <NumberedCard
              number='1'
              image='/assets/images/card-image1.png'
              text={NUMBERED_CARD_TEXT_1}
            />
            <NumberedCard
              number='2'
              image='/assets/images/card-image2.png'
              text={NUMBERED_CARD_TEXT_2}
            />
            <NumberedCard
              number='3'
              image='/assets/images/card-image4.png'
              text={NUMBERED_CARD_TEXT_3}
            />
            <NumberedCard
              number='4'
              image='/assets/images/card-image3.png'
              text={NUMBERED_CARD_TEXT_4}
            />
          </NumberedCardsContainer>
        </LandingContent>
      </Container>
    </>
  );
};

const Container = styled.div`
  padding: 0px 10% 70px 10%;
  gap: 30px;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const CardsContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
`;

const NumberedCardsContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  gap: 10px;
`;

export default Landing;
