import styled from 'styled-components';
import colors from '../../../utils/colors';
import {
  formatPhoneNumber,
  getBackgroudColorByStatus,
  getColorByStatus,
  getLabelByStatus,
} from '../../../utils/helperFunctions';
import dateFormat from 'dateformat';
import RowDropdown from './RowDropdown';
import { useEffect, useState } from 'react';
import { useUpdateHairLeadsStatusMutation } from '../../../app/services/workspace.service';
import { useNavigate } from 'react-router-dom';

const dropdownOptions: { [key: string]: string } = {
  default: 'Durum seçiniz',
  busy: 'Meşgul',
  offered: 'Teklif yapıldı',
  rejected: 'Olumsuz',
  notReached: 'Ulaşılamadı',
  sold: 'Satış yapıldı',
};

const HairTransplantRow = ({
  row,
  index,
  moreThanOnePacket,
  onFetchData,
}: any) => {
  const [isOpened, setOpen] = useState(false);
  const [isTooltipVisible, setTooltipVisible] = useState(false);
  const [condition, setSelectedCondition] = useState(row.condition);
  const [currentStatus, setCurrentStatus] = useState(row.status);
  const [updateHairLeadsStatus] = useUpdateHairLeadsStatusMutation();
  const navigate = useNavigate();

  const onTriggerAction = async (
    currentStatus: string,
    leadId: string,
    condition: string
  ) => {
    try {
      const { data }: any = await updateHairLeadsStatus({
        nextStatus: currentStatus === 'waiting' ? 'called' : null,
        leadId,
        currentStatus,
        condition,
      });

      if (data?.success) {
        setCurrentStatus(data?.data?.currentStatus);
        setSelectedCondition(data?.data?.condition);
      }
    } catch (error) {
      console.log(error);
    } finally {
      onFetchData();
    }
  };

  useEffect(() => {
    setOpen(false);
    setSelectedCondition(row.condition);
    setCurrentStatus(row.status);
  }, [row]);

  const handleRowClick = () => {
    setOpen((prev) => !prev);
  };

  const dropdownItemClickHandle = async (key: any) => {
    setSelectedCondition(key);
    await onTriggerAction(currentStatus, row.leadId, key);
  };

  return (
    <>
      <StyledRow
        index={index}
        moreThanOnePacket={moreThanOnePacket}
        onClick={handleRowClick}
      >
        <StyledTdForName $isOverFlowHidden $radiusLeft>
          {row.fullname}
        </StyledTdForName>
        <StyledTdForDate>
          {dateFormat(row.createdAt, 'dd-mm-yyyy HH:MM')}
        </StyledTdForDate>
        <StyledTdForNumber $isWrap>
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              position: 'relative',
              justifyContent: 'space-between',
              width: '145px',
            }}
          >
            {row.condition === 'waiting'
              ? row?.normalizedPhone
              : formatPhoneNumber(row.phone)}
            {moreThanOnePacket && (
              <ImgContainer>
                <img
                  onMouseEnter={() => setTooltipVisible(true)}
                  onMouseLeave={() => setTooltipVisible(false)}
                  style={{ width: '20px', height: '20px', cursor: 'pointer' }}
                  src='/assets/icons/blue-down-arrow.png'
                />
                {isTooltipVisible && (
                  <Tooltip $isDropdownOpen={isOpened}>
                    Bu numara ile birden fazla başvuru yapılmıştır. (Görmek için
                    tıklayınız.)
                  </Tooltip>
                )}
              </ImgContainer>
            )}
          </div>
        </StyledTdForNumber>
        {/* <StyledTdForPacket $isOverFlowHidden>
          {row.packetData.paketAdi}
        </StyledTdForPacket> */}
        <StyledTdForCallStatus $status={currentStatus}>
          <StyledStatus $status={currentStatus}>
            {getLabelByStatus(currentStatus)}
          </StyledStatus>
        </StyledTdForCallStatus>
        <StyledTdForStatus $radiusRight>
          <div
            style={{
              display: 'flex',
              // justifyContent: 'space-between',
              alignItems: 'center',
              width: '300px',
            }}
          >
            <DropdownContainer>
              <StyledSelect
                disabled={currentStatus === 'waiting' ? true : false}
                currentStatus={currentStatus}
                defaultValue={dropdownOptions[condition]}
                onClick={(e) => {
                  e.stopPropagation();
                }}
                onChange={(e) => {
                  const selectedConditionValue = e.target.value;
                  const selectedConditionKey = Object.keys(
                    dropdownOptions
                  ).find(
                    (key) => dropdownOptions[key] === selectedConditionValue
                  );
                  dropdownItemClickHandle(selectedConditionKey);
                }}
              >
                {Object.entries(dropdownOptions).map(([key, value], index) => (
                  <StyledOption disabled={key === 'default'}>
                    {value}
                  </StyledOption>
                ))}
              </StyledSelect>
            </DropdownContainer>
            <div
              style={{
                display: 'flex',
                gap: '5px',
              }}
            >
              <img
                style={{ cursor: 'pointer' }}
                width={36}
                src='/assets/icons/info-icon.png'
                onClick={(e) => {
                  e.stopPropagation();
                  // onShowDetailToggle();
                  // onSelectDetailHandler(showDetail ? '' : row.leadId);
                  navigate(row.leadId, { state: { type: 'hair', row } });
                }}
              />
              {currentStatus === 'waiting' && (
                <img
                  style={{ cursor: 'pointer' }}
                  width={36}
                  onClick={(e) => {
                    e.stopPropagation();
                    onTriggerAction(currentStatus, row.leadId, condition);
                  }}
                  src='/assets/icons/call-icon.png'
                />
              )}
            </div>
          </div>
        </StyledTdForStatus>
      </StyledRow>
      {moreThanOnePacket && isOpened && <RowDropdown row={row} />}
    </>
  );
};

const StyledRow = styled.tr<{ index: number; moreThanOnePacket: boolean }>`
  background: ${({ index }) =>
    index % 2 === 0 ? colors.mainLightBlue : 'white'};
  cursor: ${({ moreThanOnePacket }) =>
    moreThanOnePacket ? 'pointer' : 'unset'};
  height: 80px;
`;

const StyledSelect = styled.select<{ currentStatus: string }>`
  cursor: ${({ currentStatus }) =>
    currentStatus === 'waiting' ? 'no-drop' : 'pointer'};
  border: 1px solid ${colors.mainBlue};
  border-radius: 10px;
  text-align: center;
  margin-right: 20px;
  font-weight: 400;
  font-size: 16px;
  color: ${colors.mainBlue};
  width: 200px;
  height: 30px;
  background-color: ${({ currentStatus }: any) =>
    currentStatus === 'waiting' ? '#e9e9e9' : 'white'};
`;

const StyledOption = styled.option`
  font-size: 16px;
`;

const DropdownContainer = styled.div`
  position: relative;
`;

{
  /* <div style={{
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    gap: '20px',
  }}>
    <ShowPhoneNumber
      $showDetail={showDetail}
      $clickable={row.status !== 'called'}
      onClickCapture={(e) => {
        e.stopPropagation();
        onTriggerAction(row.status, row.leadId);
      }}
    >
      {getActionLabelByStatus(row.status)}
    </ShowPhoneNumber>
    <StyledDetailButton
      onClick={(e) => {
        e.stopPropagation();
        onShowDetailToggle();
        onSelectDetailHandler(showDetail ? '' : row.leadId);
        window.scrollTo(0, 0);
      }}
    >
      Detay
    </StyledDetailButton>
  </div> */
}

const commonCellStyle = ({
  $isOverFlowHidden,
  $isWrap,
  $radiusLeft,
  $radiusRight,
}: {
  $isOverFlowHidden?: boolean;
  $isWrap?: boolean;
  $radiusLeft?: boolean;
  $radiusRight?: boolean;
}) => `
  line-height: 24px;
  padding: 0px 10px 0px 10px;
  text-align: left;
  text-overflow: ellipsis;
  overflow: ${$isOverFlowHidden ? 'hidden' : 'none'};
  white-space: ${$isWrap ? 'wrap' : 'nowrap'};
  border-top-left-radius: ${$radiusLeft ? '14px' : '0px'};
  border-bottom-left-radius: ${$radiusLeft ? '14px' : '0px'};
  border-top-right-radius: ${$radiusRight ? '14px' : '0px'};
  border-bottom-right-radius: ${$radiusRight ? '14px' : '0px'};
  @media (max-width: 768px) {
    line-height: 16px;
  }
`;

const StyledTd = styled.td<{
  $radiusLeft?: boolean;
  $radiusRight?: boolean;
  $status?: string;
  $isWrap?: boolean;
  $isOverFlowHidden?: boolean;
}>`
  ${commonCellStyle}
`;

const StyledTdForName = styled(StyledTd)<{
  $radiusLeft?: boolean;
  $radiusRight?: boolean;
}>`
  min-width: 140px;
  max-width: 140px;
  font-weight: 700;
  font-size: 16px;
`;

const StyledTdForDate = styled(StyledTd)<{
  $radiusLeft?: boolean;
  $radiusRight?: boolean;
}>`
  width: 170px;
`;

const StyledTdForNumber = styled(StyledTd)<{
  $radiusLeft?: boolean;
  $radiusRight?: boolean;
}>`
  width: 180px;
`;

const StyledTdForPacket = styled(StyledTd)<{
  $radiusLeft?: boolean;
  $radiusRight?: boolean;
}>`
  max-width: 220px;
  min-width: 220px;
`;

const StyledTdForCallStatus = styled(StyledTd)<{
  $radiusLeft?: boolean;
  $radiusRight?: boolean;
}>`
  width: 150px;
  min-width: 150px;
`;

const StyledTdForStatus = styled(StyledTd)<{
  $radiusLeft?: boolean;
  $radiusRight?: boolean;
}>`
  width: 350px;
`;

const StyledStatus = styled.div<{ $status: string }>`
  color: ${({ $status }) => getColorByStatus($status)};
  background-color: ${({ $status }) => getBackgroudColorByStatus($status)};
  border: 1px solid ${({ $status }) => getBackgroudColorByStatus($status)};
  border-radius: 12px;
  padding: 2px 5px;
  min-width: fit-content;
  text-align: center;
`;

const StyledDetailButton = styled.div`
  border-radius: 10px;
  background: white;
  width: 50px;
  min-width: 50px;
  height: 25px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid ${colors.mainBlue};
  color: ${colors.mainBlue};
  cursor: pointer;
  font-size: 14px;
`;

const ShowPhoneNumber = styled.div<{
  $showDetail: boolean;
  $clickable?: boolean;
}>`
  font-size: 14px;
  min-width: ${({ $showDetail }) => ($showDetail ? 'min-content' : '135px')};
  color: ${({ $clickable }) => ($clickable ? colors.mainBlue : '#7c7575')};
  cursor: ${({ $clickable }) => ($clickable ? 'pointer' : '')};
  @media (max-width: 768px) {
    min-width: min-content;
  }

  &:hover {
    color: ${({ $clickable }) => ($clickable ? colors.mainDarkBlue : '')};
  }
`;

const ImgContainer = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const Tooltip = styled.div<{
  $isDropdownOpen?: boolean;
}>`
  position: absolute;
  bottom: -29.5px;
  right: -150px;
  transform: translateX(50%);
  width: 280px;
  background-color: #455bd5;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 15px 15px;
  display: ${({ $isDropdownOpen }) => ($isDropdownOpen ? 'none' : '')};
  &:after {
    content: ' ';
    position: absolute;
    top: 50%;
    left: -8.5px;
    margin-top: -5px;
    border-width: 5px;
    border-style: solid;
    border-color: transparent #455bd5 transparent transparent;
  }
`;

// const StyledDropdownContent = styled.div`
//   position: absolute;
//   background-color: #f9f9f9;
//   min-width: 200px;
//   box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
//   z-index: 1;
// `;

// const DropdownItem = styled.div`
//   padding: 8px 16px;
//   cursor: pointer;

//   &:hover {
//     background-color: #ddd;
//   }
// `;

const StyledDropdownButton = styled.div<{
  $isDisabled?: string;
}>`
  cursor: ${({ $isDisabled }) =>
    $isDisabled === 'waiting' ? 'no-drop' : 'pointer'};
  display: flex;
  justify-content: space-between;
  align-items: center;
  border: 1px solid ${colors.mainBlue};
  border-radius: 10px;
  padding: 0px 10px;
  margin-right: 20px;
  font-weight: 400;
  color: ${colors.mainBlue};
  width: 200px;
  height: 30px;
  background-color: ${({ $isDisabled }) =>
    $isDisabled === 'waiting' ? '#e9e9e9' : 'white'};
`;

const Icon = styled.img`
  width: 20px;
  height: 20px;
`;

const ButtonText = styled.span`
  margin-left: 5px;
`;

export default HairTransplantRow;
