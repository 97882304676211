import { createContext, useEffect, useState } from 'react';
import { io, Socket } from 'socket.io-client';
import { useAppSelector } from '../app/hooks';
import { getSocketBaseUrl } from '../config';
const socketBaseUrl = getSocketBaseUrl();

export const AppContext = createContext<any>({
  socket: undefined,
});

const SocketContextProvider = ({ children }: any) => {
  const token = useAppSelector((store) => store.auth.token);
  const [socketInstance, setSocketInstance] = useState<any>(null);
  const workspace = useAppSelector((store) => store.workspace.data);

  const socket: Socket = io(socketBaseUrl, {
    path: `/${(workspace?.company?.isim as string)?.toLowerCase()}`,
    autoConnect: false,
    upgrade: true,
    transports: ['websocket', 'polling'],
  });

  useEffect(() => {
    if (token && !socketInstance && workspace) {
      socket.io.opts.query = { cdvntr_user_registerid: token };
      console.log('Socket connection attempt with token:', token);
      socket.connect();
      socket.on('connect', () => {
        setSocketInstance(socket);
      });
    } else if (!token) {
      socket.io.opts.extraHeaders = {};
      socket.on('disconnect', () => {
        console.log('Disconnected');
        setSocketInstance(null);
      });
      socket.disconnect();
    }
    return () => {
      socket.off();
    };
  }, [token, socketInstance, socket, workspace]);

  const value = {
    socket: socketInstance,
  };

  return <AppContext.Provider value={value}>{children}</AppContext.Provider>;
};

export default SocketContextProvider;
