import React from 'react';
import styled from 'styled-components';
import Button from './Button';

interface InputProps {
  value?: string;
  onChangeHandler: (e: React.ChangeEvent<HTMLInputElement>) => void;
  placeHolder: string;
  name: string;
  label: string;
  isAddedButtonHidden?: boolean;
  addedButtonClick?: (e: React.MouseEvent<HTMLButtonElement>) => void;
}

const Input: React.FC<InputProps> = ({
  value,
  onChangeHandler,
  placeHolder,
  name,
  label,
  isAddedButtonHidden = true,
  addedButtonClick
}) => {
  return (
    <InputContainer>
      <Label htmlFor={name}>{label}</Label>
    <div style={{display: "flex", justifyContent: "flex-start", alignItems: "center", gap:"10px"}}>
      <StyledInput
        name={name}
        placeholder={placeHolder}
        value={value}
        onChange={onChangeHandler}
        $isAddedButtonHidden = {isAddedButtonHidden}
      />
    <Button text='Ekle' onClickHandler={addedButtonClick} type='update' hidden={isAddedButtonHidden}></Button>
    </div>
    </InputContainer>
  );
};

const InputContainer = styled.div`
  margin-bottom: 5px;
`;

const Label = styled.label`
  display: block;
  font-weight: 600;
  font-size: 14px;
  line-height: 17px;
  margin-bottom: 10px;
`;

const StyledInput = styled.input<{$isAddedButtonHidden: boolean}>`
  width:  ${({ $isAddedButtonHidden }) =>
  $isAddedButtonHidden ? "100%" : "90%"};
  border: 1px solid gray;
  border-radius: 10px;
  padding: 4px 8px;
  height: 40px;
`;

export default Input;
