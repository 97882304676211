import api from '../api';

export const transactionService = api.injectEndpoints({
  endpoints: (build) => {
    return {
      getTransactions: build.mutation({
        query: (args) => ({
          url: `transactions?${new URLSearchParams(args)}`,
          method: 'PUT',
        }),
        invalidatesTags: ['TRANSACTIONS'],
      }),
      getTransactionsLimits: build.mutation({
        query: (args) => ({
          url: `transactions?${new URLSearchParams(args)}`,
          method: 'GET',
        }),
        invalidatesTags: ['TRANSACTIONS_LIMIT'],
      }),
    };
  },
});

export const { useGetTransactionsMutation, useGetTransactionsLimitsMutation } =
  transactionService;
