import styled from 'styled-components';
import colors from '../../../utils/colors';
import CallcenterRow from '../row/CallcenterRow';

const dummyColumns = [
  { key: 'customerName', label: 'Ad-Soyad', filterable: false },
  { key: 'createdAt', label: 'Tarih', filterable: false },
  { key: 'customerPhone', label: 'Telefon', filterable: false },
  { key: 'packetName', label: 'Paket Adı', filterable: false },
  { key: 'lastDate', label: 'Son Geçerlilik', filterable: false },
  { key: 'status', label: 'Durum', filterable: false },
  { key: 'upfrontPayment', label: 'Ön Ödeme', filterable: true },
  { key: 'totalPayment', label: 'Toplam Tutar', filterable: true },
  { key: 'pdfNumber', label: 'Seri No', filterable: true },
];

const CallcenterTable = ({ columns = dummyColumns, data = [] }) => {
  return (
    <div>
      <div style={{ overflow: 'auto', paddingTop: '2px' }}>
        <StyledTable>
          <tr>
            {columns.map(({ key, label }) => (
              <StyledTh key={key}>
                <InnerTh>
                  <InnerThContainer>{label}</InnerThContainer>
                </InnerTh>
              </StyledTh>
            ))}
          </tr>
          <Space />
          {data.length > 0 &&
            data.map((row: any, index) => {
              return <CallcenterRow index={index} row={row} />;
            })}
        </StyledTable>
      </div>
    </div>
  );
};

const StyledTable = styled.table`
  width: 100%;
  border-collapse: collapse;
  min-width: 850px;
  margin-bottom: 15px;
  position: relative;
`;

const StyledTh = styled.th<{ sortable?: boolean }>`
  color: ${colors.mainBlue};
  text-align: left;
  padding: 0px 10px 0px 10px;
  width: auto;
  height: 50px;
`;

const InnerTh = styled.div<{ $sortable?: boolean; $filterable?: boolean }>`
  margin: auto;
  font-size: 16px;
  display: flex;
  align-items: center;
`;

const InnerThContainer = styled.div<{
  $sortable?: boolean;
  $filterable?: boolean;
}>`
  cursor: ${({ $sortable, $filterable }) =>
    $sortable || $filterable ? 'pointer' : 'auto'};
  display: flex;
  justify-content: start;
`;

const Space = styled.div`
  height: 30px;
`;

const NoOfferMessage = styled.div`
  text-align: center;
  padding: 100px 0;
  font-weight: 600;
`;

export default CallcenterTable;
