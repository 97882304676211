import { useEffect, useRef, useState } from 'react';
import styled, { keyframes } from 'styled-components';
import dateFormat from 'dateformat';
import {
  getBackgroudColorByStatus,
  getColorByStatus,
  getLabelByStatus,
} from '../../../utils/helperFunctions';
import colors from '../../../utils/colors';
import {
  useGetHairLeadByIdMutation,
  useGetLeadByIdMutation,
  useGetNoteByLeadIdMutation,
  // useGetReportByLeadIdMutation,
  useSaveNoteByLeadIdMutation,
} from '../../../app/services/workspace.service';

import {
  ADD_NOTE,
  END_DATE,
  MULTIPLE_HOSPITAL_OFFERS,
  SINGLE_HOSPITAL_OFFER,
  START_DATE,
} from '../../../utils/constant';
import { useAppSelector } from '../../../app/hooks';
import Loading from '../../../components/loading/Loading';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import Button from '../../../components/bids/form/Button';
import CallcenterForm from '../../callcenter/CallcenterForm';

const OfferDetail = () => {
  // const [reports, setReports] = useState<any>();
  const [leadInfo, setLeadInfo] = useState<any>();
  const [note, setNote] = useState('');
  const [isEditing, setEditing] = useState(false);
  const textareaRef = useRef<any>();
  const workspace = useAppSelector((store) => store.workspace.data);
  const { offerId } = useParams();

  const location = useLocation();
  const navigate = useNavigate();

  // const [getReportByWorkspaceId] = useGetReportByLeadIdMutation();
  const [getLeadById] = useGetLeadByIdMutation();
  const [getHairLeadById] = useGetHairLeadByIdMutation();
  const [getNoteByLeadId] = useGetNoteByLeadIdMutation();
  const [saveNoteByLeadId] = useSaveNoteByLeadIdMutation();
  const [isFetchingObj, setFetchingObj] = useState({
    isNoteFetched: true,
    // isReportFetched: true,
    isInfoFetched: true,
  });

  const handleInputChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    setNote(event.target.value);
  };

  const handleEditToggle = () => {
    setEditing(!isEditing);
  };

  useEffect(() => {
    if (isEditing) {
      textareaRef.current.focus();
      textareaRef.current.setSelectionRange(note?.length, note?.length);
    }
  }, [isEditing, note]);

  // const fetchReport = async () => {
  //   const { data }: any = await getReportByWorkspaceId({
  //     leadId: offerId,
  //   });
  //   if (data?.success) {
  //     setFetchingObj((prev) => ({ ...prev, isReportFetched: false }));
  //     setReports(data?.data);
  //   }
  // };

  const fetchLeadInfo = async () => {
    if (location?.state?.type === 'hair') {
      const { data }: any = await getHairLeadById({
        leadId: offerId,
      });
      if (data?.success) {
        setFetchingObj((prev) => ({ ...prev, isInfoFetched: false }));
        setLeadInfo(data?.leadInfo);
      }
    } else {
      const { data }: any = await getLeadById({
        leadId: offerId,
      });
      if (data?.success) {
        setFetchingObj((prev) => ({ ...prev, isInfoFetched: false }));
        setLeadInfo(data?.leadInfo);
      }
    }
  };

  const fetchNote = async () => {
    const { data }: any = await getNoteByLeadId({
      leadId: offerId,
    });
    if (data?.success) {
      setFetchingObj((prev) => ({ ...prev, isNoteFetched: false }));
      setNote(data?.data);
    }
  };

  const handleSave = async () => {
    const { data }: any = await saveNoteByLeadId({
      leadId: offerId,
      content: note,
    });

    if (data?.success) {
      setEditing(false);
    }
  };

  const onCloseDetail = () => {
    navigate('/offers', { state: { currentPage: location.state.currentPage } });
  };

  useEffect(() => {
    // fetchReport();
    fetchLeadInfo();
    fetchNote();
  }, []);

  const fetchingData = !Object.values(isFetchingObj).every((item) => !item);

  console.log(leadInfo?.offer?.packet);

  return (
    <OuterWrapper>
      <Content $fetchingData={fetchingData}>
        {!fetchingData && (
          <div
            style={{ display: 'flex', gap: '25px', flexDirection: 'column' }}
          >
            <StyledContainer>
              <Header>
                <Name>{leadInfo?.offer?.fullname}</Name>
                <CloseButton onClick={onCloseDetail}>X</CloseButton>
              </Header>
              <MainSectionWrapper>
                <InfoContainer>
                  <InfoSection>
                    <InfoLabel>İstek Tarihi</InfoLabel>
                    <InfoValue>
                      {dateFormat(leadInfo?.createdAt, 'dd-mm-yyyy HH:MM')}
                    </InfoValue>
                  </InfoSection>
                  <InfoSection>
                    <InfoLabel>Durum</InfoLabel>
                    <StatusLabel $status={leadInfo?.status}>
                      {getLabelByStatus(leadInfo?.status)}
                    </StatusLabel>
                  </InfoSection>
                  <InfoSection>
                    <InfoLabel>Kişinin Bulunduğu İlçe</InfoLabel>
                    <InfoValue>
                      {leadInfo?.offer?.leadDistrict ||
                        leadInfo?.hairoffer?.leadDistrict ||
                        'Belirtilmedi'}
                    </InfoValue>
                  </InfoSection>
                  <InfoSection>
                    <InfoLabel>Hastane</InfoLabel>
                    <InfoValue>
                      {`Talep Edilen Kurum: ${leadInfo?.offer?.packet?.company?.isim}`}
                    </InfoValue>
                  </InfoSection>
                  {leadInfo?.offer?.packet && (
                    <InfoSection>
                      <InfoLabel>Paket</InfoLabel>
                      <InfoValue>
                        {leadInfo?.offer?.packet?.paketAdi?.tr ||
                          leadInfo?.offer?.packet?.paketAdi}
                      </InfoValue>
                    </InfoSection>
                  )}
                  <InfoSection>
                    <InfoLabel>Tercih</InfoLabel>
                    <InfoValue>
                      {leadInfo?.offer?.consentMultipleHospital ||
                      leadInfo?.hairoffer?.consentMultipleHospital
                        ? MULTIPLE_HOSPITAL_OFFERS
                        : SINGLE_HOSPITAL_OFFER}
                    </InfoValue>
                  </InfoSection>
                </InfoContainer>
                {/* <ReportSection>
                {reports?.map((report: any, key: number) => (
                  <ReportItem key={key}>
                    <ReportDate>
                      {report?.action === 'calling' ? END_DATE : START_DATE}
                    </ReportDate>
                    <div>{dateFormat(report?.createdAt, 'dd-mm-yyyy HH:MM')}</div>
                    <div style={{ color: 'gray' }}>({report?.description})</div>
                  </ReportItem>
                ))}
              </ReportSection> */}
                <NoteSectionWrapper>
                  <NoteSection>
                    <NoteLabel>Notlar</NoteLabel>
                    <EditableContainer>
                      {isEditing ? (
                        <EditNoteSection>
                          <StyledTextarea
                            value={note}
                            onChange={handleInputChange}
                            ref={textareaRef}
                          ></StyledTextarea>
                          <SaveButton onClick={handleSave}>Kaydet</SaveButton>
                        </EditNoteSection>
                      ) : (
                        <DisplayNote onClick={handleEditToggle}>
                          {note || ADD_NOTE}
                        </DisplayNote>
                      )}
                    </EditableContainer>
                  </NoteSection>
                  {/* <Button
                    text='Call Center'
                    fontSize={'16px'}
                    onClickHandler={() =>
                      navigate('/callcenter', {
                        state: {
                          leadInfo: { ...leadInfo, ...location.state.row },
                        },
                      })
                    }
                    type='save'
                  /> */}
                </NoteSectionWrapper>
              </MainSectionWrapper>
            </StyledContainer>
            {workspace?.callcenterPostFeature && (
              <CallcenterForm
                rowInfo={location.state.row}
                callcenterPostFeature={workspace?.callcenterPostFeature}
              />
            )}
          </div>
        )}
        {fetchingData && <Loading width='50px' boldness='6px' />}
      </Content>
    </OuterWrapper>
  );
};

const SideBarAnimation = keyframes`
  0% {
    transform: scale(0);
    opacity: 0;
  }
  100% {
    transform: scale(1);
    opacity: 1;
  }
`;

const OuterWrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const Content = styled.div<{ $fetchingData: boolean }>`
  background: #f3f7ff;
  display: flex;
  flex-direction: column;
  /* height: 100vh; */
  gap: 20px;
  width: 80%;
  padding: 120px 5%;
  /* animation: ${SideBarAnimation} 0.5s ease forwards; */
`;

const StyledContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 40px;
`;

const Header = styled.div`
  display: flex;
  justify-content: space-between;
`;

const Name = styled.div`
  font-size: 32px;
  line-height: 24px;
  font-weight: 600;
  margin-bottom: 30px;
`;

const CloseButton = styled.div`
  cursor: pointer;
  border-radius: 10px;
  background-color: ${colors.gray60};
  padding: 10px;
  height: 36px;
  color: white;
  text-align: center;
  vertical-align: middle;
`;

const InfoContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 15px;
  padding-bottom: 30px;
  @media (max-width: 940px) {
    flex-wrap: wrap;
  }
`;

const InfoSectionWrapper = styled.div`
  display: flex;
  gap: 25px;
`;

const InfoSection = styled.div`
  display: flex;
  flex-direction: column;
  border: 1px solid rgb(154, 185, 252, 0.2);
  border-radius: 8px;
  padding: 10px;
  gap: 10px;
`;

const InfoLabel = styled.div`
  font-size: 16px;
  line-height: 19px;
  font-weight: 700;
`;

const InfoValue = styled.div`
  font-size: 16px;
  line-height: 19px;
`;

const StatusLabel = styled.div<{ $status: string }>`
  color: ${({ $status }) => getColorByStatus($status)};
  background-color: ${({ $status }) => getBackgroudColorByStatus($status)};
  border: 1px solid ${({ $status }) => getBackgroudColorByStatus($status)};
  border-radius: 12px;
  padding: 4px 12px 4px 12px;
  width: fit-content;
`;

const ReportSection = styled.div`
  display: flex;
  flex-direction: column;
`;

const ReportItem = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin-bottom: 15px;
`;

const ReportDate = styled.div`
  font-size: 16px;
  line-height: 19px;
  font-weight: 600;
`;

const NoteSection = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
`;

const NoteLabel = styled.div`
  font-size: 16px;
  line-height: 19px;
  font-weight: 700;
`;

const EditNoteSection = styled.div`
  display: flex;
  flex-direction: column;
`;

const StyledTextarea = styled.textarea`
  width: 100%;
  height: 150px;
  background: white;
  border: 1px solid rgb(210, 223, 250, 1);
  border-radius: 8px;
  padding: 8px;
  box-sizing: border-box;
  font-size: 16px;
  resize: none;
`;

const SaveButton = styled.button`
  background-color: ${colors.mainBlue};
  color: white;
  padding: 6px 12px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 12px;
  transition: background-color 0.3s;
  width: fit-content;
  align-self: flex-end;
  margin-top: 10px;
  &:hover {
    background-color: ${colors.mainDarkBlue};
  }
`;

const DisplayNote = styled.div`
  width: 100%;
  min-height: 190px;
  background: white;
  border: 1px solid rgb(154, 185, 252, 0.4);
  border-radius: 8px;
  padding: 8px;
  box-sizing: border-box;
  font-size: 16px;
  overflow-wrap: break-word;
  cursor: pointer;
`;

const EditableContainer = styled.div`
  width: 100%;
`;

const MainSectionWrapper = styled.div`
  display: flex;
  flex-direction: column;
  column-gap: 80px;
`;
const NoteSectionWrapper = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 43px;
  /* width: 340px; */
`;

export default OfferDetail;
