import { useEffect, useState } from 'react';
import CustomTab from '../../components/customTab/CustomTab';
import Bids from '../bids/Bids';
import Whatsapp from '../whatsapp/whatsapp';
import { useAppSelector } from '../../app/hooks';
import NotificationSettings from '../../components/settings/NotificationSettings';

const tabsOptions = [
  { title: 'Checkup Teklifi', tabIndex: 1 },
  { title: 'Whatsapp', tabIndex: 2 },
  { title: 'Atama Notifikasyonu', tabIndex: 3 },
  { title: 'Hastane Notifikasyonu', tabIndex: 4 },
];

const getVisibleTabs = (workspace: any) => {
  if (workspace?.callcenterGetFeature) {
    return tabsOptions.filter((item) => item.tabIndex === 4);
  } else if (workspace?.callcenterPostFeature && workspace?.whatsappFeature) {
    return tabsOptions.filter(
      (tab) => tab.tabIndex === 1 || tab.tabIndex === 2 || tab.tabIndex === 3
    );
  } else if (workspace?.callcenterPostFeature && !workspace?.whatsappFeature) {
    return tabsOptions.filter(
      (tab) => tab.tabIndex === 1 || tab.tabIndex === 3
    );
  } else if (
    !workspace?.callcenterPostFeature &&
    !workspace?.callcenterGetFeature
  ) {
    return tabsOptions.filter(
      (tab) => tab.tabIndex === 1 || tab.tabIndex === 3
    );
  } else {
    return tabsOptions;
  }
};

const Settings = () => {
  const [activeTab, setActiveTab] = useState<number>(1);
  const [tabs, setTabs] = useState<any>([]);
  const workspace = useAppSelector((store) => store.workspace.data);

  useEffect(() => {
    const updatedTabs = getVisibleTabs(workspace);
    setTabs(updatedTabs);
    if (!updatedTabs.find((tab) => tab.tabIndex === activeTab)) {
      setActiveTab(updatedTabs[0]?.tabIndex || 1);
    }
  }, [workspace]);

  const handleTabClick = (index: any) => {
    setActiveTab(index);
  };

  return (
    <div
      style={{
        display: 'flex',
        padding: '150px 5%',
        gap: '30px',
        flexDirection: 'column',
      }}
    >
      <CustomTab
        tabs={tabs}
        handleTabClick={handleTabClick}
        activeTab={activeTab}
      />
      {activeTab === 1 && (
        <>
          <Bids />
        </>
      )}
      {activeTab === 2 && (
        <>
          <Whatsapp />
        </>
      )}
      {activeTab === 3 && (
        <>
          <NotificationSettings />
        </>
      )}
      {activeTab === 4 && (
        <>
          <NotificationSettings />
        </>
      )}
    </div>
  );
};

export default Settings;
