import api from '../api';

export const marketingService = api.injectEndpoints({
  endpoints: (build) => {
    return {
      unsubscribe: build.mutation({
        query: (args) => ({
          url: `marketing/unsubscribe?${new URLSearchParams(args)}`,
          method: 'PUT',
        }),
        invalidatesTags: ['UNSUBSCRIBE'],
      }),
    };
  },
});

export const { useUnsubscribeMutation } = marketingService;
