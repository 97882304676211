import { ChangeEvent, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { useLoginMutation } from '../../app/services/auth.service';
import Input from '../../components/common/Input';
import colors from '../../utils/colors';
import {
  validateCompanyId,
  validateEmail,
  validatePassword,
  validatePhoneNumber,
} from '../../utils/formInputValidations';
import { MOBILE_WIDTH } from '../../utils/globals';
import { ErrorMessages, InputType } from '../../utils/types';
import { toast } from 'react-toastify';
import OtpInput from '../../components/otp-input/OtpInput';
import { formatPhoneNumberForBackend } from '../../utils/helperFunctions';
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import { authActions } from '../../app/slices/auth.slice';

const initialState = { isValid: false, value: '' };

const Login = () => {
  //Form states
  const [phone, setPhone] = useState(initialState);
  const [email, setEmail] = useState(initialState);
  const [password, setPassword] = useState(initialState);
  const [formSubmitted, setFormSubmitted] = useState(false);
  const [visible, setVisible] = useState(false);

  // const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const loginData = useAppSelector((state) => state.auth.loginData);
  //sms state
  // const [isOtpInputVisible, setOtpInputVisible] = useState(false);
  //Gloal State
  const [login] = useLoginMutation();

  const emailChangeHandler = (event: ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    if (!validateEmail(value)) {
      return setEmail({ value, isValid: false });
    }
    setEmail({ value, isValid: true });
  };

  const phoneChangeHandler = (event: ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    if (!validatePhoneNumber(value)) {
      return setPhone({ value, isValid: false });
    }
    setPhone({ value, isValid: true });
  };

  const passwordChangeHandler = (event: ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    if (!validatePassword(value)) {
      return setPassword({ value, isValid: false });
    }
    setPassword({ value, isValid: true });
  };

  const onChangeVisibility = () => {
    setVisible((pre) => !pre);
  };

  const onSubmitHandler = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    setFormSubmitted(true);
    if (email.isValid && phone.isValid && password.isValid) {
      const { data, error }: any = await login({
        phone: formatPhoneNumberForBackend(phone.value),
        password: password.value,
        email: email.value,
      });
      if (data?.success) {
        setPhone(initialState);
        setEmail(initialState);
        setPassword(initialState);
        setFormSubmitted(false);
        dispatch(
          authActions.changeLoginData({
            email: email.value,
            phone: phone.value,
          })
        );
      }

      toast.error(error?.data?.message);
    }
  };

  return (
    <LoginPageContainer>
      <LoginImage src='/assets/icons/hangiCheckUpLogo.png' alt='logo' />
      {loginData.email ? (
        <OtpInput
          numberOfDigits={6}
          email={loginData.email}
          phone={loginData.phone}
        />
      ) : (
        <FormContainer>
          <h1 style={{ textAlign: 'center' }}>Giriş Yap</h1>
          <form onSubmit={onSubmitHandler}>
            <Input
              type={InputType.Email}
              label='Email'
              onChange={emailChangeHandler}
              placeholder='Email'
              value={email.value}
              isErrorText={
                formSubmitted && !email.isValid ? ErrorMessages.EMAIL : ''
              }
            />
            <Input
              type={InputType.Number}
              label='Telefon'
              onChange={phoneChangeHandler}
              placeholder='+90'
              value={phone.value}
              isErrorText={
                formSubmitted && !phone.isValid
                  ? ErrorMessages.PHONE_NUMBER
                  : ''
              }
            />
            <Input
              type={visible ? InputType.Text : InputType.Password}
              label='Şifre'
              onChange={passwordChangeHandler}
              placeholder='*******'
              value={password.value}
              toggle
              visible={visible}
              onChangeVisibility={onChangeVisibility}
              isErrorText={
                formSubmitted && !password.isValid ? ErrorMessages.PASSWORD : ''
              }
            />
            <ButtonContainer>
              <Button>
                <Text>Giriş Yap</Text>
              </Button>
            </ButtonContainer>
          </form>
        </FormContainer>
      )}
    </LoginPageContainer>
  );
};

const LoginPageContainer = styled.div`
  width: 50%;
  height: 100vh;
  margin: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 50px;
  @media (max-width: ${MOBILE_WIDTH}px) {
    width: 90%;
  }
`;

const FormContainer = styled.div`
  width: 60%;
  display: flex;
  flex-direction: column;
  gap: 20px;
  @media (max-width: ${MOBILE_WIDTH}px) {
    width: 100%;
    padding: 0px;
  }
`;

const ButtonContainer = styled.div`
  width: 100%;
  margin-top: 30px;
`;

const LoginImage = styled.img`
  width: 348px;
  height: 60px;
`;

const Button = styled.button`
  width: 100%;
  padding: 10px;
  border: none;
  border-radius: 8px;
  cursor: pointer;
  color: #ffffff;
  background-color: ${colors.mainBlue};
  &:active {
    background-color: ${colors.mainDarkBlue};
  }
  @media (max-width: ${MOBILE_WIDTH}px) {
    width: 100%;
  }
`;

const Text = styled.h2`
  font-size: 20px;
  font-weight: 500;
  line-height: 23px;
`;

export default Login;
