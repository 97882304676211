import { createSlice } from '@reduxjs/toolkit';
import { bidsService } from '../services/bids.service';

interface Bid {
  checkupBid: object | null;
  hairBid: object | null;
}

const initialBidState: Bid = {
  checkupBid: null,
  hairBid: null,
};

export const { actions: bidActions, reducer: bidReducer } = createSlice({
  name: 'bid',
  initialState: initialBidState,
  reducers: {
    updatecheckupBid: (state, { payload }) => {
      state.checkupBid = { ...state.checkupBid, ...payload };
    },
    createcheckupBid: (state, { payload }) => {
      state.checkupBid = { ...payload };
    },
    updatehairBid: (state, { payload }) => {
      state.hairBid = { ...state.hairBid, ...payload };
    },
    createhairBid: (state, { payload }) => {
      state.hairBid = { ...payload };
    },
  },
  extraReducers: (builder) => {
    builder.addMatcher(
      bidsService.endpoints.getBidByWorkspaceId.matchFulfilled,
      (state, action) => {
        state.checkupBid = action.payload.checkupBid;
        state.hairBid = action.payload.hairBid;
      }
    );
  },
});
