import { createSlice } from '@reduxjs/toolkit';
import { workspaceService } from '../services/workspace.service';

interface Workspace {
  data: any;
}

const initialWorkspaceState: Workspace = {
  data: null,
};

export const { actions: workspaceActions, reducer: workspaceReducer } =
  createSlice({
    name: 'workspace',
    initialState: initialWorkspaceState,
    reducers: {
      // setWorkspaceInfo: (state, { payload }) => {
      //   state.data = payload;
      // },
    },
    extraReducers: (builder) => {
      builder.addMatcher(
        workspaceService.endpoints.getWorkspace.matchFulfilled,
        (state, action) => {
          state.data = action.payload?.data;
        }
      );
    },
  });
