import { ChangeEvent, useEffect, useState } from 'react';
import styled from 'styled-components';
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import {
  useActivateToggleForBidMutation,
  useCreateBidMutation,
  useUpdateBidMutation,
} from '../../app/services/bids.service';
import { toast } from 'react-toastify';
import {
  useGetTransactionsLimitsMutation,
  useGetTransactionsMutation,
} from '../../app/services/transaction.service';
import Info from '../../components/bids/info/Info';
import Form from '../../components/bids/form/Form';
import Transition from '../../components/bids/transaction/Transaction';
import { bidActions } from '../../app/slices/bid.slice';
import { parseCurrencyStringToNumber } from '../../utils/helperFunctions';
import { MEDIUM_WIDTH } from '../../utils/globals';
import { useGetWorkspaceMutation } from '../../app/services/workspace.service';
import { useGetCreditsMutation } from '../../app/services/credit.service';
import {
  CREDIT_NOT_ENOUGH_ERROR,
  INPUT_ERROR,
  MIN_MAX_PRICES_ERROR,
} from '../../utils/constant';

const Bids = () => {
  const myBid = useAppSelector((state) => state.bid.checkupBid);
  const dispatch = useAppDispatch();
  const [phoneNumberCount, setPhoneNumberCount] = useState<number>(
    myBid?.phoneNumberCount || 0
  );

  const [creditsData, setCreditsData] = useState([]);
  const [limitsData, setLimitsData] = useState({});
  const [transactionsData, setTransactionsData] = useState([]);
  const [dropdownValue, setDropdownValue] = useState('');
  const [pricePerNumber, setPricePerNumber] = useState<number>(
    myBid?.pricePerNumber || 11
  );

  const [selectedOptions, setSelectedOptions] = useState<Array<string | null>>(
    myBid?.selectedDistricts || []
  );

  const workspace = useAppSelector((store) => store.workspace.data);

  const [getCredits] = useGetCreditsMutation();
  const [getWorkspace] = useGetWorkspaceMutation();
  const [getTransactions] = useGetTransactionsMutation();
  const [getTransactionsLimits] = useGetTransactionsLimitsMutation();

  const [createBid] = useCreateBidMutation();
  const [updateBid] = useUpdateBidMutation();
  const [activateToggle] = useActivateToggleForBidMutation();

  useEffect(() => {
    (async () => {
      await getWorkspace({});
      const { data: creditData }: any = await getCredits({ type: 'checkup' });
      if (creditData.success) {
        setCreditsData(creditData?.data);
      }
      const { data: transactionData }: any = await getTransactions({
        workspaceId: myBid?.workspace,
        type: 'checkup',
      });
      if (transactionData) {
        setTransactionsData(transactionData?.data);
      }
      const { data: limitsData }: any = await getTransactionsLimits({
        type: 'checkup',
      });
      if (limitsData) {
        setLimitsData(limitsData?.data);
      }
    })();
  }, []);

  const handleDropdownChange = (event: any) => {
    const value = event.target.value;
    if (value === 'TÜMÜ') {
      setSelectedOptions([value]);
    } else {
      if (selectedOptions.includes('TÜMÜ')) {
        setSelectedOptions([value]);
      } else {
        setSelectedOptions((prevSelectedOptions) => {
          if (!prevSelectedOptions.includes(value)) {
            return [...prevSelectedOptions, value];
          }
          return prevSelectedOptions;
        });
      }
    }
    setDropdownValue('');
  };

  const handleRemoveOption = (value: any) => {
    setSelectedOptions(selectedOptions.filter((option) => option !== value));
  };

  const onSaveHandler = async (e: any) => {
    e.preventDefault();

    if (
      phoneNumberCount &&
      pricePerNumber &&
      workspace._id &&
      selectedOptions.length > 0
    ) {
      if (phoneNumberCount * pricePerNumber > workspace.budget) {
        return toast.error(CREDIT_NOT_ENOUGH_ERROR);
      }

      if (phoneNumberCount > 10 || pricePerNumber < 50) {
        return toast.error(MIN_MAX_PRICES_ERROR);
      }

      const { data, error }: any = await createBid({
        phoneNumberCount,
        pricePerNumber,
        workspace: workspace._id,
        selectedDistricts: selectedOptions,
        isActive: true,
        type: 'checkup',
      });
      if (error) {
        toast.info(error?.data?.message);
      }
      if (data) {
        dispatch(
          bidActions.createcheckupBid({
            phoneNumberCount,
            pricePerNumber,
            selectedDistricts: selectedOptions,
            isActive: true,
          })
        );
        toast.success(data?.message);
      }
    } else {
      toast.error(INPUT_ERROR);
    }
  };

  const onUpdateHandle = async (e: any) => {
    e.preventDefault();

    if (
      phoneNumberCount &&
      pricePerNumber &&
      workspace._id &&
      selectedOptions.length > 0
    ) {
      if (phoneNumberCount * pricePerNumber > workspace.budget) {
        return toast.error(CREDIT_NOT_ENOUGH_ERROR);
      }

      if (phoneNumberCount > 10 || pricePerNumber < 50) {
        return toast.error(MIN_MAX_PRICES_ERROR);
      }
      const { data, error }: any = await updateBid({
        phoneNumberCount,
        pricePerNumber,
        workspace: workspace._id,
        selectedDistricts: selectedOptions,
        type: 'checkup',
      });
      if (error) {
        toast.info(error?.data?.message);
      }
      if (data) {
        dispatch(
          bidActions.updatecheckupBid({
            phoneNumberCount,
            pricePerNumber,
            selectedDistricts: selectedOptions,
          })
        );
        toast.success(data?.message);
      }
    } else {
      toast.error(INPUT_ERROR);
    }
  };

  const onActivateToggle = async (e: any, isActive: boolean) => {
    e.preventDefault();
    const { data, error }: any = await activateToggle({
      isActive: !isActive,
      workspace: workspace._id,
      type: 'checkup',
    });
    if (data?.success) {
      toast.success(data?.message);
      dispatch(
        bidActions.updatecheckupBid({
          isActive: !isActive,
        })
      );
    }
    if (error?.data) {
      toast.error(error?.data?.message);
    }
  };

  const onPhoneNumberCountHandler = (e: ChangeEvent<any>) => {
    const value = e.target.value;
    value > 0 ? setPhoneNumberCount(Number(value)) : setPhoneNumberCount(0);
  };

  const onPhonePriceHandler = (e: ChangeEvent<any>) => {
    const value = parseCurrencyStringToNumber(e.target.value);
    value > 0 ? setPricePerNumber(value) : setPricePerNumber(0);
  };

  return (
    <Container>
      <FormInfoWrapper>
        <Form
          phoneNumberCount={phoneNumberCount}
          onPhoneNumberCount={onPhoneNumberCountHandler}
          pricePerNumber={pricePerNumber}
          onPhonePrice={onPhonePriceHandler}
          dropdownValue={dropdownValue}
          handleDropdownChange={handleDropdownChange}
          selectedOptions={selectedOptions}
          handleRemoveOption={(option: string) => handleRemoveOption(option)}
          onSaveHandler={onSaveHandler}
          onUpdateHandle={onUpdateHandle}
          onActivateToggle={onActivateToggle}
          isActiveBid={myBid?.isActive || false}
          myBid={myBid}
        />
        <Info
          workspace={workspace}
          priceLimit={limitsData || {}}
          credit={creditsData || []}
        />
      </FormInfoWrapper>
      <Transition transactions={transactionsData} />
    </Container>
  );
};

const Container = styled.div`
  padding: 150px 10% 70px 10%;
  gap: 80px;
  display: flex;
  flex-direction: column;
`;

const FormInfoWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 50px;
  width: 100%;
  @media (max-width: ${MEDIUM_WIDTH}px) {
    flex-direction: column;
  }
`;

export default Bids;
