import React, { useRef, useEffect, useState } from 'react';
import dateFormat from 'dateformat';
import colors from '../../utils/colors';
import styled from 'styled-components';
import {
  useReSendCodeMutation,
  useSendCodeMutation,
} from '../../app/services/auth.service';
import { useAppDispatch } from '../../app/hooks';
import { authActions } from '../../app/slices/auth.slice';
import { toast } from 'react-toastify';

const OtpInput: React.FC<{
  numberOfDigits: number;
  email: string;
  phone: string;
}> = ({ numberOfDigits, email, phone }) => {
  const [timer, setTimer] = useState<number>(120);
  const [otp, setOtp] = useState<string[]>(new Array(numberOfDigits).fill(''));
  const otpBoxReference = useRef<(HTMLInputElement | null)[]>(
    new Array(numberOfDigits).fill(null)
  );
  const [sendCode] = useSendCodeMutation();
  const [resendCode] = useReSendCodeMutation();
  const dispatch = useAppDispatch();

  const handleOnChange = (value: string, index: number) => {
    let newArr = [...otp];
    newArr[index] = value.toUpperCase();
    setOtp(newArr);
    if (
      value &&
      index < numberOfDigits - 1 &&
      otpBoxReference.current[index + 1]
    ) {
      otpBoxReference.current[index + 1]?.focus();
    }
  };

  const handlePaste = (event: React.ClipboardEvent<HTMLInputElement>) => {
    const pastedText = event.clipboardData.getData('text');
    if (pastedText.length >= 1) {
      const newOtp = pastedText
        .slice(0, numberOfDigits)
        .split('')
        .map((digit) => digit.toUpperCase());
      if (otpBoxReference.current) {
        otpBoxReference.current.forEach((inputBox, index) => {
          if (index < newOtp.length && inputBox) {
            inputBox.value = newOtp[index];
          }
        });
      }
      if (otpBoxReference.current && otpBoxReference.current[newOtp.length]) {
        otpBoxReference.current[newOtp.length]?.focus();
      }
      event.preventDefault();
    }
  };

  const handleOnKeyDown = (
    event: React.KeyboardEvent<HTMLInputElement>,
    index: number
  ) => {
    if (event.key === 'Backspace') {
      if (otp[index] !== '') {
        const newOtp = [...otp];
        newOtp[index] = '';
        setOtp(newOtp);
      } else if (index > 0) {
        otpBoxReference.current[index - 1]?.focus();
      }
    }
  };

  const handleResendCode = async () => {
    setTimer(120);
    const { data }: any = await resendCode({ email, phone });

    if(data?.success === true){
      toast.success(data?.message)
    }
  };

  const handleSendCode = async () => {
    const { data }: any = await sendCode({ code: otp.join(''), email });

    if(data?.success === false){
      toast.error(data?.message)
    }

    setOtp(new Array(numberOfDigits).fill(''));
  };

  useEffect(() => {
    let interval: NodeJS.Timeout;
    if (timer > 0) {
      interval = setInterval(() => {
        setTimer((prevTimer) => prevTimer - 1);
      }, 1000);
    }
    return () => clearInterval(interval);
  }, [timer]);

  return (
    <Container>
      <Header>
        <div>Telefonunuza gelen kodu giriniz</div>
        <div>
          {timer === 0 && (
            <ResendButton onClick={handleResendCode}>
              Kodu Tekrar Gönder
            </ResendButton>
          )}
          {timer > 0 && (
            <Timer>{dateFormat(new Date(1000 * timer), 'MM:ss')}</Timer>
          )}
        </div>
      </Header>
      <InputContainer>
        {otp.map((digit, index) => (
          <InputWrapper key={index}>
            <InputField
              digit={!!digit}
              maxLength={1}
              value={digit}
              onChange={(e) => handleOnChange(e.target.value, index)}
              onKeyDown={(e) => handleOnKeyDown(e, index)}
              onPaste={handlePaste}
              ref={(ref) => (otpBoxReference.current[index] = ref)}
            />
            {index < 5 && <Divider>-</Divider>}
          </InputWrapper>
        ))}
      </InputContainer>
      <ButtonWrapper>
        <div>
          <SendButton onClick={() => dispatch(authActions.resetLoginData())}>
            Geri
          </SendButton>
        </div>
        <div>
          <SendButton onClick={handleSendCode}>Gönder</SendButton>
        </div>
      </ButtonWrapper>
    </Container>
  );
};

interface InputProps {
  digit: boolean;
}

const Container = styled.div`
  background-color: white;
  width: max-content;
  padding-bottom: 150px;
`;

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
`;

const ResendButton = styled.button`
  border-radius: 10px;
  cursor: pointer;
`;

const Timer = styled.p`
  font-size: 14px;
`;

const InputContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 10px;
  width: max-content;
`;

const InputWrapper = styled.div`
  display: flex;
  gap: 10px;
`;

const InputField = styled.input<InputProps>`
  background-color: ${({ digit }) =>
    digit ? colors.mainLightBlueHover : colors.mainLightBlue};
  border: 1px solid black;
  height: 50px;
  width: 50px;
  text-align: center;
  font-size: 25px;
  color: ${colors.mainBlue};
`;

const Divider = styled.div`
  font-size: 25px;
  display: flex;
  align-items: center;
`;

const ButtonWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 20px;
`;

const SendButton = styled.button`
  background-color: ${colors.mainBlue};
  color: white;
  padding: 10px 25px;
  border-radius: 10px;
  cursor: pointer;
`;

export default OtpInput;
