// pages/bids/Bids.tsx
export const MIN_MAX_PRICES_ERROR =
  'Telefon fiyatı minimum 50TL ve günlük telefon adedi maksimum 10 adet olarak seçilebilir.';
export const CREDIT_NOT_ENOUGH_ERROR = 'Krediniz yeterli değildir.';
export const INPUT_ERROR = 'Check your inputs values.';

// components/bids/info/Info.tsx
export const TOTAL_CREDIT = 'Toplam Harcanabilir Kredi';
export const YESTERDAY_MAX_OFFER = 'Dün Son Teklif (Maksimum)';
export const YESTERDAY_MIN_OFFER = 'Dün Son Teklif (Minimum)';

// components/bids/transaction/Transaction.tsx
// Burada ilk başta columns diye bir kısım var bu kısmı da alabiliriz not olarak buraya yazdım.
/*
export const transaction_columns_titles = [
    { key: 'fullname', label: 'Checkup Talep Eden Kişi' },
    { key: 'phoneNumber', label: 'Telefon Numarası' },
    { key: 'createdAt', label: 'İşlem Tarihi' },
    { key: 'soldPrice', label: 'Gerçekleşme Fiyatı' },
  ];
*/

// components/detail-sidebar/DetailSidebar.tsx
export const MULTIPLE_HOSPITAL_OFFERS =
  'Kişi birçok hastaneden teklif almak istiyor.';
export const SINGLE_HOSPITAL_OFFER =
  'Kişi sadece sizin hastanenizden teklif almak istiyor.';
export const END_DATE = 'İşlem Bitiş Tarihi';
export const START_DATE = 'İşlem Başlangıç Tarihi';
export const ADD_NOTE = 'Buraya not ekleyebilirsiniz...';

// components/table/Table.tsx
//Aynı şekilde burada da bir tablo var ve tablonun ilk satırındaki başlıklar için burada bir değer oluşturabiliriz
/*
export const table_columns_titles = [
  { key: 'fullname', label: 'Ad-Soyad', sortable: true },
  { key: 'createdAt', label: 'Tarih', sortable: true },
  { key: 'phoneNumber', label: 'Telefon', sortable: false },
  { key: 'paketAdi', label: 'Paket', sortable: false },
  { key: 'status', label: 'Durum', sortable: true },
];
*/

// pages/landing/Landing.tsx

export const CARD_TEXT_1 =
  'Check-up yaptırmak üzere araştırma yapmış, sistemimize iletişim bilgilerini bırakmış potansiyel müşterilerinize anında ulaşın.';
export const CARD_TITLE_1 = 'Potansiyel müşterilere hızlıca ulaşma imkanı';
export const CARD_TEXT_2 =
  'Kurumunuza özel yönetim paneli ile müşterilerle anında iletişim kurun, hem kendi bölgeniz, hem de çevre ilçelerdeki müşterilere erişim sağlayın.';
export const CARD_TITLE_2 = 'Yönetim Paneli ile anlık müşteri takibi';
export const CARD_TEXT_3 =
  'Kuruma özel yapay zeka destekli Whatsapp ile check-up ve diğer sağlık hizmetleri konusunda olası müşterileri 7/24 bilgilendirme şansı edinin.';
export const CARD_TITLE_3 = 'Yapay zeka destekli Whatsapp iletişimi';
export const CARD_TEXT_4 =
  'Kurumunuz ve check-up paketleriniz Hangicheckup.com’da üst sıralarda yerini alsın, görünürlüğünüzü artırarak daha fazla müşteriye ulaşın.';
export const CARD_TITLE_4 = 'Sponsorlu olarak öne çıkma fırsatı';

export const NUMBERED_CARD_TEXT_1 =
  'HangiCheckup’ta paketleri ve kurumları inceleyen müşteri, sisteme telefon numarasını bırakır.';
export const NUMBERED_CARD_TEXT_2 =
  'Müşterinin iletişim bilgileri Hangicheckup sisteminde iletişime geçilmek üzere yer alır.';
export const NUMBERED_CARD_TEXT_3 =
  'Hangicheckup çağrı merkezi, potansiyel müşteri ile iletişime geçer ve anlaşmalı kurumların paketlerini sunar.';
export const NUMBERED_CARD_TEXT_4 =
  'Müşterinin onay verdiği checkup paketi ve iletişim bilgiler anlaşmalı kuruma teslim edilen panelde yer alır.';

export const BANNER_TITLE = 'HangiCheckup.com’da yerinizi siz de alın!';
export const BANNER_DESC =
  'Hangicheckup.com, check-up yaptırmayı düşünen kullanıcılara,istedikleri sağlık kurumunda ihtiyaçları olan sağlık paketlerini bulmave karşılaştırma konusunda kolaylık sağlar. Kurumunuzla siz deHangiCheckup.com’a kaydolun, potansiyel müşterilerinize kolaycaulaşın.';
export const WHY_ME = 'Neden HangiCheckup?';
export const HOW_IT_WORKS = 'Nasıl Çalışır?';
