import styled from 'styled-components';
import Button from './Button';
import Dropdown from './Dropdown';
import Input from './Input';
import { MEDIUM_WIDTH, option } from '../../../utils/globals';

const Form = ({
  phoneNumberCount,
  onPhoneNumberCount,
  pricePerNumber,
  onPhonePrice,
  dropdownValue,
  handleDropdownChange,
  selectedOptions,
  handleRemoveOption,
  onSaveHandler,
  onUpdateHandle,
  isActiveBid,
  onActivateToggle,
  myBid,
}: any) => {
  return (
    <Container>
      <Header>Teklif Ver</Header>
      <FormWrapper>
        <Input
          label={`Telefon Numara Adedi`}
          name='number-count'
          placeHolder={'Write price count'}
          value={phoneNumberCount}
          onChangeHandler={onPhoneNumberCount}
        />
        <Input
          label={`Telefon Numarası Başı Fiyat`}
          name='number-price'
          placeHolder={'Write max price'}
          value={pricePerNumber?.toLocaleString('tr-TR', option)}
          onChangeHandler={onPhonePrice}
        />
        <Dropdown
          dropdownValue={dropdownValue}
          handleDropdownChange={handleDropdownChange}
          selectedOptions={selectedOptions}
          handleRemoveOption={(option: string) => handleRemoveOption(option)}
        />
        <div style={{ display: 'flex', gap: '10px' }}>
          <Button text='Kaydet' onClickHandler={onSaveHandler} type='save' />
          <Button
            text='Güncelle'
            onClickHandler={onUpdateHandle}
            type='update'
          />
          {myBid && (
            <Button
              text={isActiveBid ? 'Deaktif Et' : 'Aktif Et'}
              onClickHandler={(e: any) => onActivateToggle(e, isActiveBid)}
              type={isActiveBid ? 'deaktif' : 'aktif'}
            />
          )}
        </div>
      </FormWrapper>
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  /* width: 50%; */
  width: 35%;
  gap: 20px;
  /* padding: 10px; */
  /*flex: 1*/
  @media (max-width: ${MEDIUM_WIDTH}px) {
    width: 100%;
  }
`;

const Header = styled.div`
  font-weight: 600;
  font-size: 32px;
  line-height: 39px;
`;

const FormWrapper = styled.form`
  /* width: 75%; */
  display: flex;
  flex-direction: column;
  gap: 15px;
`;
export default Form;
