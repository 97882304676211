import styled from 'styled-components';
import { useAppSelector } from '../../app/hooks';
import { useEffect, useState } from 'react';
import { useGetCustomersMutation } from '../../app/services/callcenter.service';
import CallcenterTable from '../../components/table/callcenter/CallcenterTable';
import Loading from '../../components/loading/Loading';

const AppointedCustomersByWorkspace = () => {
  const [customers, setCustomers] = useState([]);

  const { callcenterGetFeature } = useAppSelector(
    (state) => state.workspace.data
  );

  const [getCustomers, { isLoading }] = useGetCustomersMutation();

  useEffect(() => {
    getCustomersHandler();
  }, []);

  const getCustomersHandler = async () => {
    const { data }: any = await getCustomers({});
    setCustomers(data?.data);
  };
  return (
    callcenterGetFeature && (
      <CallCenterGetFeatureWrapper>
        <Customers>
          {customers.length > 0 && <CallcenterTable data={customers} />}
          {customers.length === 0 && (
            <div style={{ textAlign: 'center' }}>
              Herhangi bir veri bulunamadı
            </div>
          )}
          {isLoading && <Loading width='50px' boldness='6px' />}
        </Customers>
      </CallCenterGetFeatureWrapper>
    )
  );
};

const Customers = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  /* display: grid;
  grid-template-columns: repeat(2, 1fr); */
  gap: 10px;
  /* height: 600px;
  overflow-y: auto; */
`;

const NoCustomersMessage = styled.div`
  border: 1px solid black;
  border-radius: 8px;
  padding: 10px;
  text-align: center;
`;

const LeftFeature = styled.div`
  width: 100%;
`;
// const RightFeature = styled.div`
//   width: 50%;
// `;
const FeatureTitle = styled.h2`
  margin-bottom: 10px;
`;
const AppointedCustomers = styled.div`
  width: 100%;
  height: 100%;
  overflow-y: auto;
`;

const CallCenterGetFeatureWrapper = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  gap: 15px;
  padding-top: 40px;
`;

export default AppointedCustomersByWorkspace;
