import { useState } from 'react';
import { ErrorMessages, InputType } from '../../../utils/types';
import InputTwo from '../../common/InputTwo';
import Button from './Button';
import {
  validateEmail,
  validatePhoneNumber,
  validateText,
} from '../../../utils/formInputValidations';
import { useCreateApplicationMutation } from '../../../app/services/bids.service';
import { toast } from 'react-toastify';
import ModalCloseButton from '../../common/ModalCloseButton';

const initialState = { isValid: false, value: '' };

const ApplicationForm = ({ handleCloseModal }: any) => {
  const [isFormSubmit, setIsFormSubmit] = useState(false);
  const [companyName, setCompanyName] = useState(initialState);
  const [city, setCity] = useState(initialState);
  const [district, setDistrict] = useState(initialState);
  const [phone, setPhone] = useState(initialState);
  const [email, setEmail] = useState(initialState);
  const [authorityName, setAuthorityName] = useState(initialState);
  const [authorityTitle, setAuthorityTitle] = useState(initialState);

  const [createApplication] = useCreateApplicationMutation();

  const onChangeName = (event: any) => {
    const value = event.target.value;
    if (!validateText(value)) {
      return setCompanyName({ value, isValid: false });
    }
    setCompanyName({ value, isValid: true });
  };

  const onChangeCity = (event: any) => {
    const value = event.target.value;
    if (!validateText(value)) {
      return setCity({ value, isValid: false });
    }
    setCity({ value, isValid: true });
  };

  const onChangeDistrict = (event: any) => {
    const value = event.target.value;
    if (!validateText(value)) {
      return setDistrict({ value, isValid: false });
    }
    setDistrict({ value, isValid: true });
  };

  const onChangePhone = (event: any) => {
    const value = event.target.value;
    if (!validatePhoneNumber(value)) {
      return setPhone({ value, isValid: false });
    }
    setPhone({ value, isValid: true });
  };

  const onChangeEmail = (event: any) => {
    const value = event.target.value;
    if (!validateEmail(value)) {
      return setEmail({ value, isValid: false });
    }
    setEmail({ value, isValid: true });
  };

  const onChangeAuthorityName = (event: any) => {
    const value = event.target.value;
    if (!validateText(value)) {
      return setAuthorityName({ value, isValid: false });
    }
    setAuthorityName({ value, isValid: true });
  };

  const onChangeAuthorityTitle = (event: any) => {
    const value = event.target.value;
    if (!validateText(value)) {
      return setAuthorityTitle({ value, isValid: false });
    }
    setAuthorityTitle({ value, isValid: true });
  };

  const onSubmit = async (e: Event) => {
    e.preventDefault();
    setIsFormSubmit(true);
    if (
      companyName.isValid &&
      phone.isValid &&
      email.isValid &&
      city.isValid &&
      district.isValid &&
      authorityName.isValid &&
      authorityTitle.isValid
    ) {
      const { data }: any = await createApplication({
        companyName: companyName.value,
        phone: phone.value,
        email: email.value,
        city: city.value,
        district: district.value,
        authorityName: authorityName.value,
        authorityTitle: authorityTitle.value,
      });
      if (data?.doc) {
        handleCloseModal();
        toast.success('Başvuru formunuz başarılı bir şekilde gönderilmiştir.');
      }
    }
  };

  return (
    <form style={{ display: 'flex', flexWrap: 'wrap' }}>
      <ModalCloseButton onClick={handleCloseModal}></ModalCloseButton>
      <InputTwo
        type={InputType.Text}
        onChange={onChangeName}
        placeholder='Kurum Adı'
        value={companyName.value}
        isErrorText={
          isFormSubmit && !companyName.isValid ? ErrorMessages.INVALID_TEXT : ''
        }
      />
      <InputTwo
        type={InputType.Text}
        onChange={onChangeCity}
        placeholder='İl'
        value={city.value}
        isErrorText={
          isFormSubmit && !city.isValid ? ErrorMessages.INVALID_TEXT : ''
        }
      />
      <InputTwo
        type={InputType.Text}
        onChange={onChangeDistrict}
        placeholder='İlçe'
        value={district.value}
        isErrorText={
          isFormSubmit && !district.isValid ? ErrorMessages.INVALID_TEXT : ''
        }
      />
      <InputTwo
        type={InputType.Text}
        onChange={onChangePhone}
        placeholder='Telefon Numarası'
        value={phone.value}
        isErrorText={
          isFormSubmit && !phone.isValid ? ErrorMessages.PHONE_NUMBER : ''
        }
      />
      <InputTwo
        type={InputType.Email}
        onChange={onChangeEmail}
        placeholder='Email'
        value={email.value}
        isErrorText={isFormSubmit && !email.isValid ? ErrorMessages.EMAIL : ''}
      />
      <InputTwo
        type={InputType.Text}
        onChange={onChangeAuthorityName}
        placeholder='Yetkili İsim Soyisim'
        value={authorityName.value}
        isErrorText={
          isFormSubmit && !authorityName.isValid
            ? ErrorMessages.INVALID_TEXT
            : ''
        }
      />
      <InputTwo
        type={InputType.Text}
        onChange={onChangeAuthorityTitle}
        placeholder='Yetkili Görevi'
        value={authorityTitle.value}
        isErrorText={
          isFormSubmit && !authorityTitle.isValid
            ? ErrorMessages.INVALID_TEXT
            : ''
        }
      />
      <div style={{ marginTop: '10px' }}>
        <Button text='Kaydet' onClickHandler={onSubmit} type='save' />
      </div>
    </form>
  );
};

export default ApplicationForm;
