export function validateCompanyId(fullname: string) {
  const nameRegex =
    /^(?=.*[A-Za-zğüşıöçĞÜŞİÖÇ])(?=.*\d)[A-Za-zğüşıöçĞÜŞİÖÇ\d]{10,}$/;
  return nameRegex.test(fullname);
}

export function validateEmail(email: string) {
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  return emailRegex.test(email);
}

export function validatePassword(password: string) {
  const passwordRegex = /^.{6,}$/;
  return passwordRegex.test(password);
}

export function validatePhoneNumber(phoneNumber: string) {
  const phoneNumberRegex =
    /^(0|\+90)?([\s(])?5([0-9]{2})([)]?)?([\s-])?([0-9]{3})([\s-])?([0-9]{2})([\s-])?([0-9]{2})$/;
  return phoneNumberRegex.test(phoneNumber);
}

export function validateText(fullname: string) {
  const nameRegex = /^[a-zA-Z]{3,}$/;
  return nameRegex.test(fullname);
}

