import { Navigate, Outlet } from 'react-router-dom';
import { useAppSelector } from '../app/hooks';
import Navbar from '../components/navbar/Navbar';
import {
  useGetWorkspaceMutation,
  useSubscribeNotificationMutation,
} from '../app/services/workspace.service';
import { useGetBidByWorkspaceIdMutation } from '../app/services/bids.service';
import { useEffect } from 'react';
import { publicVapidKey } from '../utils/globals';

const options = {
  userVisibleOnly: true,
  applicationServerKey: publicVapidKey,
};

const registerServiceWorker = async (subscribeNotification: any) => {
  if ('serviceWorker' in navigator) {
    try {
      navigator.serviceWorker.addEventListener('message', (event) => {
        if (event.data.action === 'playSound') {
          console.log('playSound action has come');
          const audio = new Audio('/assets/hangicheckup.mp3');
          audio?.play();
        } else if ((event.data.action = 'reloadPage')) {
          window.location.reload();
        }
      });
      await navigator.serviceWorker.register('/sw.js', {
        scope: '/',
      });
      const registration = await navigator.serviceWorker.ready;
      if (registration.active) {
        const result = await Notification.requestPermission();
        if (result === 'granted') {
          const existingSubscription =
            await registration.pushManager.getSubscription();
          if (!existingSubscription) {
            // Abonelik yoksa yeni bir abonelik al
            const newSubscription = await registration.pushManager.subscribe(
              options
            );
            console.log('New subscription:', newSubscription);
            // Abonelik bilgisini backend'e gönder
            await subscribeNotification(newSubscription);
          }
        }
      }
    } catch (error) {
      console.error('Service worker registration failed:', error);
    }
  } else {
    console.error('Service workers are not supported.');
  }
};

const ProtectedLayout = () => {
  const workspace = useAppSelector((store) => store.workspace.data);
  const token = useAppSelector((store) => store.auth.token);
  const [subscribeNotification] = useSubscribeNotificationMutation();

  const whatsappFeature = workspace?.whatsappFeature;
  const bidsFeature = workspace?.bidsFeature;
  const workspaceId = workspace?._id;
  const callcenterGetFeature = workspace?.callcenterGetFeature;
  const callcenterPostFeature = workspace?.callcenterPostFeature;

  const [getWorkspace] = useGetWorkspaceMutation();
  const [getBidByWorkspaceId] = useGetBidByWorkspaceIdMutation();

  useEffect(() => {
    if (workspaceId) {
      fetchBidByWorkspaceId(workspaceId);
    }
    if (token) {
      fetchWorkspace();
    }
  }, [workspaceId]);

  useEffect(() => {
    if (token) {
      registerServiceWorker(subscribeNotification);
    }
  }, [token]);

  const fetchBidByWorkspaceId = async (workspaceId: string) => {
    try {
      await getBidByWorkspaceId({ workspaceId });
    } catch (error) {
      console.log(error);
    }
  };

  const fetchWorkspace = async () => {
    try {
      await getWorkspace({});
    } catch (error) {
      console.log(error);
    }
  };

  if (!token) return <Navigate to='/landing' />;
  return (
    <>
      <Navbar
        whatsappFeature={whatsappFeature}
        bidsFeature={bidsFeature}
        callcenterGetFeature={callcenterGetFeature}
        callcenterPostFeature={callcenterPostFeature}
      />
      <Outlet />
    </>
  );
};

export default ProtectedLayout;
