import api from '../api';

export const creditService = api.injectEndpoints({
  endpoints: (build) => {
    return {
      getCredits: build.mutation({
        query: (args) => ({
          url: `credit?${new URLSearchParams(args)}`,
        }),
        invalidatesTags: ['CREDIT'],
      }),
    };
  },
});

export const { useGetCreditsMutation } = creditService;
