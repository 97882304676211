import { useEffect, useState } from 'react';
// import DetailSidebar from '../../components/detail-sidebar/DetailSidebar';
import SampleTable from '../../components/table/Table';
// import styled from 'styled-components';
import HairTransplantTable from '../../components/table/HairTransplantTable';
import { useAppSelector } from '../../app/hooks';
import CustomTab from '../../components/customTab/CustomTab';
import DropdownMenu from '../../components/changeSiteDropdown/DropdownMenu';
import AppointedCustomersAll from '../callcenter/AppointedCustomersAll';
import AppointedCustomersByWorkspace from '../callcenter/AppointedCustomersByWorkspace';

const baseTabsOptions = [{ title: 'Başvuru bilgileri', tabIndex: 0 }];

const callcenterTabsOptions = [{ title: 'Gerçekleşen İşlemler', tabIndex: 1 }];

const hospitalTabsOptions = [{ title: 'Atanan İşlemler', tabIndex: 2 }];

const dropdownOptions = [
  {
    id: 0,
    title: 'HangiCheckUp',
    imageSrc: '/assets/icons/hangiCheckUpLogo.png',
  },
  {
    id: 1,
    title: 'HangiSacEkimi',
    imageSrc: '/assets/icons/hangiSacEkimiLogo.png',
  },
];

export const Offers = () => {
  const [options, setOptions] = useState<any>([]);
  const [tabs, setTabs] = useState<any>([]);
  const [selectedOption, setSelectedOption] = useState<any>(null);
  const [activeTab, setActiveTab] = useState<number>(0);

  const workspace = useAppSelector((store) => store.workspace.data);
  //For dropdown
  const isCheckupFeatureAvailable = workspace?.bidsFeature;
  const isHairBidFeatureAvailable = workspace?.hairBidsFeature;

  //For tabs
  const callcenterFeatureAvailable = workspace?.callcenterPostFeature;
  const hospitalFeatureAvailable = workspace?.callcenterGetFeature;

  useEffect(() => {
    // Dropdown options
    const availableOptions = [];
    if (isCheckupFeatureAvailable) availableOptions.push(dropdownOptions[0]);
    if (isHairBidFeatureAvailable) availableOptions.push(dropdownOptions[1]);
    setOptions(availableOptions);
    setSelectedOption(availableOptions[0] || null);

    // Tabs options
    let updatedTabs = [...baseTabsOptions];
    if (!hospitalFeatureAvailable && !callcenterFeatureAvailable) {
      updatedTabs = [...updatedTabs];
    }
    if (callcenterFeatureAvailable) {
      updatedTabs = [...updatedTabs, ...callcenterTabsOptions];
    }
    if (hospitalFeatureAvailable) {
      updatedTabs = [...hospitalTabsOptions];
    }

    setTabs(updatedTabs);
    setActiveTab(
      updatedTabs.find((tab) => tab.tabIndex === activeTab)
        ? activeTab
        : updatedTabs[0]?.tabIndex || 0
    );
  }, [
    isCheckupFeatureAvailable,
    isHairBidFeatureAvailable,
    hospitalFeatureAvailable,
  ]);

  const onChangeSelectedOption = (option: any) => {
    setSelectedOption(option);
    setActiveTab(tabs[0]?.tabIndex);
  };

  const handleTabClick = (index: any) => {
    setActiveTab(index);
  };

  return (
    <div style={{ display: 'flex', padding: '0px 5%', gap: '30px' }}>
      <div style={{ padding: '150px 0px 50px 0px', width: '100%' }}>
        {selectedOption && (
          <DropdownMenu
            onChangeSelectedOption={onChangeSelectedOption}
            options={options}
            selectedOption={selectedOption}
          />
        )}
        <CustomTab
          tabs={tabs}
          handleTabClick={handleTabClick}
          activeTab={activeTab}
        />
        {activeTab === 0 && selectedOption?.id === 0 && (
          <>
            <SampleTable />
          </>
        )}
        {activeTab === 1 && selectedOption?.id === 0 && (
          <>
            <AppointedCustomersAll />
          </>
        )}
        {activeTab === 0 && selectedOption?.id === 1 && (
          <>
            <HairTransplantTable />
          </>
        )}
        {activeTab === 1 && selectedOption?.id === 1 && (
          <>
            {/* Change this for hair transplant*/}
            <AppointedCustomersAll />
          </>
        )}
        {activeTab === 2 && selectedOption?.id === 0 && (
          <>
            <AppointedCustomersByWorkspace />
          </>
        )}
        {activeTab === 2 && selectedOption?.id === 1 && (
          <>
            <div>Hangi-Sacekimi AppointedCustomersByWorkspace</div>
          </>
        )}
      </div>
    </div>
  );
};

// const HeaderTitle = styled.h1`
//   font-size: 32px;
//   line-height: 39px;
//   @media (max-width: 768px) {
//     font-size: 24px;
//   }
// `;

// const HeaderContainer = styled.div`
//   display: flex;
//   justify-content: center;
//   background: #f7f7f7;
//   border-radius: 14px;
//   padding: 10px 20px;
// `;
