import { Navigate, Outlet } from 'react-router-dom';
import { useAppSelector } from '../app/hooks';
import Navbar from '../components/navbar/Navbar';

const UnprotectedRoute = () => {
  const token = useAppSelector((store) => store.auth.token);
  if (token) return <Navigate to='/offers' />;
  return (
    <>
      <Navbar unprotected />
      <Outlet />
    </>
  );
};

export default UnprotectedRoute;
