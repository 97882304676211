export const MOBILE_WIDTH = 768;
export const MEDIUM_WIDTH = 1007;
export const privateVapidKey = 'zb5xrlmZ1FC7NsDjf0j3YaaSeiTTAnuXmSn1w6KJ_Fk';
export const publicVapidKey =
  'BBsnjzHV-w89ZMGdTEwRCbL9fggfw2xoYLMTvo1ZpMSyidqyAwlMv8mClTV4JakZ6BM_mXHv4ZnbhqToqqmMbm4';

export const option = {
  style: 'currency',
  currency: 'TRY',
};

export const DISTRICTS = [
  {
    value: 1000,
    text: 'TÜMÜ',
  },
  {
    value: 1103,
    text: 'ADALAR',
  },
  {
    value: 2048,
    text: 'ARNAVUTKÖY',
  },
  {
    value: 2049,
    text: 'ATAŞEHİR',
  },
  {
    value: 2003,
    text: 'AVCILAR',
  },
  {
    value: 2004,
    text: 'BAĞCILAR',
  },
  {
    value: 2005,
    text: 'BAHÇELİEVLER',
  },
  {
    value: 1166,
    text: 'BAKIRKÖY',
  },
  {
    value: 2050,
    text: 'BAŞAKŞEHİR',
  },
  {
    value: 1886,
    text: 'BAYRAMPAŞA',
  },
  {
    value: 1183,
    text: 'BEŞİKTAŞ',
  },
  {
    value: 1185,
    text: 'BEYKOZ',
  },
  {
    value: 2051,
    text: 'BEYLİKDÜZÜ',
  },
  {
    value: 1186,
    text: 'BEYOĞLU',
  },
  {
    value: 1782,
    text: 'BÜYÜKÇEKMECE',
  },
  {
    value: 1237,
    text: 'ÇATALCA',
  },
  {
    value: 2052,
    text: 'ÇEKMEKÖY',
  },
  {
    value: 2016,
    text: 'ESENLER',
  },
  {
    value: 2053,
    text: 'ESENYURT',
  },
  {
    value: 1325,
    text: 'EYÜPSULTAN',
  },
  {
    value: 1327,
    text: 'FATİH',
  },
  {
    value: 1336,
    text: 'GAZİOSMANPAŞA',
  },
  {
    value: 2010,
    text: 'GÜNGÖREN',
  },
  {
    value: 1421,
    text: 'KADIKÖY',
  },
  {
    value: 1810,
    text: 'KAĞITHANE',
  },
  {
    value: 1449,
    text: 'KARTAL',
  },
  {
    value: 1823,
    text: 'KÜÇÜKÇEKMECE',
  },
  {
    value: 2012,
    text: 'MALTEPE',
  },
  {
    value: 1835,
    text: 'PENDİK',
  },
  {
    value: 2054,
    text: 'SANCAKTEPE',
  },
  {
    value: 1604,
    text: 'SARIYER',
  },
  {
    value: 1659,
    text: 'ŞİLE',
  },
  {
    value: 1622,
    text: 'SİLİVRİ',
  },
  {
    value: 1663,
    text: 'ŞİŞLİ',
  },
  {
    value: 2014,
    text: 'SULTANBEYLİ',
  },
  {
    value: 2055,
    text: 'SULTANGAZİ',
  },
  {
    value: 2015,
    text: 'TUZLA',
  },
  {
    value: 1852,
    text: 'ÜMRANİYE',
  },
  {
    value: 1708,
    text: 'ÜSKÜDAR',
  },
  {
    value: 1739,
    text: 'ZEYTİNBURNU',
  },
];
