import { useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import Modal from '../../components/modal/Modal';
import styled from 'styled-components';
import Button from '../../components/bids/form/Button';
import { toast } from 'react-toastify';
import { useUnsubscribeMutation } from '../../app/services/marketing.service';

export const Subscription = () => {
  const [modalOpen] = useState(true);

  const [search] = useSearchParams();
  const navigate = useNavigate();
  const [unsubscribe] = useUnsubscribeMutation();
  const email = search.get('email');

  const onTerminateHandler = async () => {
    const emailId = search.get('id');
    if (emailId) {
      const { data }: any = await unsubscribe({ id: emailId });
      if (data?.success) {
        toast.success('Üyeliğiniz başarılı şekilde sonlandırıldı.');
        navigate('/landing', { replace: true });
      }
    } else {
      toast.error('Bir hata oluştu. Lütfen daha sonra tekrar deneyiniz');
      navigate('/landing', { replace: true });
    }
  };

  const onCancelHandler = async () => {
    navigate('/landing', { replace: true });
  };

  return (
    <Modal
      isOpen={modalOpen}
      title={'Abonelik Sonlandırma'}
      content={
        <div>
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              gap: '30px',
              fontSize: '20px',
            }}
          >
            <strong>Sayın Kullanıcı,</strong>
            <p>
              Eğer bu tür bilgilendirici mailleri almak istemediğinizden
              eminseniz, lütfen bize bildirin. Size daha uygun bir deneyim
              sunabilmek için isteklerinizi dikkate alacağız.
            </p>
            <p>
              Hangicheckup'tan <strong>{email}</strong> adresi için benzer
              mesajlar almayı bırakmak istiyor musunuz?
            </p>
            <p>Anlayışınız için teşekkür ederiz.</p>
            <div>
              <strong>Saygılarımızla</strong>
              <p>Hangicheckup Ekibi</p>
            </div>
          </div>
          <ButtonsContainer>
            <Button
              text='Sonlandır'
              onClickHandler={onTerminateHandler}
              type='save'
            />
            <Button
              text='İptal'
              onClickHandler={onCancelHandler}
              type='update'
            />
          </ButtonsContainer>
        </div>
      }
    />
  );
};

const ButtonsContainer = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-top: 35px;
  gap: 10px;
`;
