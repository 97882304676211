const colors = {
  mainBlue: '#2C4DC3',
  mainLightBlue: '#F3F7FF',
  mainLightBlueHover: '#DFE4F6',
  mainDarkBlue: '#1a2e75',
  mainRed: '#E7214A',
  lightGray: '#F8F7FA',
  gray40: '#DADEDE',
  gray60: '#ADB0B0',
  gray80: '#67696F',
  gray100: '#2C2F36',
  green: '#74BC6E',
  white: '#FFFFFF',
  listHover: '#EAEDF9CC',
  black: '#000000',
  transparent: 'transparent',
};

export default colors;
