import { createSlice } from '@reduxjs/toolkit';
import { authService } from '../services/auth.service';

interface Auth {
  token: string | null;
  loginData: { [key: string]: string };
}

const initialAuthState: Auth = {
  token: null,
  loginData: {
    email: '',
    phone: '',
  },
};

export const { actions: authActions, reducer: authReducer } = createSlice({
  name: 'auth',
  initialState: initialAuthState,
  reducers: {
    login: (state, { payload }) => {
      state.token = payload.token;
    },
    changeLoginData: (state, { payload }) => {
      state.loginData = payload;
    },
    resetLoginData: (state) => {
      state.loginData = initialAuthState.loginData;
    },
  },
  extraReducers: (builder) => {
    builder.addMatcher(
      authService.endpoints.sendCode.matchFulfilled,
      (state, action) => {
        state.token = action.payload.token;
      }
    );
  },
});
