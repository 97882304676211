import { ChangeEvent } from 'react';
import styled from 'styled-components';
import colors from '../../utils/colors';

const Input = ({
  type,
  label,
  onChange,
  placeholder,
  value,
  isErrorText,
  toggle,
  onChangeVisibility,
  visible,
}: {
  type: string;
  label: string;
  onChange: (event: ChangeEvent<HTMLInputElement>) => void;
  placeholder: string;
  value: string;
  isErrorText: string;
  toggle?: boolean;
  visible?: boolean;
  onChangeVisibility?: () => void;
}) => {
  return (
    <InputContainer>
      <StyledLabel htmlFor={label}>{label}</StyledLabel>
      <StyledInput
        type={type}
        onChange={onChange}
        placeholder={placeholder}
        value={value}
        $isError={!!isErrorText}
      />
      {toggle && (
        <ToggleDiv onClick={onChangeVisibility}>
          <img
            src={`/assets/icons/${visible ? 'icon_showpw' : 'icon_hidepw'}.png`}
            alt='image'
          />
        </ToggleDiv>
      )}
      <ErrorText>{isErrorText}</ErrorText>
    </InputContainer>
  );
};

const InputContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  margin-bottom: 3px;
  position: relative;
`;

const StyledLabel = styled.label`
  font-size: 14px;
  font-weight: 600;
  line-height: 17px;
  margin-bottom: 10px;
`;

const ErrorText = styled.div`
  font-size: 11px;
  margin-top: 5px;
  min-height: 15px;
  color: ${colors.mainRed};
  font-weight: 500;
`;

const StyledInput = styled.input<{ $isError: boolean }>`
  height: 48px;
  border-radius: 8px;
  outline: none;
  border: ${({ $isError }) =>
    $isError ? `1px solid ${colors.mainRed}` : `1px solid ${colors.gray60}`};
  font-size: 16px;
  font-weight: 400;
  line-height: 20px;
  transition: box-shadow 0.3s ease-in-out;
  padding: 5px;
  display: inline-block;
  padding: 10px;
  &:active,
  &:focus {
    box-shadow: ${({ $isError }) =>
      $isError
        ? '0px 0px 10px rgba(255, 0, 0, 0.5)'
        : '0px 0px 10px rgba(58, 99, 195, 0.5)'};
  }
`;

const ToggleDiv = styled.div`
  position: absolute;
  right: 15px;
  bottom: 32px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 24px;
  height: 24px;
`;

export default Input;
