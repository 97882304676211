import styled, { keyframes } from 'styled-components';
import colors from '../../utils/colors';

interface IProps {
  width?: string;
  boldness?: string;
  color?: string;
}
export default function Loading({
  width = '100px',
  boldness = '12px',
  color = colors.mainDarkBlue,
}: IProps) {
  return <LoaderContainer $width={width} $boldness={boldness} $color={color} />;
}

const spin = keyframes`
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
`;

const LoaderContainer = styled.div<{
  $width: string;
  $boldness: string;
  $color: string;
}>`
  border: ${({ $boldness }) => `${$boldness} solid #f3f3f3`};
  border-top: ${({ $boldness, $color }) => `${$boldness} solid ${$color}`};
  border-radius: 50%;
  width: ${({ $width }) => $width};
  height: ${({ $width }) => $width};
  animation: ${spin} 2s linear infinite;
  margin: auto;
`;
