import { Middleware } from '@reduxjs/toolkit';

const errorHandlerMiddleware: Middleware =
  (store) => (next) => (action: any) => {
    const { payload } = action;
    const isRequestRejected =
      action.type.includes('apis') && action.type.endsWith('/rejected');
    if (isRequestRejected) {
      if (payload?.status === 401 || payload?.originalStatus === 401) {
        store.dispatch({ type: 'RESET' });
        localStorage.clear();
      }
    }

    return next(action);
  };

export default errorHandlerMiddleware;
