import { createPortal } from 'react-dom';
import styled, { keyframes } from 'styled-components';

const Modal = ({ isOpen, content, title }: any) => {
  if (!isOpen) return null;

  return createPortal(
    <ModalOverlay>
      <ModalContainer>
        <Header>
          <h3>{title}</h3>
        </Header>
        <ModalContent>{content}</ModalContent>
      </ModalContainer>
    </ModalOverlay>,
    document.getElementById('modal-root') as Element
  );
};

const ModalOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 3;
`;

const modalOpeninngAnimation = keyframes`
 0% { transform: scale(0)}
 100% { transform: scale(1)}
`;

const ModalContainer = styled.div`
  background-color: #fff;
  border-radius: 8px;
  padding: 30px 25px;
  position: relative;
  animation: ${modalOpeninngAnimation} 0.8s forwards;
  width: 50%;
  position: relative;
  z-index: 3;
  @media (max-width: 1200px) {
    width: 55%;
    margin: auto;
  }
  @media (max-width: 768px) {
    width: 80%;
    margin: auto;
  }
`;

const ModalContent = styled.div`
  margin-top: 20px;
`;

const Header = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

export default Modal;
