import React from 'react';
import styled from 'styled-components';
import colors from '../../../utils/colors';

interface ButtonProps {
  onClickHandler: any;
  text: string;
  type: string;
  hidden?: boolean;
  fontSize?: any;
}

const Button: React.FC<ButtonProps> = ({ onClickHandler, text, type, hidden, fontSize }) => {
  return (
    <StyledButton $fontSize={fontSize} $type={type} hidden={hidden} onClick={onClickHandler}>
      {text}
    </StyledButton>
  );
};

const StyledButton = styled.button<{ $type: string, $fontSize: any }>`
  height: 36px;
  border: 1px solid
    ${({ $type }) =>
      $type === 'save'
        ? colors.mainBlue
        : $type === 'update'
        ? colors.mainBlue
        : $type === 'deaktif'
        ? colors.mainRed
        : $type === 'aktif'
        ? colors.green
        : ''};
  border-radius: 10px;
  padding: 5px 15px;
  cursor: pointer;
  font-size: ${({ $fontSize }) => $fontSize ? `${$fontSize}` : "14px"};
  background-color: ${({ $type }) =>
    $type === 'save'
      ? colors.mainBlue
      : $type === 'update'
      ? colors.white
      : $type === 'deaktif'
      ? colors.mainRed
      : $type === 'aktif'
      ? colors.green
      : ''};
  color: ${({ $type }) =>
    $type === 'save'
      ? colors.white
      : $type === 'update'
      ? colors.mainBlue
      : $type === 'deaktif'
      ? colors.white
      : $type === 'aktif'
      ? colors.white
      : ''};
`;

export default Button;
