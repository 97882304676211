import {
  Page,
  Text,
  View,
  Document,
  StyleSheet,
  Font,
  Image,
} from '@react-pdf/renderer';

Font.register({
  family: 'Open Sans',
  src: 'https://fonts.gstatic.com/s/opensans/v18/mem8YaGs126MiZpBA-U1Ug.ttf',
});

const CustomPDFDocument = ({
  customerName,
  date,
  lastDate,
  packetName,
  packetDetails,
  hospitalPhone,
  hospitalAddress,
  remainingPayment,
  upfrontPayment,
  hospitalIcon,
  hospitalName,
  customerPhone,
  seriNu,
}: any) => (
  <Document>
    <Page size='A4' style={styles.page}>
      <View style={styles.headerContainer}>
        <Image style={styles.logo} src='/assets/icons/hangiCheckUpLogo.png' />
        <View style={styles.innerLine}>
          <Text style={styles.text8}>Seri No: {seriNu}</Text>
        </View>
      </View>
      <View style={styles.section}>
        <View style={styles.headerSection}>
          <Image style={styles.icon} src='/assets/icons/okey.png' />
          <Text style={styles.description}>Ön ödemeniz alındı!</Text>
        </View>
        <View style={styles.hospitalInfo}>
          <View style={styles.hospitalIcon}>
            <Image src={hospitalIcon} style={{ objectFit: 'cover' }} />
          </View>
          <View style={styles.hospitalRightInfo}>
            <Text style={styles.text1}>{packetName}</Text>
            <View style={{ flexWrap: 'wrap', width: '60%' }}>
              <Text style={styles.text2}>{hospitalName}</Text>
              <Text style={styles.text3}>{hospitalAddress}</Text>
              <Text style={styles.text3}>{hospitalPhone}</Text>
            </View>
          </View>
        </View>
        <View style={styles.item}>
          <Text style={styles.text4}>Ödeme Detayları</Text>
          <View>
            <View style={styles.innerLine}>
              <Text style={styles.text5}>Alınan Ön Ödeme</Text>
              <Text style={styles.text6}>{upfrontPayment} TRY</Text>
            </View>
            <View style={styles.innerLine}>
              <Text style={styles.text5}>Kalan Toplam Ödeme</Text>
              <Text style={styles.text6}>{remainingPayment} TRY</Text>
            </View>
            <View style={styles.innerLine}>
              <Text style={styles.text5}>Ödeme Tarihi</Text>
              <Text style={styles.text6}>{date}</Text>
            </View>
          </View>
        </View>
        {/* {packetDetails && (
          <View style={styles.item}>
            <Text style={styles.text4}>Paket Detayları</Text>
            <View>
              <View style={styles.innerLine}>
                <Text style={styles.text7}>{packetDetails}</Text>
              </View>
            </View>
          </View>
        )} */}
        <View style={styles.item}>
          <Text style={styles.text4}>Randevu Detayları</Text>
          <View>
            <View style={styles.innerLine}>
              <Text style={styles.text5}>Randevu Aralığı</Text>
              <Text style={styles.text6}>{`${date} / ${lastDate}`}</Text>
            </View>
            <View style={styles.innerLine}>
              <Text style={styles.text5}>Ad Soyad</Text>
              <Text style={styles.text6}>{customerName}</Text>
            </View>
            <View style={styles.innerLine}>
              <Text style={styles.text5}>Telefon Numarası</Text>
              <Text style={styles.text6}>{customerPhone}</Text>
            </View>
          </View>
        </View>
      </View>
      <View style={styles.footer}>
        <Text style={{ fontSize: 11, textAlign: 'justify' }}>
          Lütfen randevuya gelirken adınıza hazırlanmış olan işbu belgeyi
          yanınızda getirmeyi unutmayınız. Randevu geçerlilik tarihine dikkat
          ediniz. Aksi taktirde söz konusu belge geçersiz sayılacaktır .
        </Text>
        <Text style={{ textAlign: 'center' }}>www.hangicheckup.com</Text>
        <Text style={{ textAlign: 'center' }}>
          Sorularınız mı var? info@hangicheckup.com
        </Text>
      </View>
    </Page>
  </Document>
);

const styles = StyleSheet.create({
  page: {
    paddingVertical: 10,
    paddingHorizontal: 20,
    backgroundColor: '#f5f5f5',
    fontFamily: 'Open Sans',
  },
  headerContainer: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: 10,
  },
  headerSection: {
    justifyContent: 'center',
    alignItems: 'center',
    gap: 20,
  },
  hospitalInfo: {
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  hospitalRightInfo: {
    justifyContent: 'space-between',
    flexWrap: 'wrap',
  },
  hospitalIcon: {
    width: 180,
    height: 108,
    justifyContent: 'center',
    alignItems: 'center',
    border: '1px solid #BEC8EC',
    borderRadius: 10,
    backgroundColor: 'white',
  },
  innerLine: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  logo: {
    width: 230,
    height: 40,
  },
  icon: {
    width: 50,
    height: 50,
  },
  header: {
    fontSize: 26,
    color: '#333',
  },
  section: {
    padding: 10,
    flexGrow: 1,
    gap: 20,
  },
  item: {
    gap: 10,
  },
  label: {
    fontSize: 14,
    marginBottom: 5,
    color: '#555',
    fontWeight: 'bold',
  },
  text1: {
    fontSize: 16,
    fontWeight: 'light',
  },
  text2: {
    fontSize: 14,
    fontWeight: 'thin',
  },
  text3: {
    fontSize: 12,
    fontWeight: 'extralight',
    color: '#6D6E6F',
  },

  text4: {
    fontSize: 18,
    fontWeight: 'bold',
    color: '#2C4DC3',
  },
  text5: {
    fontSize: 12,
    fontWeight: 'thin',
  },
  text6: {
    fontSize: 14,
    fontWeight: 'bold',
  },
  text7: {
    fontSize: 10,
    fontWeight: 'thin',
  },
  text8: {
    fontSize: 14,
    fontWeight: 'bold',
  },
  description: {
    fontSize: 28,
    fontWeight: 'bold',
  },
  boldText: {
    fontSize: 12,
    fontWeight: 'bold',
    color: '#000',
  },
  footer: {
    marginTop: 20,
    textAlign: 'center',
    fontSize: 10,
    color: '#777',
    gap: 10,
  },
});

export default CustomPDFDocument;
