import { useState, useEffect, useRef } from 'react';
import styled from 'styled-components';
import colors from '../../utils/colors';
import {
  useGetHairLeadsMutation,
  useGetLeadsMutation,
} from '../../app/services/workspace.service';
import Loading from '../loading/Loading';
import Pagination from '../pagination/Pagination';
import Row from './row/Row';
import HairTransplantRow from './row/HairTransplantRow';

const columns = [
  { key: 'fullname', label: 'Ad-Soyad', sortable: true, filterable: false },
  { key: 'createdAt', label: 'Tarih', sortable: true, filterable: false },
  { key: 'phoneNumber', label: 'Telefon', sortable: false, filterable: false },
  // { key: 'paketAdi', label: 'Paket', sortable: false, filterable: false },
  { key: 'callStatus', label: 'Arama', sortable: false, filterable: true },
  { key: 'condition', label: 'Durum', sortable: false, filterable: true },
];

const HairTransplantTable = () => {
  const [data, setData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [showUncalledNumbers, setShowUncalledNumbers] = useState(false);
  const [showConditionFilterDropdown, setShowConditionFilterDropdown] =
    useState(false);
  const [showStatusFilterDropdown, setShowStatusFilterDropdown] =
    useState(false);
  const [conditionFilterArray, setConditionFilterArray]: any = useState([]);
  const [statusFilterArray, setStatusFilterArray]: any = useState([]);
  const [isFetching, setIsFetching] = useState(true);
  const [sortConfig, setSortConfig] = useState({
    key: 'createdAt',
    direction: 'asc',
  });
  const [totalPages, setTotalPages] = useState(1);
  const [getHairLeads] = useGetHairLeadsMutation();

  const statusDropdownRef = useRef<HTMLDivElement>(null);
  const conditionDropdownRef = useRef<HTMLDivElement>(null);

  const callOptions: { [key: string]: string } = {
    waiting: 'Aranmadı',
    called: 'Tamamlandı',
    calling: 'Tekrar Aranacak',
  };
  const statusOptions: { [key: string]: string } = {
    busy: 'Meşgul',
    offered: 'Teklif yapıldı',
    rejected: 'Olumsuz',
    notReached: 'Ulaşılamadı',
    sold: 'Satış yapıldı',
  };

  useEffect(() => {
    fetchData();
  }, [
    currentPage,
    sortConfig,
    showUncalledNumbers,
    conditionFilterArray,
    statusFilterArray,
  ]);

  const fetchData = async () => {
    const { data }: any = await getHairLeads({
      page: currentPage,
      sort: sortConfig.key,
      order: sortConfig.direction,
      filter: showUncalledNumbers,
      filters: {
        condition: conditionFilterArray,
        status: statusFilterArray,
      },
    });

    if (data?.data) {
      setData(data?.data);
      setTotalPages(data?.totalPages);
      setIsFetching(false);
    }
  };

  const handleSort = (key: any) => {
    let direction = 'asc';
    if (sortConfig.key === key && sortConfig.direction === 'asc') {
      direction = 'desc';
    }
    setCurrentPage(1);
    setSortConfig({ key, direction });
  };

  const handlePageChange = (newPage: any) => {
    setCurrentPage(newPage);
  };

  const conditionFilterDropdownItemClickHandle = (optionKey: any) => {
    if (
      !conditionFilterArray.includes(optionKey) ||
      conditionFilterArray.length == 0
    ) {
      setConditionFilterArray([...conditionFilterArray, optionKey]);
    } else {
      setConditionFilterArray([
        ...conditionFilterArray.filter((item: any) => item !== optionKey),
      ]);
    }
  };

  const statusFilterDropdownItemClickHandle = (optionKey: any) => {
    if (
      !statusFilterArray.includes(optionKey) ||
      statusFilterArray.length == 0
    ) {
      setStatusFilterArray([...statusFilterArray, optionKey]);
    } else {
      setStatusFilterArray([
        ...statusFilterArray.filter((item: any) => item !== optionKey),
      ]);
    }
    setShowStatusFilterDropdown((prev) => !prev);
  };

  useEffect(() => {
    const handleClickOutside = (event: any) => {
      if (
        statusDropdownRef.current &&
        !statusDropdownRef.current.contains(event.target)
      ) {
        setShowStatusFilterDropdown(false);
      }
      if (
        conditionDropdownRef.current &&
        !conditionDropdownRef.current.contains(event.target)
      ) {
        setShowConditionFilterDropdown(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  return (
    <div>
      <Disclaimer>
        ( Maskeli Numarayı görebilmek için lütfen arama simgesine tıklayın! )
      </Disclaimer>
      <div style={{ overflow: 'auto', paddingTop: '2px' }}>
        <StyledTable>
          <tr>
            {columns.map(({ key, label, sortable, filterable }) => (
              <StyledTh key={key}>
                <InnerTh>
                  <InnerThContainer
                    $filterable={filterable}
                    $sortable={sortable}
                    onClick={() => {
                      sortable && handleSort(key);
                      filterable &&
                        key === 'callStatus' &&
                        setShowStatusFilterDropdown((prev) => !prev);
                      filterable &&
                        key === 'condition' &&
                        setShowConditionFilterDropdown((prev) => !prev);
                    }}
                  >
                    {label}
                    {sortable && (
                      <SortIndicator>
                        {sortConfig.key === key
                          ? sortConfig.direction === 'asc'
                            ? '▲'
                            : '▼'
                          : ''}
                      </SortIndicator>
                    )}
                    {filterable && (
                      <div
                        style={{
                          position: 'relative',
                        }}
                      >
                        {((conditionFilterArray.length > 0 &&
                          key === 'condition') ||
                          (statusFilterArray.length > 0 &&
                            key === 'callStatus')) && (
                          <div
                            style={{
                              position: 'absolute',
                              display: 'flex',
                              justifyContent: 'center',
                              alignItems: 'center',
                              top: '-2px',
                              left: '20px',
                              fontSize: '8px',
                              color: colors.listHover,
                              backgroundColor: colors.mainDarkBlue,
                              borderRadius: '50%',
                              width: '13px',
                              height: '13px',
                            }}
                          >
                            {key === 'callStatus'
                              ? statusFilterArray.length
                              : conditionFilterArray.length}
                          </div>
                        )}
                        <img
                          style={{
                            marginLeft: '7px',
                            backgroundColor:
                              (showConditionFilterDropdown &&
                                key === 'condition') ||
                              (showStatusFilterDropdown && key === 'callStatus')
                                ? colors.listHover
                                : 'white',
                            borderRadius: '50%',
                            padding: '2px',
                          }}
                          width={27}
                          height={27}
                          src='/assets/icons/filter-icon.png'
                          alt='call-filter'
                        />
                      </div>
                    )}
                  </InnerThContainer>
                  {showStatusFilterDropdown && key === 'callStatus' && (
                    <DropdownContent ref={statusDropdownRef}>
                      {Object.entries(callOptions).map(
                        ([optionKey, optionValue], index) => (
                          <DropdownContainer>
                            <DropdownItem key={index}>
                              {optionValue}
                            </DropdownItem>
                            <FilterCheckbox
                              type='checkbox'
                              id='call-checkbox'
                              checked={statusFilterArray.includes(optionKey)}
                              onClick={() =>
                                statusFilterDropdownItemClickHandle(optionKey)
                              }
                            />
                          </DropdownContainer>
                        )
                      )}
                    </DropdownContent>
                  )}
                  {showConditionFilterDropdown && key === 'condition' && (
                    <DropdownContent ref={conditionDropdownRef}>
                      {Object.entries(statusOptions).map(
                        ([optionKey, optionValue], index) => (
                          <DropdownContainer>
                            <DropdownItem key={index}>
                              {optionValue}
                            </DropdownItem>
                            <FilterCheckbox
                              type='checkbox'
                              id='call-checkbox'
                              checked={conditionFilterArray.includes(optionKey)}
                              onClick={() =>
                                conditionFilterDropdownItemClickHandle(
                                  optionKey
                                )
                              }
                            />
                          </DropdownContainer>
                        )
                      )}
                    </DropdownContent>
                  )}
                </InnerTh>
              </StyledTh>
            ))}
          </tr>
          <Space />
          {data.length > 0 &&
            data.map((row: any, index) => {
              const moreThanOnePacket =
                row?.repetitive?.length > 0 &&
                Object.keys(row?.repetitive?.[0])?.length > 0;
              return (
                <HairTransplantRow
                  key={index}
                  row={row}
                  index={index}
                  moreThanOnePacket={moreThanOnePacket}
                  onFetchData={fetchData}
                />
              );
            })}
        </StyledTable>
      </div>
      {!isFetching && data.length === 0 && (
        <NoOfferMessage>
          Henüz herhangi bir istek bulunmamaktadır.
        </NoOfferMessage>
      )}
      {isFetching && <Loading width='50px' boldness='6px' />}
      <PaginationWrapper>
        <Pagination
          onChangePage={handlePageChange}
          pageNumber={currentPage}
          totalCount={totalPages * 10}
        />
      </PaginationWrapper>
    </div>
  );
};

const StyledTable = styled.table`
  width: 100%;
  border-collapse: collapse;
  min-width: 850px;
  margin-bottom: 15px;
  position: relative;
`;

const StyledTh = styled.th<{ sortable?: boolean }>`
  color: ${colors.mainBlue};
  text-align: left;
  padding: 0px 10px 0px 10px;
  width: auto;
  height: 50px;
`;

const InnerTh = styled.div<{ $sortable?: boolean; $filterable?: boolean }>`
  margin: auto;
  font-size: 16px;
  display: flex;
  align-items: center;
`;

const InnerThContainer = styled.div<{
  $sortable?: boolean;
  $filterable?: boolean;
}>`
  cursor: ${({ $sortable, $filterable }) =>
    $sortable || $filterable ? 'pointer' : 'auto'};
  display: flex;
  justify-content: start;
`;

const SortIndicator = styled.span`
  margin-left: 15px;
`;

const PaginationWrapper = styled.div`
  display: flex;
  justify-content: center;
`;

const Space = styled.div`
  height: 30px;
`;

// const HeaderContainer = styled.div`
//   display: flex;
//   justify-content: center;
//   background: #f7f7f7;
//   border-radius: 14px;
//   padding: 10px 20px;
// `;

// const HeaderTitle = styled.h1`
//   font-size: 32px;
//   line-height: 39px;
//   @media (max-width: 768px) {
//     font-size: 24px;
//   }
// `;

const FilterWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  margin: 20px 0px;
`;

const Disclaimer = styled.div`
  text-align: end;
  margin-bottom: 25px;
  font-style: italic;
  font-weight: 100;
`;

const FilterContainer = styled.div`
  display: flex;
  gap: 10px;
  align-items: center;
`;

const FilterCheckbox = styled.input`
  cursor: pointer;
`;

const FilterLabel = styled.label`
  @media (max-width: 768px) {
    font-size: 14px;
  }
`;

const NoOfferMessage = styled.div`
  text-align: center;
  padding: 100px 0;
  font-weight: 600;
`;

const DropdownContainer = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 10px 10px;
  &:hover {
    background-color: #f1f1f1;
  }
  cursor: pointer;
`;

const DropdownContent = styled.div`
  position: absolute;
  background-color: #f9f9f9;
  min-width: 140px;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  top: 40px;
  z-index: 2;
`;

const DropdownItem = styled.div`
  font-size: 12px;
`;

export default HairTransplantTable;
