import { useState, useEffect, useRef } from 'react';
import styled from 'styled-components';
import colors from '../../utils/colors';
import { useGetLeadsMutation } from '../../app/services/workspace.service';
import Loading from '../loading/Loading';
import Pagination from '../pagination/Pagination';
import Row from './row/Row';
import { useLocation } from 'react-router-dom';
import InputTwo from '../common/InputTwo';
import { InputType } from '../../utils/types';
import { useDebounce } from '../../app/hooks/debounce';
import { DISTRICTS } from '../../utils/globals';

const columns = [
  { key: 'fullname', label: 'Ad-Soyad', sortable: true, filterable: false },
  { key: 'createdAt', label: 'Tarih', sortable: true, filterable: false },
  { key: 'phoneNumber', label: 'Telefon', sortable: false, filterable: false },
  { key: 'leadDistrict', label: 'Konum', sortable: false, filterable: true },
  { key: 'hospital', label: 'Hastane', sortable: false, filterable: false },
  { key: 'callStatus', label: 'Arama', sortable: false, filterable: true },
  { key: 'condition', label: 'Durum', sortable: false, filterable: true },
];

const callOptions: { [key: string]: string } = {
  waiting: 'Aranmadı',
  called: 'Tamamlandı',
  calling: 'Tekrar Aranacak',
};
const statusOptions: { [key: string]: string } = {
  busy: 'Meşgul',
  offered: 'Teklif yapıldı',
  rejected: 'Olumsuz',
  notReached: 'Ulaşılamadı',
  sold: 'Satış yapıldı',
  wrongNumber: 'Yanlış numara',
};

const SampleTable = () => {
  const [data, setData] = useState([]);
  const location = useLocation();

  const [query, setQuery] = useState('');
  const debouncedQuery = useDebounce(query, 750);

  const [currentPage, setCurrentPage] = useState(
    location.state?.currentPage || 1
  );
  const [showUncalledNumbers, setShowUncalledNumbers] = useState(false);
  const [showConditionFilterDropdown, setShowConditionFilterDropdown] =
    useState(false);
  const [showDistrictFilterDropdown, setShowDistrictFilterDropdown] =
    useState(false);
  const [showStatusFilterDropdown, setShowStatusFilterDropdown] =
    useState(false);
  const [conditionFilterArray, setConditionFilterArray]: any = useState([]);
  const [districtFilterArray, setDistrictFilterArray]: any = useState([]);
  const [statusFilterArray, setStatusFilterArray]: any = useState([]);
  const [isFetching, setIsFetching] = useState(true);
  const [sortConfig, setSortConfig] = useState({
    key: 'createdAt',
    direction: 'asc',
  });
  const [totalPages, setTotalPages] = useState(1);
  const [getLeads] = useGetLeadsMutation();

  const statusDropdownRef = useRef<HTMLDivElement>(null);
  const conditionDropdownRef = useRef<HTMLDivElement>(null);
  const districtDropdownRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    fetchData();
  }, [
    currentPage,
    sortConfig,
    showUncalledNumbers,
    conditionFilterArray,
    statusFilterArray,
    districtFilterArray,
    debouncedQuery,
  ]);

  const fetchData = async () => {
    const { data }: any = await getLeads({
      page: currentPage,
      sort: sortConfig.key,
      order: sortConfig.direction,
      filter: showUncalledNumbers,
      filters: {
        condition: conditionFilterArray,
        status: statusFilterArray,
        district: districtFilterArray,
        phoneFilter: debouncedQuery,
      },
    });
    setData(data?.data);
    setTotalPages(data?.totalPages);
    setIsFetching(false);
    console.log('data fetchlendi');
  };

  const handleSort = (key: any) => {
    let direction = 'asc';
    if (sortConfig.key === key && sortConfig.direction === 'asc') {
      direction = 'desc';
    }
    setCurrentPage(1);
    setSortConfig({ key, direction });
  };

  const handlePageChange = (newPage: any) => {
    setCurrentPage(newPage);
  };

  const conditionFilterDropdownItemClickHandle = (optionKey: any) => {
    setCurrentPage(1);
    if (
      !conditionFilterArray.includes(optionKey) ||
      conditionFilterArray.length == 0
    ) {
      setConditionFilterArray([...conditionFilterArray, optionKey]);
    } else {
      setConditionFilterArray([
        ...conditionFilterArray.filter((item: any) => item !== optionKey),
      ]);
    }
  };

  const districtFilterDropdownItemClickHandle = (optionKey: any) => {
    setCurrentPage(1);
    if (
      !districtFilterArray.includes(optionKey) ||
      districtFilterArray.length == 0
    ) {
      setDistrictFilterArray([...districtFilterArray, optionKey]);
    } else {
      setDistrictFilterArray([
        ...districtFilterArray.filter((item: any) => item !== optionKey),
      ]);
    }
  };

  const statusFilterDropdownItemClickHandle = (optionKey: any) => {
    setCurrentPage(1);
    if (
      !statusFilterArray.includes(optionKey) ||
      statusFilterArray.length == 0
    ) {
      setStatusFilterArray([...statusFilterArray, optionKey]);
    } else {
      setStatusFilterArray([
        ...statusFilterArray.filter((item: any) => item !== optionKey),
      ]);
    }
    setShowStatusFilterDropdown((prev) => !prev);
  };

  useEffect(() => {
    const handleClickOutside = (event: any) => {
      if (
        statusDropdownRef.current &&
        !statusDropdownRef.current.contains(event.target)
      ) {
        setShowStatusFilterDropdown(false);
      }
      if (
        conditionDropdownRef.current &&
        !conditionDropdownRef.current.contains(event.target)
      ) {
        setShowConditionFilterDropdown(false);
      }
      if (
        districtDropdownRef.current &&
        !districtDropdownRef.current.contains(event.target)
      ) {
        setShowDistrictFilterDropdown(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  return (
    <>
      <Disclaimer>
        ( Maskeli Numarayı görebilmek için lütfen arama simgesine tıklayın! )
        <div style={{ width: '30%', maxWidth: '200px' }}>
          <InputTwo
            type={'phone'}
            onChange={(e) => {
              if (Number(e.target.value) > -1) {
                setQuery(e.target.value);
              }
            }}
            placeholder='Telefon Numarası Giriniz'
            value={query}
            isErrorText={''}
          />
        </div>
      </Disclaimer>
      <div>
        <div style={{ overflow: 'auto', paddingTop: '2px' }}>
          <StyledTable>
            <tr>
              {columns.map(({ key, label, sortable, filterable }) => (
                <StyledTh key={key}>
                  <InnerTh>
                    <InnerThContainer
                      $filterable={filterable}
                      $sortable={sortable}
                      onClick={() => {
                        sortable && handleSort(key);
                        filterable &&
                          key === 'callStatus' &&
                          setShowStatusFilterDropdown((prev) => !prev);
                        filterable &&
                          key === 'condition' &&
                          setShowConditionFilterDropdown((prev) => !prev);
                        filterable &&
                          key === 'leadDistrict' &&
                          setShowDistrictFilterDropdown((prev) => !prev);
                      }}
                    >
                      <div>{label}</div>
                      {sortable && (
                        <SortIndicator>
                          {sortConfig.key === key
                            ? sortConfig.direction === 'asc'
                              ? '▲'
                              : '▼'
                            : ''}
                        </SortIndicator>
                      )}
                      {filterable && (
                        <div
                          style={{
                            position: 'relative',
                            display: 'flex',
                            alignItems: 'center',
                          }}
                        >
                          {((conditionFilterArray.length > 0 &&
                            key === 'condition') ||
                            (statusFilterArray.length > 0 &&
                              key === 'callStatus') ||
                            (districtFilterArray.length > 0 &&
                              key === 'leadDistrict')) && (
                            <div
                              style={{
                                position: 'absolute',
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                                top: '-2px',
                                left: '20px',
                                fontSize: '8px',
                                color: colors.listHover,
                                backgroundColor: colors.mainDarkBlue,
                                borderRadius: '50%',
                                width: '13px',
                                height: '13px',
                              }}
                            >
                              {key === 'callStatus'
                                ? statusFilterArray.length
                                : key === 'condition'
                                ? conditionFilterArray.length
                                : districtFilterArray.length}
                            </div>
                          )}
                          <img
                            style={{
                              marginLeft: '7px',
                              backgroundColor:
                                (showConditionFilterDropdown &&
                                  key === 'condition') ||
                                (showStatusFilterDropdown &&
                                  key === 'callStatus') ||
                                (showDistrictFilterDropdown &&
                                  key === 'leadDistrict')
                                  ? colors.listHover
                                  : 'white',
                              borderRadius: '50%',
                              padding: '2px',
                            }}
                            width={27}
                            height={27}
                            src='/assets/icons/filter-icon.png'
                            alt='call-filter'
                          />
                        </div>
                      )}
                    </InnerThContainer>
                    {/* Arama Dropdown */}
                    {showStatusFilterDropdown && key === 'callStatus' && (
                      <DropdownContent ref={statusDropdownRef}>
                        {Object.entries(callOptions).map(
                          ([optionKey, optionValue], index) => (
                            <DropdownContainer>
                              <DropdownItem key={index}>
                                {optionValue}
                              </DropdownItem>
                              <FilterCheckbox
                                type='checkbox'
                                id='call1-checkbox'
                                checked={statusFilterArray.includes(optionKey)}
                                onClick={() =>
                                  statusFilterDropdownItemClickHandle(optionKey)
                                }
                              />
                            </DropdownContainer>
                          )
                        )}
                      </DropdownContent>
                    )}
                    {/* Durum Dropdown */}
                    {showConditionFilterDropdown && key === 'condition' && (
                      <DropdownContent ref={conditionDropdownRef}>
                        {Object.entries(statusOptions).map(
                          ([optionKey, optionValue], index) => (
                            <DropdownContainer>
                              <DropdownItem key={index}>
                                {optionValue}
                              </DropdownItem>
                              <FilterCheckbox
                                type='checkbox'
                                id='call2-checkbox'
                                checked={conditionFilterArray.includes(
                                  optionKey
                                )}
                                onClick={() =>
                                  conditionFilterDropdownItemClickHandle(
                                    optionKey
                                  )
                                }
                              />
                            </DropdownContainer>
                          )
                        )}
                      </DropdownContent>
                    )}
                    {showDistrictFilterDropdown && key === 'leadDistrict' && (
                      <DropdownContent ref={districtDropdownRef}>
                        {DISTRICTS.filter((item) => item.text !== 'TÜMÜ').map(
                          ({ text }: any, index) => (
                            <DropdownContainer>
                              <DropdownItem key={index}>{text}</DropdownItem>
                              <FilterCheckbox
                                type='checkbox'
                                id='call3-checkbox'
                                checked={districtFilterArray.includes(text)}
                                onClick={() =>
                                  districtFilterDropdownItemClickHandle(text)
                                }
                              />
                            </DropdownContainer>
                          )
                        )}
                      </DropdownContent>
                    )}
                  </InnerTh>
                </StyledTh>
              ))}
            </tr>
            <Space />
            {data.length > 0 &&
              data.map((row: any, index) => {
                const moreThanOnePacket =
                  row?.repetitive?.length > 0 &&
                  Object.keys(row?.repetitive?.[0])?.length > 0;
                return (
                  <Row
                    key={index}
                    row={row}
                    index={index}
                    moreThanOnePacket={moreThanOnePacket}
                    onFetchData={fetchData}
                    currentPage={currentPage}
                  />
                );
              })}
          </StyledTable>
        </div>
        {!isFetching && data.length === 0 && (
          <NoOfferMessage>
            Henüz herhangi bir istek bulunmamaktadır.
          </NoOfferMessage>
        )}
        {isFetching && <Loading width='50px' boldness='6px' />}
        {!isFetching && (
          <PaginationWrapper>
            <Pagination
              onChangePage={handlePageChange}
              pageNumber={currentPage}
              totalCount={totalPages * 10}
            />
          </PaginationWrapper>
        )}
      </div>
    </>
  );
};

const StyledTable = styled.table`
  width: 100%;
  border-collapse: collapse;
  min-width: 850px;
  margin-bottom: 15px;
  position: relative;
`;

const StyledTh = styled.th<{ sortable?: boolean }>`
  color: ${colors.mainBlue};
  text-align: left;
  padding: 0px 10px 0px 10px;
  width: auto;
  height: 50px;
`;

const InnerTh = styled.div<{ $sortable?: boolean; $filterable?: boolean }>`
  margin: auto;
  font-size: 16px;
  display: flex;
  align-items: center;
`;

const InnerThContainer = styled.div<{
  $sortable?: boolean;
  $filterable?: boolean;
}>`
  cursor: ${({ $sortable, $filterable }) =>
    $sortable || $filterable ? 'pointer' : 'auto'};
  display: flex;
  justify-content: start;
  align-items: center;
`;

const SortIndicator = styled.span`
  margin-left: 15px;
`;

const PaginationWrapper = styled.div`
  display: flex;
  justify-content: center;
`;

const Space = styled.div`
  height: 30px;
`;

const Disclaimer = styled.div`
  text-align: end;
  margin-bottom: 25px;
  font-style: italic;
  font-weight: 100;
`;

const FilterCheckbox = styled.input`
  cursor: pointer;
`;

const NoOfferMessage = styled.div`
  text-align: center;
  padding: 100px 0;
  font-weight: 600;
`;

const DropdownContainer = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 10px 10px;
  &:hover {
    background-color: #f1f1f1;
  }
  cursor: pointer;
`;

const DropdownContent = styled.div`
  position: absolute;
  background-color: #f9f9f9;
  min-width: 140px;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  top: 40px;
  max-height: 250px;
  overflow-y: scroll;
  z-index: 2;
`;

const DropdownItem = styled.div`
  font-size: 12px;
`;

export default SampleTable;
