import api from '../api';

export const callcenterService = api.injectEndpoints({
  endpoints: (build) => {
    return {
      getDropdownCompany: build.mutation({
        query: () => ({
          url: 'callcenter/companies',
        }),
        invalidatesTags: ['DROPDOWN_COMPANY'],
      }),
      getCustomers: build.mutation({
        query: () => ({
          url: 'callcenter/customers',
        }),
        invalidatesTags: ['GET_CUSTOMERS'],
      }),
      getAppointed: build.mutation({
        query: (args) => ({
          url: 'callcenter/appointed',
          method: 'PUT',
          body: args,
        }),
        invalidatesTags: ['GET_APPOINTED'],
      }),
      postCustomer: build.mutation({
        query: (args) => ({
          method: 'POST',
          url: 'callcenter/customers',
          body: args,
        }),
        invalidatesTags: ['POST_CUSTOMER'],
      }),
    };
  },
});

export const {
  useGetDropdownCompanyMutation,
  useGetCustomersMutation,
  usePostCustomerMutation,
  useGetAppointedMutation,
} = callcenterService;
