import styled from 'styled-components';

const LandingContent = ({ children, title, id }: any) => {
  return (
    <Container>
      <Title id={id}>{title}</Title>
      {children}
    </Container>
  );
};

const Container = styled.div`
  margin-top: 30px;
  margin-bottom: 80px;
  width: 100%;
`;

const Title = styled.h1`
  margin-bottom: 73px;
`;

export default LandingContent;
