import { useCallback, useContext, useEffect, useState } from 'react';
import { AppContext } from '../../context/socket-context';
import QRCode from 'react-qr-code';
import { useAppSelector } from '../../app/hooks';
import { toast } from 'react-toastify';
import styled from 'styled-components';
import Loading from '../../components/loading/Loading';
import { isEmptySessionObj } from '../../utils/helperFunctions';

const initialSessionInfo = {};

const Whatsapp = () => {
  const { socket } = useContext(AppContext);
  const workspace = useAppSelector((store) => store.workspace.data);
  const [qrCode, setQrCode] = useState('');
  const [qrLoading, setQRLoading] = useState(false);

  const [sessionInfo, setSessionInfo] = useState<{ [keys: string]: string }>(
    initialSessionInfo
  );

  useEffect(() => {
    const setupSocketListeners = () => {
      socket.on('qr', (data: any) => {
        console.log('QR is coming:', data);
        const { qr } = data;
        setQrCode(qr);
        setQRLoading(false);
      });

      socket.on('alert', (data: any) => {
        console.log('Alert is coming:', data);
        setQRLoading(false);
        toast.info(data.message);
      });

      socket.on('session-info', (data: any) => {
        console.log(data);
        setSessionInfo(data);
        setQrCode('');
      });

      socket.on('logout', (data: any) => {
        console.log(data);
      });
    };

    if (socket) {
      setupSocketListeners();
    }

    return () => {
      socket?.off('qr');
      socket?.off('alert');
      socket?.off('session-info');
      socket?.off('logout');
    };
  }, [socket]);

  useEffect(() => {
    setSessionInfo(
      workspace?.company?.connectedWhatsappAccount || initialSessionInfo
    );
  }, [workspace?.company?.connectedWhatsappAccount]);

  const onLogout = useCallback(async () => {
    if (socket) {
      socket.emit('logout', { workspaceId: workspace._id.toString() });
    }
  }, [socket]);

  const onGenerateQR = useCallback(async () => {
    if (socket) {
      setQRLoading(true);
      socket.emit('create-session', { workspaceId: workspace._id.toString() });
    }
  }, [socket]);

  return (
    <Container>
      <ContentWrapper>
        {!isEmptySessionObj(sessionInfo) ? (
          <InfoWrapper>
            <div>
              {sessionInfo?.name && (
                <>
                  <b>Kullanıcı Adı :</b> {sessionInfo?.name}
                </>
              )}
            </div>
            <div>
              {sessionInfo?.phoneNumber && (
                <>
                  <b>Kullanıcı Numarası :</b> {sessionInfo?.phoneNumber}
                </>
              )}
            </div>
            <div>
              {sessionInfo?.platform && (
                <>
                  <b>Kullanıcı Platformu :</b> {sessionInfo?.platform}
                </>
              )}
            </div>
          </InfoWrapper>
        ) : null}
        <QRWrapper>
          {qrCode && !qrLoading && <QRCode value={qrCode} />}
          {!qrCode && qrLoading && <Loading width='50px' boldness='6px' />}
          {!qrCode && !qrLoading && <QRCodeSpace>QR Kod Alanı</QRCodeSpace>}
        </QRWrapper>
        {!isEmptySessionObj(sessionInfo) && (
          <ButtonWrapper>
            <button onClick={onLogout}>WhatsApp oturumunu sonlandır</button>
          </ButtonWrapper>
        )}
        {!qrCode && isEmptySessionObj(sessionInfo) && (
          <ButtonWrapper>
            <button onClick={onGenerateQR}>Whatsapp oturumu oluştur</button>
          </ButtonWrapper>
        )}
        {qrCode && isEmptySessionObj(sessionInfo) && (
          <div>Whatsapp üzerinden QR kodu tarat</div>
        )}
      </ContentWrapper>
    </Container>
  );
};

const Container = styled.div`
  padding: 0px 5%;
  gap: 30px;
  display: flex;
  justify-content: center;
`;

const ContentWrapper = styled.div`
  padding: 150px 0px 50px 0px;
  width: 300px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 25px;
`;

const InfoWrapper = styled.div`
  width: 300px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 8px;

  & > div {
    align-self: flex-start;
  }
`;

const ButtonWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  cursor: pointer;
  background-color: #dfe4f6;
  padding: 10px;
  border-radius: 10px;

  & > button {
    cursor: pointer;
    color: #2c4dc3;
  }
`;

const QRWrapper = styled.div`
  width: 100%;
  height: 300px;
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: 0px 0px 10px 4px rgba(0, 0, 255, 0.1);
`;

const QRCodeSpace = styled.div`
  /* Add styling for QR Code Space if needed */
`;

export default Whatsapp;
