import { ChangeEvent } from 'react';
import styled from 'styled-components';
import colors from '../../utils/colors';

const InputTwo = ({
  type,
  onChange,
  placeholder,
  value,
  isErrorText,
}: {
  type: string;
  onChange: (event: ChangeEvent<HTMLInputElement>) => void;
  placeholder: string;
  value: string;
  isErrorText: string;
}) => {
  return (
    <InputContainer>
      <StyledInput
        type={type}
        onChange={onChange}
        placeholder={placeholder}
        value={value}
        $isError={!!isErrorText}
      />
      <ErrorText>{isErrorText}</ErrorText>
    </InputContainer>
  );
};

const InputContainer = styled.div`
  width: 100%;
`;

const ErrorText = styled.div`
  font-size: 11px;
  margin-top: 5px;
  min-height: 15px;
  color: ${colors.mainRed};
  font-weight: 500;
`;

const StyledInput = styled.input<{ $isError: boolean }>`
  height: 48px;
  /* border-radius: 8px; */
  outline: none;
  border-bottom: ${({ $isError }) =>
    $isError ? `1px solid ${colors.mainRed}` : `1px solid ${colors.gray60}`};
  font-size: 16px;
  font-weight: 400;
  line-height: 20px;
  transition: box-shadow 0.3s ease-in-out;
  padding: 5px;
  display: inline-block;
  padding: 10px;
  width: 100%;
  &:active,
  &:focus {
    box-shadow: ${({ $isError }) =>
      $isError
        ? '0px 1px 0px rgba(113, 1, 1, 0.819)'
        : `0px 1px 0px ${colors.mainBlue}`};
  }
`;

export default InputTwo;
