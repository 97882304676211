import styled from 'styled-components';
import { MOBILE_WIDTH } from '../../utils/globals';

interface NumberedCardProps {
  number: string;
  image: string;
  text: string;
}

const NumberedCard: React.FC<NumberedCardProps> = ({ number, image, text }) => {
  return (
    <Container>
      <NumberCircle>{number}</NumberCircle>
      <ImageContainer>
        <img
          height={image === '/assets/images/card-image3.png' ? '44px' : '60px'}
          src={image}
          alt='Card'
        />
      </ImageContainer>
      <Text>{text}</Text>
    </Container>
  );
};

const Container = styled.div`
  height: 305px;
  width: 270px;
  background-color: #ffffff;
  margin-bottom: 50px;
  margin-top: 40px;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  border-radius: 24.58px;
  gap: 20px;
  box-shadow: 0px 0px 24.583341598510742px 4.91666841506958px #2c4dc31f;
`;

const NumberCircle = styled.div`
  height: 50px;
  width: 50px;
  border-radius: 50%;
  background-color: #2c4dc3;
  color: #ffffff;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  left: 50%;
  top: -25px;
  transform: translateX(-50%);
  font-size: 25px;
  font-weight: 700;
  line-height: 30.26px;
`;

const ImageContainer = styled.div`
  height: 106px;
  width: 106px;
  border-radius: 50%;
  background-color: #f3f7ff;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const Text = styled.p`
  width: 208.96px;
  height: 76px;
  font-size: 16px;
  font-weight: 400;
  line-height: 19.36px;
  @media screen and (max-width: ${MOBILE_WIDTH}px) {
    font-size: 14px;
}
`;

export default NumberedCard;
