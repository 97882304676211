import { useEffect, useState } from 'react';
import { useGetAppointedMutation } from '../../app/services/callcenter.service';
import styled from 'styled-components';
import CallcenterTable from '../../components/table/callcenter/CallcenterTable';
import Loading from '../../components/loading/Loading';
import InputTwo from '../../components/common/InputTwo';
import { useDebounce } from '../../app/hooks/debounce';

const AppointedCustomersAll = () => {
  const [appointedCustomers, setAppointedCustomers] = useState<any>([]);
  const [getAppointed, { isLoading }] = useGetAppointedMutation();

  const [query, setQuery] = useState('');
  const debouncedQuery = useDebounce(query, 750);

  const getAppointedCustomersHandler = async (debouncedQuery: string) => {
    const { data }: any = await getAppointed({ filter: debouncedQuery });
    setAppointedCustomers([...data?.data]);
  };

  useEffect(() => {
    getAppointedCustomersHandler(debouncedQuery);
  }, [debouncedQuery]);

  return (
    <RightFeature>
      <div style={{ width: '30%', maxWidth: '200px' }}>
        <InputTwo
          type={'phone'}
          onChange={(e) => {
            if (Number(e.target.value) > -1) {
              setQuery(e.target.value);
            }
          }}
          placeholder='Telefon Numarası Giriniz'
          value={query}
          isErrorText={''}
        />
      </div>
      <AppointedCustomers>
        {appointedCustomers.length > 0 && (
          <CallcenterTable data={appointedCustomers} />
        )}
        {appointedCustomers.length === 0 && (
          <div style={{ textAlign: 'center' }}>
            Herhangi bir veri bulunamadı
          </div>
        )}
        {isLoading && <Loading width='50px' boldness='6px' />}
      </AppointedCustomers>
    </RightFeature>
  );
};

const RightFeature = styled.div`
  width: 100%;
  padding: 19px 0px;
`;

const AppointedCustomers = styled.div`
  width: 100%;
  height: 100%;
  overflow-y: auto;
`;

export default AppointedCustomersAll;
