import styled from "styled-components";

interface ModalCloseButtonProps {
    onClick: () => void;
}

const ModalCloseButton: React.FC<ModalCloseButtonProps> = ({onClick}) => {
 
    return(
        <CloseButton onClick={onClick}>
            X
        </CloseButton>
    )
}

const CloseButton = styled.button`
  position: absolute;
  top: 20px;
  right: 20px;
  background-color: transparent;
  border: none;
  cursor: pointer;
`;

export default ModalCloseButton