import React from 'react';
import styled from 'styled-components';
import { MEDIUM_WIDTH, MOBILE_WIDTH } from '../../utils/globals';

interface ContentCardProps {
    image: string;
    title: string;
    text: string;
}

const ContentCard: React.FC<ContentCardProps> = ({ image, title, text }) => {
    return (
        <Container>
            <Image src={image} />
            <Title>{title}</Title>
            <Description>{text}</Description>
        </Container>
    );
}

const Container = styled.div`
    height: 417px;
    width: 540px;
    margin-bottom: 60px;
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    @media screen and (max-width:1349px) {
        height: 237px;
        width: 360px;
        margin-bottom: 110px;
    }
`;

const Image = styled.img`
    height: 160px;
    margin-bottom: 40px;
    @media screen and (max-width: 1350px) {
        height: 110px;
        margin-bottom: 20px;
    }
`;

const Title = styled.h4`
    font-size: 28px;
    width: 481px;
    margin-bottom: 16px;
    @media screen and (max-width: 1350px) {
        font-size: 22px;
        width: auto;
    }
`;

const Description = styled.p`
    font-size: 16px;
    width: 378px;
    @media screen and (max-width: ${MEDIUM_WIDTH}px) {
        width: auto;
        font-size: 14px;
    }
`;

export default ContentCard;
