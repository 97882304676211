import styled from 'styled-components';
import InfoLabel from './InfoLabel';
import { MOBILE_WIDTH, option } from '../../../utils/globals';
import { TOTAL_CREDIT, YESTERDAY_MAX_OFFER, YESTERDAY_MIN_OFFER } from '../../../utils/constant';
import { useState } from 'react';
import Modal from '../../modal/Modal';
import CreditHistory from '../../credit/CreditHistory';
import colors from '../../../utils/colors';

const Info: React.FC<{ priceLimit: any; workspace: any, credit: any }> = ({
  workspace,
  priceLimit,
  credit,
}) => {

  const [modalOpen, setModalOpen] = useState(false);

  const onCloseModal = () => {
    setModalOpen(false);
    document.body.style.overflow = 'auto';
  };

  return (
    <>
      <Modal
        isOpen={modalOpen}
        onClose={onCloseModal}
        content={<CreditHistory onCloseModal={onCloseModal} data={credit}/>}
        title={'Hesap Hareketleri'}
      />

      <Container>
        <div style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center"
        }}>

          <Title>Kredi</Title>

          <StyledButton 
          onClick={() => {setModalOpen(true); document.body.style.overflow = 'hidden';}}
          >Hesap Hareketleri</StyledButton>
        </div>
        <InfoWrapper>
          <InfoLabel
            label={TOTAL_CREDIT}
            price={workspace?.budget?.toLocaleString('tr-TR', option) || 0}
          />
          <InfoLabel
            label={YESTERDAY_MAX_OFFER}
            price={(priceLimit?.maxPrice ?? 0).toLocaleString('tr-TR', option)}
          />
          <InfoLabel
            label={YESTERDAY_MIN_OFFER}
            price={(priceLimit?.minPrice ?? 0).toLocaleString('tr-TR', option)}
            borderless
          />
        </InfoWrapper>
      </Container>
    </>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  /* width: 85%; */
  gap: 20px;
  padding: 25px;
  border-radius: 16px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
  height: 300px;
  flex: 1;
`;

const Title = styled.div`
  font-weight: 600;
  font-size: 32px;
  line-height: 39px;
`;

const InfoWrapper = styled.div`
  display: flex;
  gap: 20px;
  flex-wrap: wrap;
  width: max-content;
  @media (max-width: ${MOBILE_WIDTH}px) {
    flex-direction: column;
    width: 100%;
  }
`;

const StyledButton = styled.button`
  height: 36px;
  border: 1px solid ${colors.black};
  border-radius: 10px;
  padding: 5px 15px;
  cursor: pointer;
  background-color: ${colors.white};
  color: ${colors.black}
`

export default Info;
