import styled from 'styled-components';
import colors from '../../utils/colors';
import { MOBILE_WIDTH } from '../../utils/globals';

const CustomTab = ({ tabs, handleTabClick, activeTab }: any) => {
  return (
    <CustomTabContainer>
      <div>
        {tabs.map(
          ({ tabIndex, title, groupId, bidRelated }: any, index: any) => {
            return (
              <CustomTabButton
                key={index}
                active={tabIndex === activeTab}
                onClick={() => handleTabClick(tabIndex)}
              >
                {title}
              </CustomTabButton>
            );
          }
        )}
      </div>
    </CustomTabContainer>
  );
};

const CustomTabContainer = styled.div`
  width: max-content;
  height: 40px;
  @media (max-width: ${MOBILE_WIDTH}px) {
    margin-bottom: 25px;
  }
`;

const CustomTabButton = styled.button<{ active: boolean }>`
  padding: 8px 20px 8px 20px;
  height: 40px;
  /* width: 120px; */
  border: none;
  position: relative;
  overflow: hidden;
  font-size: 20px;
  font-weight: 600;
  line-height: 24px;
  color: ${({ active }) => (active ? colors.mainBlue : '#6D6E6F')};
  cursor: pointer;

  &:after {
    content: '';
    display: block;
    height: 2px;
    width: ${({ active }) => (active ? '100%' : '0')};
    background-color: ${colors.mainBlue};
    transition: width 0.5s ease;
    position: absolute;
    bottom: 0;
    left: 0;
  }
`;

export default CustomTab;
