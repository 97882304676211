import { useEffect, useState } from 'react';
import {
  useGetDropdownCompanyMutation,
  usePostCustomerMutation,
} from '../../app/services/callcenter.service';
import { MOBILE_WIDTH, option } from '../../utils/globals';
import {
  addMonths,
  generateRandomHex,
  parseCurrencyStringToNumber,
} from '../../utils/helperFunctions';
import {
  useGetAllWorkspacesMutation,
  useGetIconMutation,
} from '../../app/services/workspace.service';
import styled from 'styled-components';
import colors from '../../utils/colors';
import { toast } from 'react-toastify';
import { PDFDownloadLink } from '@react-pdf/renderer';
import CustomPDFDocument from '../../components/pdf/Pdf';
import { useLocation, useNavigate } from 'react-router-dom';
import dateFormat from 'dateformat';
import { getBaseUrl, getSocketBaseUrl } from '../../config';

const initialDropdownOption = {
  companyName: 'Durum seçiniz',
  companyWorkspace: '',
};

const initialFormState = {
  evaluated: '',
  packetName: '',
  customerName: '',
  customerPhone: '',
  upfrontPayment: 0,
  totalPayment: 0,
};

const CallcenterForm = ({ rowInfo }: any) => {
  const [dropdown, setDropdown] = useState([initialDropdownOption]);
  const [workspaceDropdown, setWorkspaceDropdown] = useState([]);
  const [pdfNumber, setPdfNumber] = useState<null | string>(null);
  const [workspaceAccount, setWorkspaceAccount] = useState<any>(null);
  const [pdfHospitalIcon, setPdfHospitalIcon] = useState('');

  const navigate = useNavigate();
  const location = useLocation();

  const [formData, setFormData] = useState({
    evaluated: '',
    packetName:
      rowInfo?.packetData?.paketAdi ||
      'Herhangi bir saç ekim paketi seçilmemiştir',
    customerName: rowInfo?.fullname,
    customerPhone: rowInfo?.phone,
    upfrontPayment: 0,
    totalPayment: 0,
  });

  const [getDropdownCompany] = useGetDropdownCompanyMutation();
  const [postCustomer] = usePostCustomerMutation();
  const [getAllWorkspace] = useGetAllWorkspacesMutation();

  useEffect(() => {
    getDropdownHandler();
    getAllWorkspaces();
  }, []);

  const getDropdownHandler = async () => {
    const { data }: any = await getDropdownCompany({});
    setDropdown((prev) => prev.concat(data?.data));
  };

  const getAllWorkspaces = async () => {
    const { data }: any = await getAllWorkspace({});
    setWorkspaceDropdown(data?.data);
  };

  const [getIcon] = useGetIconMutation();

  const onChangeInputHandler = async (key: string, value: string) => {
    if (key === 'evaluated' && value) {
      const { data }: any = await getIcon({ workspaceId: value });
      setPdfHospitalIcon(data?.data.company.icon.url);
    }

    setFormData((prev) => {
      if (key === 'upfrontPayment' || key === 'totalPayment') {
        return {
          ...prev,
          [key]: parseCurrencyStringToNumber(value),
        };
      } else {
        return { ...prev, [key]: value };
      }
    });
  };

  const workspaceAccountHandler = (event: any) => {
    const relatedWorkspace = workspaceDropdown.find(
      (workspace: any) => workspace._id === event.target.value
    );
    setWorkspaceAccount(relatedWorkspace);
  };

  const onSubmitHandler = async (e: any) => {
    e.preventDefault();
    if (workspaceAccount) {
      const uniqueNumber = generateRandomHex();
      setPdfNumber(uniqueNumber);
      const { data }: any = await postCustomer({
        ...formData,
        pdfNumber: uniqueNumber,
      });
      if (data?.success) {
        toast.success('İşleminiz başarılı şekilde gerçekleşmiştir.');

        // setFormData(initialFormState);
        // setDropdown([initialDropdownOption]);

        navigate(`${location.pathname}?pdf=true`, { state: location.state });
      }
    } else {
      toast.info('Lütfen kurum bilgilerinden atama yapacağınız kurumu seçiniz');
    }
  };

  const now = dateFormat(new Date(), 'dd-mm-yyyy');
  const threeMonthsLater = addMonths(new Date(), 3);
  const sixMonthsLater = addMonths(new Date(), 6);

  const threeMonth = dateFormat(
    workspaceAccount?.name?.toLowerCase().includes('medical')
      ? sixMonthsLater
      : threeMonthsLater,
    'dd-mm-yyyy'
  );

  let result = '';

  rowInfo?.packetData?.paketDetaylari_new.forEach((item: any) => {
    Object.keys(item).forEach((key) => {
      if (key !== 'id') {
        const values = item[key].split(';');
        result += `${key.charAt(0).toUpperCase() + key.slice(1)}: ${values
          .join(', ')
          .trim()}\n`;
      }
    });
  });

  return (
    <Container>
      <ContentWrapper $isGetFeatureCentered={false}>
        <CallCenterPostFeatureWrapper>
          <Wrapper>
            <StyledLabel htmlFor='workspace-select'>
              Kurum Bilgileri :
            </StyledLabel>
            <WorkspaceSelectWrapper>
              <WorkspaceSelect
                name='workspaces'
                id='workspace-select'
                onChange={workspaceAccountHandler}
              >
                <option value=''>-- Kurum Seçiniz --</option>
                {workspaceDropdown
                  .filter((item: any) => item.callcenterGetFeature)
                  .map((workspace: any, index) => (
                    <option key={index} value={workspace._id}>
                      {workspace.name}
                    </option>
                  ))}
              </WorkspaceSelect>
              <WorkspaceDetails>
                <WorkspaceLabel>Hesap ve Yetkili Bilgileri</WorkspaceLabel>
                <div>{`İban Nu. : ${workspaceAccount?.iban || ''}`}</div>
                <div>{`Hesap Adı. : ${
                  workspaceAccount?.accountName || ''
                }`}</div>
                <div>{`Yetkili Ad Soyad : ${
                  workspaceAccount?.authorityName || ''
                }`}</div>
                <div>{`Yetkili Telefonu : ${
                  workspaceAccount?.authorityNumber || ''
                }`}</div>
                <div>{`Hastane Adresi : ${
                  workspaceAccount?.address || ''
                }`}</div>
              </WorkspaceDetails>
            </WorkspaceSelectWrapper>
            <div>
              <FeatureTitle>İşlem Formu</FeatureTitle>
              <span>(Ön ödeme alındıktan sonra doldurunuz.)</span>
            </div>
            <Form>
              <FormLabel htmlFor='packet'>Paket Adı</FormLabel>
              <FormInput
                id='packet'
                type='text'
                value={formData.packetName}
                onChange={(e) =>
                  onChangeInputHandler('packetName', e.target.value)
                }
              />
              <FormLabel htmlFor='customer'>Müşteri Adı Soyadı</FormLabel>
              <FormInput
                id='customer'
                type='text'
                value={formData.customerName}
                onChange={(e) =>
                  onChangeInputHandler('customerName', e.target.value)
                }
              />
              <FormLabel htmlFor='customerPhone'>Müşteri Numarası</FormLabel>
              <FormInput
                id='customerPhone'
                type='text'
                value={formData.customerPhone}
                onChange={(e) =>
                  onChangeInputHandler('customerPhone', e.target.value)
                }
              />
              <FormLabel htmlFor='upfrontPayment'>Alınan Ön Ödeme</FormLabel>
              <FormInput
                id='upfrontPayment'
                type='text'
                value={formData.upfrontPayment?.toLocaleString('tr-TR', option)}
                onChange={(e) =>
                  onChangeInputHandler('upfrontPayment', e.target.value)
                }
              />
              <FormLabel htmlFor='totalPayment'>Toplam Tutar</FormLabel>
              <FormInput
                id='totalPayment'
                type='text'
                value={formData.totalPayment?.toLocaleString('tr-TR', option)}
                onChange={(e) =>
                  onChangeInputHandler('totalPayment', e.target.value)
                }
              />
              <FormLabel htmlFor='evaluated'>Atama Yapılacak Hastane</FormLabel>
              <FormSelect
                id='evaluated'
                onChange={(e) =>
                  onChangeInputHandler('evaluated', e.target.value)
                }
              >
                {dropdown.map((item: any, index) => (
                  <option key={index} value={item.companyWorkspace}>
                    {item.companyName}
                  </option>
                ))}
              </FormSelect>
              <SubmitButton
                type='submit'
                onClick={onSubmitHandler}
                disabled={!!location.search.split('=').at(-1)}
              >
                Gönder
              </SubmitButton>
            </Form>
            {location.search.split('=').at(-1) && (
              <div
                onClick={() => {
                  setTimeout(() => {
                    navigate(`${location.pathname}`, {
                      state: location.state,
                    });
                  }, 500);
                }}
              >
                <PDFDownloadLink
                  document={
                    <CustomPDFDocument
                      customerName={formData.customerName}
                      date={now}
                      lastDate={threeMonth}
                      customerPhone={formData?.customerPhone}
                      packetName={formData?.packetName}
                      packetDetails={result ?? ''}
                      hospitalPhone={workspaceAccount?.authorityNumber}
                      hospitalAddress={workspaceAccount?.address}
                      hospitalIcon={`${getSocketBaseUrl()}${pdfHospitalIcon}`}
                      hospitalName={
                        dropdown.find(
                          (item) => item.companyWorkspace === formData.evaluated
                        )?.companyName
                      }
                      upfrontPayment={formData.upfrontPayment}
                      remainingPayment={
                        Number(formData.totalPayment) -
                        Number(formData.upfrontPayment)
                      }
                      seriNu={pdfNumber}
                    />
                  }
                  fileName={`${rowInfo?.fullname}.pdf`}
                >
                  {({ blob, url, loading, error }) =>
                    loading ? 'Loading document...' : 'Download PDF'
                  }
                </PDFDownloadLink>
              </div>
            )}
          </Wrapper>
        </CallCenterPostFeatureWrapper>
      </ContentWrapper>
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  height: 100vh;
  flex: 1;
`;

const ContentWrapper = styled.div<{ $isGetFeatureCentered: boolean }>`
  display: flex;
  justify-content: ${({ $isGetFeatureCentered }) =>
    $isGetFeatureCentered ? 'center' : 'space-between'};
  gap: 20px;
  width: 100%;
`;

const CallCenterPostFeatureWrapper = styled.div`
  display: flex;
  gap: 15px;
  width: 100%;
`;

const WorkspaceSelectWrapper = styled.div`
  padding: 5px 10px;
  width: 100%;
  display: flex;
  align-items: flex-start;
  gap: 20px;
  border: 1px solid rgb(154, 185, 252, 0.4);
  border-radius: 8px;
  @media screen and (max-width: ${MOBILE_WIDTH}px) {
    flex-direction: column;
  }
`;

const WorkspaceSelect = styled.select`
  border: 1px solid rgb(154, 185, 252, 0.4);
  border-radius: 8px;
  height: 40px;
  padding: 0px 10px;
  appearance: none;
  background-color: #f9f9f9;
  cursor: pointer;
`;

const WorkspaceDetails = styled.div`
  font-size: 14px;
  display: flex;
  flex-direction: column;
  gap: 20px;
`;

const WorkspaceLabel = styled.label`
  font-weight: 700;
`;

const Form = styled.form`
  display: flex;
  flex-direction: column;
  gap: 20px;
  width: 100%;
  flex-grow: 1;
`;

const FormLabel = styled.label`
  font-weight: bold;
`;

const FormInput = styled.input`
  border: 1px solid rgb(154, 185, 252, 0.7);
  border-radius: 8px;
  padding: 0px 10px;
  height: 40px;
`;

const FormSelect = styled.select`
  border: 1px solid rgb(154, 185, 252, 0.7);
  border-radius: 8px;
  height: 40px;
  padding: 0px 10px;
  appearance: none;
  background-color: #f9f9f9;
  cursor: pointer;
`;

const SubmitButton = styled.button`
  align-self: start;
  font-size: 16px;
  border: none;
  border-radius: 8px;
  padding: 10px;
  background-color: ${colors.mainBlue};
  color: white;
  cursor: pointer;
  margin-top: 20px;
`;

const Wrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  max-width: 100%;
  gap: 30px;
`;

const FeatureTitle = styled.h2`
  margin-bottom: 10px;
`;

const StyledLabel = styled.label`
  font-weight: bold;
`;

export default CallcenterForm;
