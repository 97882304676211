import styled from 'styled-components';
import { DISTRICTS } from '../../../utils/globals';
import colors from '../../../utils/colors';
import { convertToFirstCharUppercase } from '../../../utils/helperFunctions';

const Dropdown = ({
  dropdownValue,
  handleDropdownChange,
  selectedOptions,
  handleRemoveOption,
}: any) => {
  return (
    <DropdownContainer>
      <label htmlFor='dropdown'>İlçe</label>
      <select
        id='dropdown'
        value={dropdownValue}
        onChange={handleDropdownChange}
      >
        <option value='' style={{ color: 'grey' }}>
          İlçe Seçiniz
        </option>
        {DISTRICTS.map((option) => (
          <option key={option.value} value={option.text}>
            {option.text}
          </option>
        ))}
      </select>
      <div>
        {selectedOptions.map((option: string) => (
          <span key={option} style={{ border: '1px solid transparent' }}>
            {convertToFirstCharUppercase(option)}
            <button onClick={() => handleRemoveOption(option)}>x</button>
          </span>
        ))}
      </div>
    </DropdownContainer>
  );
};

const DropdownContainer = styled.div`
  margin-bottom: 20px;
  width: 100%;
  label {
    display: block;
    margin-bottom: 5px;
    font-weight: 600;
    font-size: 14px;
    line-height: 17px;
  }

  select {
    width: 100%;
    padding: 8px;
    border: 1px solid #ccc;
    border-radius: 10px;
    box-sizing: border-box;
    font-size: 16px;
    margin-bottom: 10px;
  }

  div {
    display: flex;
    flex-wrap: wrap;
    gap: 5px;
  }

  span {
    background-color: ${colors.mainLightBlue};
    padding: 5px 15px;
    border-radius: 20px;
    display: flex;
    align-items: center;
    color: ${colors.mainBlue};
    gap: 10px;
  }

  button {
    margin-left: 5px;
    border: none;
    background-color: transparent;
    color: ${colors.mainBlue};
    cursor: pointer;
  }
`;
export default Dropdown;
