import {
  Navigate,
  Route,
  RouterProvider,
  createBrowserRouter,
  createRoutesFromElements,
} from 'react-router-dom';
// import Bids, { bidsLoader } from '../pages/bids/Bids';
import Login from '../pages/login/Login';
import { Offers } from '../pages/offers/Offers';
import Whatsapp from '../pages/whatsapp/whatsapp';
import ProtectedLayout from './ProtectedRoute';
import Landing from '../pages/landing/Landing';
import UnprotectedLayout from './UnprotectedRoute';
import ConditionalWhatsappRoute from './ConditionalWpRoute';
import { Subscription } from '../pages/subscription/Subscription';
import OfferDetail from '../pages/offers/details/OfferDetail';
import Settings from '../pages/settings/Settings';
import CustomerDetail from '../pages/offers/details/CustomerDetail';

const Index = () => {
  const router = createBrowserRouter(
    createRoutesFromElements(
      <>
        <Route element={<ProtectedLayout />}>
          <Route path='/offers' element={<Offers />} />
          <Route path='/settings' element={<Settings />} />
          <Route path='/offers/:offerId' element={<OfferDetail />} />
          <Route
            path='/offers/customers/:customerId'
            element={<CustomerDetail />}
          />
          <Route path='/whatsapp' element={<ConditionalWhatsappRoute />}>
            <Route index element={<Whatsapp />} />
          </Route>
          <Route path='*' element={<Navigate to='/offers' />} />
        </Route>
        <Route element={<UnprotectedLayout />}>
          <Route path='/landing' element={<Landing />} />
          <Route path='/login' element={<Login />} />
          <Route path='/subscription' element={<Subscription />} />
          <Route path='*' element={<Navigate to='/landing' />} />
        </Route>
      </>
    )
  );
  return <RouterProvider router={router} />;
};

export default Index;
