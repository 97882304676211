import api from '../api';

export const bidsService = api.injectEndpoints({
  endpoints: (build) => {
    return {
      createBid: build.mutation({
        query: (args) => ({
          url: 'bids/create',
          method: 'POST',
          body: args,
        }),
        invalidatesTags: ['AUTH'],
      }),
      updateBid: build.mutation({
        query: (args) => ({
          url: 'bids/update',
          method: 'PUT',
          body: args,
        }),
        invalidatesTags: ['UPDATE_AUTH'],
      }),
      getBidByWorkspaceId: build.mutation({
        query: (args) => ({
          url: `bids/?${new URLSearchParams(args)}`,
          method: 'GET',
        }),
        invalidatesTags: ['GET_BID_BY_ID'],
      }),
      activateToggleForBid: build.mutation({
        query: (args) => ({
          url: 'bids/activate',
          method: 'PUT',
          body: args,
        }),
        invalidatesTags: ['ACTIVATE_TOGGLE'],
      }),
      createApplication: build.mutation({
        query: (args) => ({
          url: 'application',
          method: 'POST',
          body: args,
        }),
        invalidatesTags: ['CREATE_APP'],
      }),
    };
  },
});

export const {
  useCreateBidMutation,
  useGetBidByWorkspaceIdMutation,
  useUpdateBidMutation,
  useActivateToggleForBidMutation,
  useCreateApplicationMutation,
} = bidsService;
