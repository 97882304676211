import dateFormat from 'dateformat';
import styled from 'styled-components';
import colors from '../../../utils/colors';
import { option } from '../../../utils/globals';
import { MOBILE_WIDTH } from '../../../utils/globals';
import { formatPhoneNumber } from '../../../utils/helperFunctions';

const columns = [
  { key: 'fullname', label: 'Checkup Talep Eden Kişi' },
  { key: 'phoneNumber', label: 'Telefon Numarası' },
  { key: 'createdAt', label: 'İşlem Tarihi' },
  { key: 'soldPrice', label: 'Gerçekleşme Fiyatı' },
];

const Transition = ({ transactions }: any) => {
  return (
    <Container>
      <StyledTable>
        <tr>
          {columns.map(({ label }) => (
            <td
              key={label}
              style={{ color: colors.mainBlue, fontWeight: '600' }}
            >
              {label}
            </td>
          ))}
        </tr>
        <Space />
        {transactions.map(
          ({ createdAt, price, offer, hairoffer }: any, index: number) => (
            <StyledTableRow key={index} $bg={index % 2 === 0}>
              <StyledTableCol $radiusLeft={true}>
                {offer?.fullname || hairoffer?.fullname}
              </StyledTableCol>
              <StyledTableCol>
                {formatPhoneNumber(offer?.phone || hairoffer?.phone)}
              </StyledTableCol>
              <StyledTableCol>
                {dateFormat(createdAt, 'dd-mm-yyyy HH:MM')}
              </StyledTableCol>
              <StyledTableCol $radiusRight={true}>{`${price?.toLocaleString(
                'tr-TR',
                option
              )}`}</StyledTableCol>
            </StyledTableRow>
          )
        )}
      </StyledTable>
    </Container>
  );
};

const StyledTable = styled.table`
  width: 100%;
  border-collapse: collapse;
  @media (max-width: ${MOBILE_WIDTH}px) {
    td {
      padding-right: 25px;
    }
  }
`;

const StyledTableRow = styled.tr<{ $bg: boolean }>`
  border-radius: 10px;
  padding: 10px 15px;
  background-color: ${({ $bg }) => ($bg ? colors.mainLightBlue : 'white')};
`;

const StyledTableCol = styled.td<{
  $radiusRight?: boolean;
  $radiusLeft?: boolean;
}>`
  padding: ${({ $radiusLeft }) => ($radiusLeft ? '10px 15px' : '10px 0px')};
  height: 50px;
  border-top-left-radius: ${({ $radiusLeft }) =>
    $radiusLeft ? '14px' : '0px'};
  border-bottom-left-radius: ${({ $radiusLeft }) =>
    $radiusLeft ? '14px' : '0px'};
  border-top-right-radius: ${({ $radiusRight }) =>
    $radiusRight ? '14px' : '0px'};
  border-bottom-right-radius: ${({ $radiusRight }) =>
    $radiusRight ? '14px' : '0px'};
  min-width: 200px;
`;

const Space = styled.div`
  height: 20px;
`;

const Container = styled.div`
  max-height: 600px;
  overflow: auto;
`;
export default Transition;
