import { useEffect, useRef, useState } from 'react';
import styled, { keyframes } from 'styled-components';

import colors from '../../../utils/colors';
import {
  useGetNoteByCustomerIdMutation,
  useSaveNoteByCustomerIdMutation,
  useUpdateStatusByCustomerIdMutation,
  useGetStatusByCustomerIdMutation, // Yeni endpoint için import
} from '../../../app/services/workspace.service';
import { ADD_NOTE } from '../../../utils/constant';
import Loading from '../../../components/loading/Loading';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';

const CustomerDetail = () => {
  const [note, setNote] = useState('');
  const [isEditing, setEditing] = useState(false);
  const [status, setStatus] = useState('');
  const textareaRef = useRef<any>();
  const { customerId } = useParams();
  const [getNoteByCustomerId, { isLoading: isNoteLoading }] =
    useGetNoteByCustomerIdMutation();
  const [saveNoteByCustomerId] = useSaveNoteByCustomerIdMutation();
  const [updateStatusByCustomerId] = useUpdateStatusByCustomerIdMutation();
  const [getStatusByCustomerId, { isLoading: isStatusLoading }] =
    useGetStatusByCustomerIdMutation();

  useEffect(() => {
    const fetchNoteAndStatus = async () => {
      const noteData: any = await getNoteByCustomerId({
        customerId: customerId,
      });
      if (noteData?.data?.success) {
        setNote(noteData?.data?.data);
      }

      const statusData: any = await getStatusByCustomerId({
        customerId: customerId,
      });
      if (statusData?.data?.success) {
        setStatus(statusData?.data?.data?.status);
      }
    };

    fetchNoteAndStatus();
  }, [customerId]);

  const handleInputChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    setNote(event.target.value);
  };

  const handleEditToggle = () => {
    setEditing(!isEditing);
  };

  const handleStatusChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    setStatus(event.target.value);
  };

  useEffect(() => {
    if (isEditing) {
      textareaRef.current.focus();
      textareaRef.current.setSelectionRange(note?.length, note?.length);
    }
  }, [isEditing, note]);

  const handleSave = async () => {
    const { data }: any = await saveNoteByCustomerId({
      customerId: customerId,
      content: note,
    });

    if (data?.success) {
      setEditing(false);
    }
  };

  const handleStatusUpdate = async () => {
    const { data }: any = await updateStatusByCustomerId({
      customerId: customerId,
      status: status,
    });

    if (data?.success) {
      toast.success('Başarılı');
    }
  };

  if (isNoteLoading || isStatusLoading) {
    return <Loading width='50px' boldness='6px' />;
  }

  return (
    <OuterWrapper>
      <Content>
        <NoteSectionWrapper>
          <NoteSection>
            <NoteLabel>Notlar</NoteLabel>
            <EditableContainer>
              {isEditing ? (
                <EditNoteSection>
                  <StyledTextarea
                    value={note}
                    onChange={handleInputChange}
                    ref={textareaRef}
                  ></StyledTextarea>
                  <SaveButton onClick={handleSave}>Kaydet</SaveButton>
                </EditNoteSection>
              ) : (
                <DisplayNote onClick={handleEditToggle}>
                  {note || ADD_NOTE}
                </DisplayNote>
              )}
            </EditableContainer>
          </NoteSection>
          <StatusSection>
            <StatusLabel>Durum</StatusLabel>
            <StyledSelect value={status} onChange={handleStatusChange}>
              <option value='' disabled>
                Durum Seçin
              </option>
              <option value='pending'>Beklemede</option>
              <option value='completed'>Tamamlandı</option>
              <option value='canceled'>İptal Edildi</option>
            </StyledSelect>
            <UpdateStatusButton onClick={handleStatusUpdate}>
              Durumu Güncelle
            </UpdateStatusButton>
          </StatusSection>
        </NoteSectionWrapper>
      </Content>
    </OuterWrapper>
  );
};

const SideBarAnimation = keyframes`
  0% {
    transform: scale(0);
    opacity: 0;
  }
  100% {
    transform: scale(1);
    opacity: 1;
  }
`;

const OuterWrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const Content = styled.div`
  background: #f3f7ff;
  display: flex;
  flex-direction: column;
  gap: 20px;
  width: 80%;
  padding: 120px 5%;
`;

const NoteSection = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
`;

const NoteLabel = styled.div`
  font-size: 16px;
  line-height: 19px;
  font-weight: 700;
`;

const EditNoteSection = styled.div`
  display: flex;
  flex-direction: column;
`;

const StyledTextarea = styled.textarea`
  width: 100%;
  height: 150px;
  background: white;
  border: 1px solid rgb(210, 223, 250, 1);
  border-radius: 8px;
  padding: 8px;
  box-sizing: border-box;
  font-size: 16px;
  resize: none;
`;

const SaveButton = styled.button`
  background-color: ${colors.mainBlue};
  color: white;
  padding: 6px 12px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 12px;
  transition: background-color 0.3s;
  width: fit-content;
  align-self: flex-end;
  margin-top: 10px;
  &:hover {
    background-color: ${colors.mainDarkBlue};
  }
`;

const DisplayNote = styled.div`
  width: 100%;
  min-height: 190px;
  background: white;
  border: 1px solid rgb(154, 185, 252, 0.4);
  border-radius: 8px;
  padding: 8px;
  box-sizing: border-box;
  font-size: 16px;
  overflow-wrap: break-word;
  cursor: pointer;
`;

const EditableContainer = styled.div`
  width: 100%;
`;

const MainSectionWrapper = styled.div`
  display: flex;
  flex-direction: column;
  column-gap: 80px;
`;
const NoteSectionWrapper = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 43px;
`;

const StatusSection = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
`;

const StatusLabel = styled.div`
  font-size: 16px;
  line-height: 19px;
  font-weight: 700;
`;

const StyledSelect = styled.select`
  width: 100%;
  padding: 8px;
  border-radius: 8px;
  border: 1px solid rgb(210, 223, 250, 1);
  font-size: 16px;
  background: white;
  box-sizing: border-box;
`;

const UpdateStatusButton = styled.button`
  background-color: ${colors.mainBlue};
  color: white;
  padding: 6px 12px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 12px;
  transition: background-color 0.3s;
  width: fit-content;
  align-self: flex-end;
  margin-top: 10px;
  &:hover {
    background-color: ${colors.mainDarkBlue};
  }
`;

export default CustomerDetail;
