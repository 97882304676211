import React from 'react';
import styled from 'styled-components';
import colors from '../../../utils/colors';
import { MOBILE_WIDTH } from '../../../utils/globals';

interface IProps {
  label: string;
  price: number;
  borderless?: boolean;
}

const InfoLabel: React.FC<IProps> = ({ label, price, borderless = false }) => {
  return (
    <Container $borderless={borderless}>
      <Label>{label}</Label>
      <Price>{`${price}`}</Price>
    </Container>
  );
};

const Container = styled.div<{ $borderless?: boolean }>`
  border-right: ${({ $borderless }) =>
    $borderless ? 'none' : '1px solid gray'};
    
  padding: 40px 20px 40px 0px;
  @media (max-width: ${MOBILE_WIDTH}px) {
    border-bottom: ${({ $borderless }) =>
    $borderless ? 'none' : '1px solid gray'};
    border-right:none;
  }
`;

const Label = styled.div`
  font-weight: 600;
  font-size: 14px;
  line-height: 17px;
  color: ${colors.gray80};
  margin-bottom: 5px;
`;

const Price = styled.div`
  font-weight: 600;
  font-size: 32px;
  line-height: 39px;
`;

export default InfoLabel;
